import { useEffect } from "react";
import { FC } from "react";
interface PageHeaderProps {
  title: string;
  description?: string;
  children?: JSX.Element;
  skeleton?: boolean;
}
const PageHeader: FC<PageHeaderProps> = ({
  title,
  children,
  description = "",
  skeleton = false,
}) => {
  return (
    <div
      className={`mb-4 flex flex-col items-center justify-between px-3 lg:flex-row ${
        skeleton ? "py-4" : "py-2"
      }`}
    >
      <div className="w-full justify-start">
        <h1 className={"text-dark text-3xl font-bold lg:text-2xl"}>{title}</h1>
        <h6 className={"text-base text-gray-400 lg:text-sm"}>{description}</h6>
      </div>
      <div className="mt-3 flex w-full justify-end border-b py-3 dark:border-gray-700 lg:mt-0 lg:border-none">
        {children}
      </div>
    </div>
  );
};

export default PageHeader;
