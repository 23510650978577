import React from "react";
import Avatar from "./UI/Avatar";
import Cookie from "js-cookie";
import Icon from "./UI/Icon";
import { IoLogOut, IoLogOutOutline, IoLogOutSharp } from "react-icons/io5";
import removeAuth from "../hooks/removeAuth";
import { useNavigate } from "react-router-dom";

const TheUserInfo = () => {
  const navigate = useNavigate();

  async function signOut() {
    removeAuth();
    navigate("/login");
  }

  return (
    <div className="my-6 flex w-full items-center justify-between gap-4 transition-transform lg:w-64 lg:group-hover:mt-3">
      <Avatar
        href={Cookie.get("eitrynne-client-app_id") || "avoid ts-error"}
        alt="avatar"
        className="ml-4 h-16 w-16 cursor-pointer rounded-full lg:h-8 lg:w-8"
      />
      <div className="flex w-full flex-col pl-10 transition-transform group-hover:delay-100 lg:translate-x-40 lg:pl-4 lg:group-hover:translate-x-0">
        <span className="text-dark text-lg font-bold lg:text-sm">
          {Cookie.get("eitrynne-client-app_username")
            ? Cookie.get("eitrynne-client-app_username")
            : "Ты кто такой?"}
        </span>
        <span className="font-regular text-sm text-gray-400 lg:text-xs">
          {Cookie.get("eitrynne-client-app_role") === "admin"
            ? "Администратор"
            : Cookie.get("eitrynne-client-app_role") === "employee"
            ? "Сотрудник"
            : "Клиент"}
        </span>
      </div>
      <Icon
        className="px-8 text-3xl lg:px-5 lg:text-xl"
        onClick={() => signOut()}
      >
        <IoLogOut />
      </Icon>
    </div>
  );
};

export default TheUserInfo;
