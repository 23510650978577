import { useMutation, useQuery } from "@apollo/client";
import React, { FC, useEffect, useRef, useState } from "react";
import {
  IoAlertSharp,
  IoCalendar,
  IoCalendarSharp,
  IoCallSharp,
  IoCashSharp,
  IoColorFilterSharp,
  IoLockClosedSharp,
  IoMailSharp,
  IoPencilSharp,
  IoQrCodeSharp,
  IoRefreshCircleSharp,
  IoSaveSharp,
  IoSearchSharp,
  IoTrash,
  IoTrashBinSharp,
} from "react-icons/io5";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Avatar from "../../components/UI/Avatar";
import Button from "../../components/UI/Button";
import ButtonBack from "../../components/UI/ButtonBack";
import {
  DELETE_PAYMENT,
  DELETE_USER,
  GET_GROUPS,
  GET_USER,
  GET_USER_RECORDS,
  UPDATE_USER,
} from "../../graphql/users";
import { FaMoneyCheckAlt } from "react-icons/fa";
import {
  MdCancel,
  MdCategory,
  MdEdit,
  MdMoney,
  MdMoneyOff,
} from "react-icons/md";
import calcTotalPrice from "../../hooks/totalPrice";
import { GoPlus } from "react-icons/go";
import { toast } from "react-toastify";
import { CHANGE_RECORD_STATUS, DELETE_RECORD } from "../../graphql/records";
import { TbDotsDiagonal, TbMoodEmpty } from "react-icons/tb";
import Input from "../../components/UI/Input";
import removeAuth from "../../hooks/removeAuth";
import Select from "../../components/UI/Select";
import ModalDialog from "../../components/UI/ModalDialog";
import useValidDate from "../../hooks/validDate";
import numberWithCommas from "../../hooks/numberWithComma";
import Popup from "../../components/UI/Popup";
import { axiosInstance } from "../../config/axiosConfig";
import numberWithComma from "../../hooks/numberWithComma";
import Cookie from "js-cookie";
import Divider from "../../components/UI/Divider";
import DragNDropInput from "../../components/UI/Modal/DragNDropInput";
import ModalAddRecords from "../../components/UI/Modal/modalAddRecords";
import UserSearch from "../../components/UserSearch";
import useDebounce from "../../hooks/debounce";
import Loading from "../../components/UI/Loading";
import InfinityPagination from "../../components/UI/InfinityPagination";
import ModalAddPayments from "../../components/UI/Modal/modalAddPayments";

const ClientsDetails: FC = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [editUser, setEditUser] = useState(false);
  const [dialogDeleteUser, setDialogDeleteUser] = useState(false);

  const [userInfo, setUserInfo] = useState<any>({});

  const [recordListSearchQuery, setRecordListSearchQuery] =
    useState<string>("");
  const recordListSearchValue = useDebounce(recordListSearchQuery);

  // infinity pagination
  const [recordsCount, setRecordsCount] = useState<number>(30);
  const [recordsPage, setRecordsPage] = useState<number>(0);
  const [recordsList, setRecordsList] = useState<any>([]);

  const {
    data: user,
    loading: userLoading,
    error,
    refetch,
  } = useQuery(GET_USER, {
    variables: {
      id: id,
    },
  });

  const {
    data: userRecords,
    loading: userRecordsLoading,
    error: userRecordsError,
    refetch: userRecordsRefetch,
  } = useQuery(GET_USER_RECORDS, {
    variables: {
      id: id,
      take: recordsCount,
      skip: recordsPage,
      searchValue: recordListSearchValue,
    },
  });

  useEffect(() => {
    setRecordsList([]);
    setRecordsPage(0);
  }, [recordListSearchValue]);

  useEffect(() => {
    if (gettedUserRecords) return;

    if (!user) return;

    if (user.getOneUser.role) {
      setLoading(true);
      axiosInstance
        .get(`/payments/topUserRecords?userId=${id}`)
        .then((res) => {
          if (res.data.length < 1) return;
          setUserRecordsByEmployee(
            res.data.sort((a: any, b: any) => b.count - a.count).slice(0, 5)
          );
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setGettedUserRecords(true);
          setLoading(false);
        });
    }
  }, [user]);

  useEffect(() => {
    if (!userRecordsLoading && userRecords.getRecordsByUserId.length > 0) {
      setRecordsList((prev: any) => [
        ...prev,
        ...userRecords.getRecordsByUserId,
      ]);
    }
  }, [userRecords]);

  function fetchRecords() {
    if (recordsList.length === 0) return;

    if (userRecordsLoading) return;

    if (recordsPage * recordsCount > userRecords.getRecordsByUserId.length)
      return;

    setRecordsPage((prev: number) => prev + 1);
  }

  const [userRecordsByEmployee, setUserRecordsByEmployee] = useState<any[]>([]);
  const [gettedUserRecords, setGettedUserRecords] = useState<boolean>(false);

  // * GETTING CURRENT USER RECORDS BY EMPLOYEE FOR TOP-5 RATING

  const [deleteUser] = useMutation(DELETE_USER);

  const {
    data: groups,
    loading: groupsLoading,
    error: groupsError,
  } = useQuery(GET_GROUPS);
  const [deleteRecord] = useMutation(DELETE_RECORD);
  const [updateUser] = useMutation(UPDATE_USER);

  useEffect(() => {
    refetch();
    refreshUserRecords();

    return function cleanUp() {
      setGettedUserRecords(false);
    };
  }, [id]);

  useEffect(() => {
    if (!userLoading) {
      setUserInfo({
        ...user.getOneUser,
        category: user.getOneUser.category.value,
        birthdate: new Date(user.getOneUser.birthdate).toLocaleDateString("ru"),
      });
    }
  }, [userLoading]);

  const [checkedRecords, setCheckedRecords] = useState<any[]>([]);
  const navigate = useNavigate();
  function pushCheckedRecord(record: any) {
    if (checkedRecords.includes(record)) {
      return setCheckedRecords(
        checkedRecords.filter((item) => item !== record)
      );
    }

    return setCheckedRecords([...checkedRecords, record]);
  }
  const [dialogMarkRecord, setDialogMarkRecord] = useState(false);
  const [markRecordData, setMarkRecordData] = useState<any>({
    status: false,
    date: new Date().toLocaleDateString("ru"),
    type: {
      value: "QR",
    },
  });
  const [changeRecordStatus] = useMutation(CHANGE_RECORD_STATUS);
  const [deletePayment] = useMutation(DELETE_PAYMENT, {});

  const [showModalAddRecords, setShowModalAddRecords] =
    useState<boolean>(false);

  const [showModalAddPayments, setShowModalAddPayments] =
    useState<boolean>(false);

  async function refreshUserRecords() {
    recordsList.length !== 0 && setRecordsList([]);
    recordsPage !== 0 && setRecordsPage(0);
    userRecordsRefetch();
    refetch();
  }

  async function markRecordPayed() {
    setMarkRecordData({ ...markRecordData, status: true });
    setDialogMarkRecord(true);
  }

  async function runMarkRecord() {
    setLoading(true);
    try {
      for await (const record of checkedRecords) {
        for await (const { user } of record.prices) {
          await changeRecordStatus({
            variables: {
              recordStatus: {
                id: record.id,
                status: markRecordData.status,
                payment_date: useValidDate(markRecordData.date),
                payment_type:
                  markRecordData.type.value === "QR"
                    ? "qr"
                    : markRecordData.type.value === "Наличными"
                    ? "cash"
                    : "transfer",
                payment_comment: `Отмечено как ${
                  markRecordData.status
                    ? '"Оплачено" в списке всех записей ' +
                      "[" +
                      record.employee.name +
                      "]"
                    : '"Не оплачено" в списке всех записей' +
                      "[" +
                      record.employee.name +
                      "]"
                }`,
                userId: user.id,
              },
            },
          });
        }
      }
      toast.success("Запись успешно обновлена!");
    } catch (e) {
      toast.error(
        "При обновлении статуса записи произошла ошибка! Попробуйте снова или обратитесь к администратору!"
      );
      console.log(e);
    } finally {
      await refreshUserRecords();
      setLoading(false);
      setDialogMarkRecord(false);
      setCheckedRecords([]);
    }
  }

  async function markRecordUnpayed() {
    setMarkRecordData({ ...markRecordData, status: false });
    setDialogMarkRecord(true);
  }
  async function toggleDeleteRecords() {
    if (confirm("Вы действительно хотите удалить эти записи?")) {
      confirmDeleteRecords();
    }
  }

  async function confirmDeleteRecords() {
    setLoading(true);
    try {
      for await (const { id } of checkedRecords) {
        await deleteRecord({
          variables: {
            id,
          },
        });
      }

      toast.success("Записи были успешно удалены!");
      setCheckedRecords([]);
      refreshUserRecords();
      refetch();
    } catch (e) {
      console.log("При удалении записей произошла ошибка >>>", e);
      toast.error(
        "При удалении записей произошла ошибка! Попробуйте снова или обратитесь к администратору."
      );
    } finally {
      setLoading(false);
    }
  }

  async function toggleEditUser() {
    setUserInfo({
      ...user.getOneUser,
      category: user.getOneUser.category.value,
      birthdate: new Date(user.getOneUser.birthdate).toLocaleDateString("ru"),
    });
    setEditUser(true);
  }

  async function saveUser() {
    setLoading(true);
    try {
      if (userInfo.name.length < 3) {
        throw new Error("Имя посетителя слишком короткое!");
      }
      if (!userInfo.category) {
        throw new Error("Нужно ввести корректное значение категории!");
      }
      await updateUser({
        variables: {
          updateUser: {
            id: userInfo.id,
            name: userInfo.name,
            category: userInfo.category.toLowerCase(),
            phone: userInfo.phone,
            email: userInfo.email,
            status: userInfo.status,
            birthdate: useValidDate(userInfo.birthdate),
            role: userInfo.role.toLowerCase(),
            groupId: userInfo.group.id,
          },
        },
      });

      toast.success("Информация посетителя была успешно обновлена!");
      await refetch();
      setEditUser(false);
    } catch (e) {
      console.log(e);
      toast.error(
        "При обновлении произошла ошибка! Попробуйте снова или обратитесь к адмиинистратору"
      );
    } finally {
      setLoading(false);
    }
  }

  async function toggleDeleteUser(id: string) {
    localStorage.setItem("deleteUser", id);
    setDialogDeleteUser(true);
  }

  async function runDeleteUser() {
    setLoading(true);
    try {
      await deleteUser({
        variables: { id: localStorage.getItem("deleteUser") },
      });

      toast.success(`Посетитель был успешно удален из системы!`);

      await refetch();
    } catch (e) {
      console.log("Ошибка при удалении >>>", e);
      toast.error(
        "При удалении посетителя произошла ошибка! Попробуйте снова или обратитесь к администратору."
      );
    } finally {
      setDialogDeleteUser(false);
      setLoading(false);
      localStorage.removeItem("deleteUser");
      navigate("/clients/");
    }
  }

  async function toggleDeletePayment(id: string) {
    if (confirm("Вы действительно хотите удалить данный платеж?")) {
      setLoading(true);
      try {
        await deletePayment({
          variables: {
            id,
          },
        });
        refetch();
      } catch (e) {
        console.log(
          "🚀 ~ file: [id].tsx ~ line 270 ~ toggleDeletePayment ~ e",
          e
        );
      } finally {
        setLoading(false);
      }
    }
  }

  async function deleteUserDebts() {
    if (
      confirm(
        "ВНИМАНИЕ! Все платежи будут помечены как оплаченные, а долги выведены в 0!"
      )
    ) {
      setLoading(true);
      axiosInstance
        .get(`/users/${id}/deleteUserDebts`)
        .then(() => {
          refreshUserRecords();
          setEditUser(false);
          toast.success("Долги были успешно аннулированы!");
        })
        .catch((err) => {
          console.log("Error while performing debt cancellation >>>", err);
          toast.error("При выполнении операции произошла ошибка!");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  const [file, setFile] = useState<Blob>();
  const [showDragNDropAvatar, setShowDragNDropAvatar] =
    useState<boolean>(false);
  const [filePreviewSrc, setFilePreviewSrc] = useState<string | null>(null);

  function toggleDragNDropInput() {
    setShowDragNDropAvatar(true);
  }

  function handleSave() {
    if (confirm("Подтвердите изменение аватара клиента")) {
      if (!filePreviewSrc || !file) return console.log(file);
      const formData = new FormData();
      // const imageNameWithPath = `${user.getOneUser.studio.id}/avatars/  ${id}.png`;

      formData.append("image", file, `${id}.png`);

      axiosInstance
        .post("/users/updateUserAvatar", formData, {
          headers: { "content-type": "multipart/form-data" },
        })
        .then(() => {
          setShowDragNDropAvatar(false);
          setEditUser(false);
          toast.success("Аватар пользователя был успешно обновлен!");
          setTimeout(() => {
            navigate(0);
          }, 1500);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  function deleteUserAvatar() {
    if (confirm("Подтвердите удаление аватара пользователя")) {
      setLoading(true);
      axiosInstance
        .post(`/users/deleteUserAvatar`, { id })
        .then((res) => {
          console.log(res);
          toast.success("Аватар пользователя был успешно обновлен!");
          setTimeout(() => {
            navigate(0);
          }, 1500);
        })
        .catch((e) => {
          console.log(e);
          toast.error("Ошибка при обновлении аватара >> ", e);
        })
        .finally(() => {
          setLoading(false);
        });
    }

    return;
  }

  function onInputFileChange(file: Blob) {
    setFile(file);
    const filePath = URL.createObjectURL(file);
    setFilePreviewSrc(filePath);
  }

  if (userLoading) return <div>Loading ...</div>;

  if (error) removeAuth();

  return (
    <InfinityPagination callback={() => fetchRecords()}>
      <>
        {/* ADD RECORDS MODAL WINDOW */}
        <ModalAddRecords
          onClose={() => setShowModalAddRecords(false)}
          show={showModalAddRecords}
          refetch={() => {
            refreshUserRecords();
            refetch();
          }}
        />

        {/* ADD PAYMENTS MODAL WINDOW */}
        <ModalAddPayments
          onClose={() => setShowModalAddPayments(false)}
          show={showModalAddPayments}
          refresh={() => {
            refetch();
            refreshUserRecords();
          }}
        />

        {/* DRAGNDROP */}
        <DragNDropInput
          show={showDragNDropAvatar}
          onClose={() => setShowDragNDropAvatar(false)}
          title={"Добавление аватара"}
          loading={false}
          handleChange={(file) => onInputFileChange(file)}
          filePreview={filePreviewSrc}
          handleSave={() => handleSave()}
        />
        {/* HEADER */}
        <div className="mb-8 flex">
          <div className="mr-3 flex w-28 flex-col overflow-hidden rounded-md">
            <div className="w-full">
              <Avatar href={id || "avoid ts-error"} />
            </div>
            {/* buttons */}
            {editUser && (
              <div className="flex w-full items-center justify-around bg-black/75">
                <Button
                  icon={<IoPencilSharp />}
                  style="white"
                  className="w-full rounded-none p-1"
                  onClick={() => toggleDragNDropInput()}
                />
                <Button
                  icon={<IoTrash />}
                  style="white"
                  className="text-red w-full rounded-none p-1"
                  onClick={() => deleteUserAvatar()}
                />
              </div>
            )}
          </div>
          <div className="flex-1">
            <div className={`mb-4 flex items-center justify-between px-3 py-2`}>
              <div>
                <div className="flex">
                  {editUser ? (
                    <Input
                      icon={<MdEdit />}
                      value={userInfo.name}
                      onChange={(e) => {
                        userInfo.name = e.target.value;
                        setUserInfo({ ...userInfo });
                      }}
                      type="text"
                      placeholder="Введите имя посетителя..."
                      color="white"
                    />
                  ) : (
                    <>
                      <h1 className={"text-dark text-2xl font-bold"}>
                        {userInfo.name}
                      </h1>
                      <Button
                        icon={<MdEdit />}
                        onClick={() => toggleEditUser()}
                        loading={loading}
                        className={"hover:text-primary"}
                        style="disabled"
                      />
                    </>
                  )}
                </div>
                {!editUser && (
                  <h6 className={"text-xs text-gray-400"}>
                    Детальная информация о{" "}
                    {userInfo.role === "client" ? "клиенте" : "сотруднике"}
                  </h6>
                )}
              </div>
              <div className="flex space-x-5">
                {editUser ? (
                  <>
                    <Button
                      label="Аннулировать долги"
                      icon={<IoAlertSharp />}
                      onClick={() => deleteUserDebts()}
                      filled
                      loading={loading}
                    />
                    <Button
                      label="Сохранить"
                      icon={<IoSaveSharp />}
                      onClick={() => saveUser()}
                      filled
                      loading={loading}
                    />
                    <Button
                      label="Удалить"
                      icon={<IoTrash />}
                      onClick={() => toggleDeleteUser(user.getOneUser.id)}
                      filled
                      loading={loading}
                      style="red"
                    />
                    <Button
                      label="Отменить"
                      icon={<MdCancel />}
                      onClick={() => setEditUser(false)}
                      loading={loading}
                    />
                  </>
                ) : (
                  <>
                    <ButtonBack />
                  </>
                )}
              </div>
            </div>

            <table
              className={`mx-2 w-auto text-xs ${editUser ? "-mt-2" : "-mt-4"}`}
            >
              <thead>
                <tr className="text-dark text-left">
                  <th className="px-2.5 py-0">Статус</th>
                  <th className="px-2.5 py-0">Роль</th>
                  <th className="px-2.5 py-0">Группа</th>
                  <th className="px-2.5 py-0">Баланс</th>
                  <th className="px-2.5 py-0">Категория</th>
                  <th className="px-2.5 py-0">Дата рождения</th>
                  <th className="px-2.5 py-0">Номер телефона</th>
                  <th className="px-2.5 py-0">Email-адрес</th>
                </tr>
              </thead>
              <tbody className="text-main">
                {editUser ? (
                  <tr>
                    <td className="py-0 px-2">
                      <div className="text-disabled flex items-center gap-2">
                        <IoLockClosedSharp />
                        {user.getOneUser.visible ? "Активен" : "В архиве"}
                      </div>
                    </td>
                    <td className="py-0 px-2">
                      <div className="text-disabled flex items-center gap-2">
                        <Select
                          items={[
                            { value: "Клиент" },
                            {
                              value: "Сотрудник",
                            },
                          ]}
                          onChange={(v) => {
                            userInfo.role =
                              v.value === "Клиент" ? "client" : "employee";
                            setUserInfo({
                              ...userInfo,
                            });
                          }}
                          value={
                            userInfo.role === "client"
                              ? { value: "Клиент" }
                              : { value: "Сотрудник" }
                          }
                          className="w-36"
                          icon={<IoColorFilterSharp />}
                          color="white"
                        />
                      </div>
                    </td>
                    <td className="py-0 px-2">
                      <div className="text-disabled flex items-center gap-2">
                        <Select
                          items={groups.getAllGroups}
                          onChange={(v) => {
                            userInfo.group = v;
                            setUserInfo({
                              ...userInfo,
                            });
                          }}
                          value={userInfo.group}
                          className="w-36"
                          icon={<IoColorFilterSharp />}
                          color="white"
                        />
                      </div>
                    </td>
                    <td className={`text-disabled px-2 py-1.5 `}>
                      <div className="flex items-center gap-2">
                        <IoLockClosedSharp />
                        {user.getOneUser.deposit - user.getOneUser.score}
                      </div>
                    </td>
                    <td className="py-0 px-2">
                      <div className="flex w-16 items-center gap-2">
                        <Input
                          icon={<MdCategory />}
                          value={userInfo.category.toUpperCase()}
                          onChange={(e) => {
                            userInfo.category = e.target.value.toUpperCase();
                            setUserInfo({ ...userInfo });
                          }}
                          type="text"
                          maskPlaceholder=""
                          mask="**"
                          color="white"
                        />
                      </div>
                    </td>
                    {/* Birthdate */}
                    <td className=" py-0 px-2">
                      <div className="flex items-center gap-2">
                        <Input
                          icon={<IoCalendarSharp />}
                          value={userInfo.birthdate || ""}
                          onChange={(e) => {
                            userInfo.birthdate = e.target.value;
                            setUserInfo({ ...userInfo });
                          }}
                          type="text"
                          maskPlaceholder="_"
                          mask="99.99.9999"
                          placeholder="Введите дату рождения..."
                          color="white"
                        />
                      </div>
                    </td>
                    <td className=" py-0 px-2">
                      <div className="flex items-center gap-2">
                        <Input
                          icon={<IoCallSharp />}
                          value={userInfo.phone || ""}
                          onChange={(e) => {
                            userInfo.phone = e.target.value;
                            setUserInfo({ ...userInfo });
                          }}
                          type="text"
                          maskPlaceholder="_"
                          mask="+7 (999) 999-99-99"
                          placeholder="Введите телефон..."
                          color="white"
                        />
                      </div>
                    </td>
                    <td className=" py-0 px-2">
                      <div className="flex items-center gap-2">
                        <Input
                          icon={<IoMailSharp />}
                          value={userInfo.email || ""}
                          onChange={(e) => {
                            userInfo.email = e.target.value;
                            setUserInfo({ ...userInfo });
                          }}
                          type="email"
                          placeholder="Введите email адрес"
                          color="white"
                        />
                      </div>
                    </td>

                    <td className="py-0 px-2"></td>
                  </tr>
                ) : (
                  <tr>
                    <td className="py-1.5 px-2">
                      <div className="flex items-center gap-2">
                        {user.getOneUser.visible ? "Активен" : "В архиве"}
                      </div>
                    </td>
                    <td className="py-1.5 px-2">
                      <div className="flex items-center gap-2">
                        {user.getOneUser.role === "client"
                          ? "Клиент"
                          : user.getOneUser.role === "employee"
                          ? "Сотрудник"
                          : "Администратор"}
                      </div>
                    </td>
                    <td className="py-1.5 px-2">
                      <div className="flex items-center gap-2">
                        {`${user.getOneUser.group.value} (${user.getOneUser.group.subs_fee}р. в мес.)`}
                      </div>
                    </td>

                    <td
                      className={`px-2 py-1.5 ${
                        user.getOneUser.deposit - user.getOneUser.score >= 0
                          ? "text-green"
                          : "text-red"
                      }`}
                    >
                      <div className="flex items-center gap-2">
                        <FaMoneyCheckAlt />
                        {user.getOneUser.deposit - user.getOneUser.score}
                      </div>
                    </td>
                    <td className="py-1.5 px-2">
                      <div className="flex items-center gap-2">
                        <MdCategory />{" "}
                        {user.getOneUser.category.value.toUpperCase()}
                      </div>
                    </td>
                    <td className=" py-1.5 px-2">
                      <div className="flex items-center gap-2">
                        <IoCalendarSharp />
                        {(user.getOneUser.birthdate !== null &&
                          new Date(
                            user.getOneUser.birthdate
                          ).toLocaleDateString("ru") +
                            " (" +
                            Math.floor(
                              (Date.now() -
                                new Date(user.getOneUser.birthdate).getTime()) /
                                3.15576e10
                            ) +
                            ")") ||
                          "Не указана"}
                      </div>
                    </td>
                    <td className=" py-1.5 px-2">
                      <div className="flex items-center gap-2">
                        <IoCallSharp />
                        {user.getOneUser.phone || "Не указан"}
                      </div>
                    </td>
                    <td className=" py-1.5 px-2">
                      <div className="flex items-center gap-2">
                        <IoMailSharp />
                        {user.getOneUser.email || "Не указан"}
                      </div>
                    </td>

                    <td className=" py-1.5 px-2"></td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        <div className="flex flex-col items-start gap-5 lg:flex-row">
          {/* LAST ACTIVITY TABLE */}
          <div className="card mb-1.5 w-full md:mb-5">
            {/* HEADER */}
            <div className="mb-4 flex items-center justify-between">
              <div>
                <h2 className="text-dark font-bold">
                  Список платежей в студии:
                </h2>
                <p className="text-xs text-gray-400">
                  Последние 20 платежей, внесенные администраторами
                </p>
              </div>
              <Button
                filled={false}
                style="primary"
                label="Добавить"
                icon={<GoPlus />}
                className="text-xs"
                onClick={() => setShowModalAddPayments(true)}
              />
            </div>
            {/* CONTENT */}
            <table className="w-full">
              <thead className="text-left text-sm text-gray-400">
                <tr className="border-b dark:border-none">
                  <th className="px-3 pb-2">№</th>
                  <th className="px-3 pb-2">Дата</th>
                  <th className="px-3 pb-2">Сумма</th>
                  <th className="px-3 pb-2 text-center">Тип</th>
                  <th className="px-3 pb-2"></th>
                </tr>
              </thead>
              <tbody className="text-xs">
                {user.getPaymentsByUserId.length === 0 ? (
                  <tr>
                    <td colSpan={5} className="text-disabled py-5">
                      {loading ? (
                        <div className="flex items-center justify-center">
                          <Loading />
                        </div>
                      ) : (
                        <div className="flex items-center justify-center gap-5">
                          <TbMoodEmpty className="text-xs" /> В системе нет
                          информации по платежам пользователя!
                        </div>
                      )}
                    </td>
                  </tr>
                ) : (
                  user.getPaymentsByUserId.map(
                    (payment: any, index: number) => (
                      <tr
                        className={`${
                          (index + 1) % 2 !== 0
                            ? "bg-gray-50 dark:bg-slate-900/40"
                            : ""
                        }`}
                        key={payment.id}
                      >
                        <td className="px-3.5 py-0.5">{index + 1}</td>
                        <td className="px-2 py-0.5">
                          {new Date(payment.date).toLocaleDateString("ru")}
                        </td>
                        <td className="px-2 py-0.5">
                          <div className="flex items-center justify-around">
                            {numberWithCommas(payment.value)} р.
                            <Popup>
                              <>{payment.comment}</>
                            </Popup>
                          </div>
                        </td>
                        <td className="px-2 py-0.5">
                          <div className="flex justify-center">
                            <span
                              className={`flex items-center gap-2 rounded px-2 py-0.5 text-xs font-bold ${
                                payment.type === "cash"
                                  ? "bg-green"
                                  : "text-yellow"
                              }`}
                            >
                              {payment.type === "cash" ? (
                                <>
                                  <IoCashSharp /> Наличные
                                </>
                              ) : payment.type === "qr" ? (
                                <>
                                  <IoQrCodeSharp /> QR
                                </>
                              ) : (
                                <>
                                  <IoCashSharp /> Перевод
                                </>
                              )}
                            </span>
                          </div>
                        </td>
                        <td className="px-2 py-0.5">
                          <Button
                            icon={<IoTrash />}
                            onClick={() => toggleDeletePayment(payment.id)}
                            style="disabled"
                          />
                        </td>
                      </tr>
                    )
                  )
                )}
              </tbody>
            </table>
          </div>
          {/* TOP EMPLOYEE TABLE */}
          <div className="card mb-5 max-h-fit w-full md:mb-5">
            <div className="mb-4">
              <h2 className="text-dark font-bold">
                Топ-5{" "}
                {user.getOneUser.role === "client" ? "сотрудников" : "клиентов"}
                :
              </h2>
              <p className="text-xs text-gray-400">По количеству записей</p>
            </div>

            <table className="w-full text-sm">
              <thead className="text-left text-xs text-gray-400">
                <tr>
                  <th className="px-2">№</th>
                  <th className="px-2">Имя</th>
                  <th className="px-2">Кол-во записей</th>
                  {Cookie.get("eitrynne-client-app_role") === "admin" && (
                    <>
                      <th className="px-2">Сумма</th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {!gettedUserRecords ? (
                  <tr>
                    <td colSpan={4} className="text-disabled py-5">
                      {loading ? (
                        <div className="flex items-center justify-center">
                          <Loading />
                        </div>
                      ) : (
                        <div className="flex items-center justify-center gap-5">
                          <TbMoodEmpty className="text-xs" /> В системе нет
                          информации по записям пользователя!
                        </div>
                      )}
                    </td>
                  </tr>
                ) : (
                  userRecordsByEmployee.map((employee: any, index: number) => (
                    <tr
                      key={employee.name + index}
                      className={`${
                        (index + 1) % 2 !== 0
                          ? "bg-gray-50 dark:bg-slate-900/40"
                          : ""
                      }`}
                    >
                      <td className="px-2.5 py-2.5">{index + 1}</td>
                      <td className="px-2 py-2.5">
                        <div className="flex gap-2">
                          <span className="h-6 w-6">
                            <Avatar href={employee.id}></Avatar>
                          </span>
                          {employee.name}
                        </div>
                      </td>
                      <td className="px-2 py-2.5">{employee.count}</td>
                      {Cookie.get("eitrynne-client-app_role") === "admin" && (
                        <>
                          <td className="px-2 py-2.5">
                            {numberWithComma(employee.totalPrice)} р.
                          </td>
                        </>
                      )}
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>

        {/* RECORDS TABLE */}
        <div className="card">
          {/* HEADER */}
          <div className="mx-2 mb-4 flex items-center justify-between">
            <h2 className="text-dark font-bold">
              Последние записи посетителя:
            </h2>
            {checkedRecords.length > 0 ? (
              <div className="flex space-x-2">
                <Button
                  label="Отметить как оплачено"
                  icon={<MdMoney />}
                  loading={loading}
                  onClick={() => markRecordPayed()}
                  className="text-xs"
                />
                <Button
                  label="Отметить как не оплачено"
                  icon={<MdMoneyOff />}
                  loading={loading}
                  onClick={() => markRecordUnpayed()}
                  className="text-xs"
                />
                <Button
                  onClick={() => setCheckedRecords([])}
                  label="Отменить"
                  icon={<MdCancel />}
                  className={"!text-xs"}
                  loading={loading}
                ></Button>
                <Button
                  onClick={() => toggleDeleteRecords()}
                  label="Удалить"
                  icon={<IoTrash />}
                  style="red"
                  className={"!text-xs"}
                  loading={loading}
                ></Button>
              </div>
            ) : (
              <div className="flex space-x-5">
                <Input
                  icon={<IoSearchSharp />}
                  type="text"
                  onChange={(e) => setRecordListSearchQuery(e.target.value)}
                  placeholder="Поиск по именам"
                  color="white"
                  value={recordListSearchQuery}
                />
                <Button
                  onClick={() => setShowModalAddRecords(true)}
                  label="Добавить"
                  icon={<GoPlus />}
                  className={"!text-xs"}
                ></Button>
              </div>
            )}
          </div>
          <table className="w-full">
            <thead className=" text-sm text-gray-400">
              <tr className="border-b dark:border-none">
                <td className="px-3 pb-2">№</td>
                <td className="px-3 pb-2">Дата</td>
                <td className="px-3 pb-2">Сотрудник</td>
                <td className="px-3 pb-2">Клиенты</td>
                <td className="px-3 pb-2 text-center">Длительность</td>
                <td className="px-3 pb-2 text-center">Стоимость</td>

                <td className="px-3 pb-2 text-center">Статус</td>
                {user.getOneUser.role === "client" && (
                  <td className="px-3 pb-2 text-center"></td>
                )}
              </tr>
            </thead>
            <tbody className="text-xs">
              {recordsList.length === 0 ? (
                <tr>
                  <td colSpan={8} className="text-disabled py-5">
                    {loading || userLoading ? (
                      <div className="flex items-center justify-center">
                        <Loading />
                      </div>
                    ) : (
                      <div className="flex items-center justify-center gap-5">
                        <TbMoodEmpty className="text-2xl" />
                        Информация по записям отсутствует!
                      </div>
                    )}
                  </td>
                </tr>
              ) : (
                recordsList.map((record: any, index: number) => (
                  <tr
                    key={record.id}
                    className={`${
                      (index + 1) % 2 !== 0
                        ? "bg-gray-50 dark:bg-slate-900/40"
                        : ""
                    }`}
                  >
                    <td className="px-3.5 py-2.5">{index + 1}</td>
                    <td className="px-2 py-2.5">
                      {new Date(record.date).toLocaleDateString("ru")}
                    </td>
                    <td className="px-2 py-2.5">
                      <NavLink
                        to={`/clients/${record.employee.id}`}
                        className="flex items-center gap-3"
                      >
                        <div className="h-6 w-6">
                          <Avatar
                            href={record.employee.id}
                            className="rounded-full"
                          />
                        </div>
                        {record.employee.name}
                      </NavLink>
                    </td>
                    <td className="px-2 py-2.5">
                      {record.prices.map((price: any, index: number) => (
                        <span key={price.id}>
                          {" "}
                          <NavLink to={`/clients/${price.user.id}`}>
                            {price.user.name}
                          </NavLink>
                          {index + 1 !== record.prices.length ? " + " : ""}
                        </span>
                      ))}
                    </td>

                    <td className="px-2 py-2.5 text-center">
                      {record.duration}
                    </td>

                    <td className="px-2 py-2.5 text-center">
                      {calcTotalPrice(record.prices)}
                    </td>

                    <td className="px-2 py-2.5 text-center">
                      {record.prices[0].status ? (
                        <span className="text-green font-bold">Оплачен</span>
                      ) : (
                        <span className="text-red font-bold">Не оплачен</span>
                      )}
                    </td>
                    {user.getOneUser.role === "client" && (
                      <>
                        <td className="px-2 py-2.5">
                          <input
                            type="checkbox"
                            className={`${
                              checkedRecords.includes(record)
                                ? "opacity-100"
                                : "opacity-50"
                            } accent-primary cursor-pointer`}
                            checked={checkedRecords.includes(record)}
                            onChange={() => pushCheckedRecord(record)}
                          />
                        </td>
                      </>
                    )}
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>

        <ModalDialog
          show={dialogDeleteUser}
          onClose={() => setDialogDeleteUser(false)}
          title="Подтвердите действие"
          loading={loading}
        >
          <div className="w-full">
            <p className="text-main mb-5">
              Вы действительно хотите удалить данного посетителя? <br />
              При удалении также <strong>уничтожатся все записи</strong>{" "}
              посетителя!{" "}
            </p>
            <div className="flex justify-between">
              <Button
                onClick={() => setDialogDeleteUser(false)}
                label="Отменить"
                icon={<MdCancel />}
                loading={loading}
              />
              <Button
                onClick={() => runDeleteUser()}
                label="Удалить"
                icon={<IoTrashBinSharp />}
                style="red"
                loading={loading}
              />
            </div>
          </div>
        </ModalDialog>

        <ModalDialog
          show={dialogMarkRecord}
          onClose={() => setDialogMarkRecord(false)}
          title={`Пометить записи как ${
            markRecordData.status ? "оплаченные" : "не оплаченные"
          }`}
          loading={loading}
          description={`${
            markRecordData.status
              ? "Укажите верные данные и подтвердите действие"
              : "Подтвердите действие"
          }`}
        >
          <div className="w-full">
            <div className="text-main mb-5 flex space-x-5">
              {markRecordData.status ? (
                <>
                  <Input
                    type="text"
                    label="Дата"
                    icon={<IoCalendarSharp />}
                    value={markRecordData.date}
                    onChange={(e) => {
                      markRecordData.date = e.target.value;
                      setMarkRecordData({ ...markRecordData });
                    }}
                    mask={`99.99.${new Date().getFullYear()}`}
                    maskPlaceholder="_"
                  />
                  <Select
                    items={[
                      {
                        value: "QR",
                      },
                      {
                        value: "Наличными",
                      },
                      {
                        value: "Перевод",
                      },
                    ]}
                    onChange={(v) =>
                      setMarkRecordData({ ...markRecordData, type: v })
                    }
                    value={markRecordData.type}
                    icon={<MdMoney />}
                    label="Способ оплаты"
                    className="w-40"
                  />
                </>
              ) : (
                <>
                  <p className="text-sm">
                    После подтверждения выбранные записи станут помечены как
                    неоплаченные.
                    <br /> Стоимость записи будет списана с баланса посетителя.
                  </p>
                </>
              )}
            </div>
            <div className="flex justify-between">
              <Button
                onClick={() => setDialogMarkRecord(false)}
                label="Отменить"
                icon={<MdCancel />}
                loading={loading}
              />
              <Button
                onClick={() => runMarkRecord()}
                label="Подтвердить"
                icon={<IoSaveSharp />}
                loading={loading}
                filled
              />
            </div>
          </div>
        </ModalDialog>
      </>
    </InfinityPagination>
  );
};

export default ClientsDetails;
