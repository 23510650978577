export default function useValidDate(value: string) {
  /*
   * 10 symbols in correct date (01.01.1970)
   * 8 symbols in short correct date (01.01.70)
   */

  const [day, month, year] = value.split(".");
  if (value.length === 10) {
    return [year, month, day].join("-");
  }

  if (value.length === 8) {
    return ["20" + year, month, day].join("-");
  }
}
