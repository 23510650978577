import { useMutation, useQuery } from "@apollo/client";
import React, { FC, useEffect, useState } from "react";
import { GoPlus } from "react-icons/go";
import {
  IoEllipsisVerticalSharp,
  IoPencilSharp,
  IoSaveSharp,
  IoTextOutline,
  IoTextSharp,
  IoTrash,
  IoTrashBinSharp,
} from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Avatar from "../../components/UI/Avatar";
import Button from "../../components/UI/Button";
import ButtonBack from "../../components/UI/ButtonBack";
import Input from "../../components/UI/Input";
import Loading from "../../components/UI/Loading";
import DragNDropInput from "../../components/UI/Modal/DragNDropInput";
import ModalDialog from "../../components/UI/ModalDialog";
import ModalMenu from "../../components/UI/ModalMenu";
import PageHeader from "../../components/UI/PageHeader";
import { axiosInstance } from "../../config/axiosConfig";
import {
  CREATE_STUDIO,
  DELETE_STUDIO,
  GET_STUDIOS,
  UPDATE_STUDIO,
} from "../../graphql/admin";

const Studios: FC = () => {
  const [loading, setLoading] = useState(false);
  const [dialogAddStudio, setDialogAddStudio] = useState(false);
  const [studio, setStudio] = useState({
    id: "",
    name: "",
    comment: "",
  });

  const {
    data: studios,
    loading: studiosLoading,
    error,
    refetch,
  } = useQuery(GET_STUDIOS);

  const [createStudio] = useMutation(CREATE_STUDIO);
  const [updateStudio] = useMutation(UPDATE_STUDIO);
  const [deleteStudio] = useMutation(DELETE_STUDIO);
  const navigate = useNavigate();
  useEffect(() => {
    refetch();
  }, []);
  async function toggleEditStudio(s: any) {
    setStudio({ ...s });
    setDialogAddStudio(true);
  }

  async function toggleDeleteStudio(s: any) {
    if (confirm("Подтвердите удаление студии")) {
      runDeleteStudio(s.id);
    }
  }

  async function runDeleteStudio(id: string) {
    setLoading(true);
    try {
      await deleteStudio({
        variables: {
          id,
        },
      });

      toast.success("Студия была удалена!");
      refetch();
    } catch (e) {
      console.log("Ошибка во время удаления студии >>>", e);
      toast.error(`Ошибка во время удаления студии ${e}`);
    } finally {
      setLoading(false);
      closeDialog();
    }
  }

  function closeDialog() {
    setDialogAddStudio(false);
    setStudio({
      id: "",
      name: "",
      comment: "",
    });
  }

  async function saveStudio() {
    setLoading(true);
    try {
      await createStudio({
        variables: {
          createStudio: {
            name: studio.name,
            comment: studio.comment,
          },
        },
      });
      await refetch();
      toast.success("Студия успешно создана");
    } catch (e) {
      console.log("Ошибка во время создания студии >>>", e);
      toast.error(`Ошибка во время создания студии >>> ${e}`);
    } finally {
      setLoading(false);
      closeDialog();
    }
  }

  async function runEditStudio() {
    setLoading(true);
    try {
      await updateStudio({
        variables: {
          updateStudio: {
            id: studio.id,
            name: studio.name,
            comment: studio.comment,
          },
        },
      });
      await refetch();
      toast.success("Студия была изменена");
    } catch (e) {
      console.log("Ошибка во время изменения студии >>>", e);
      toast.error(`Ошибка во время изменения студии >>> ${e}`);
    } finally {
      setLoading(false);
      closeDialog();
    }
  }

  const [studioImage, setStudioImage] = useState<null | Blob>(null);
  const [showDragNDropAvatar, setShowDragNDropAvatar] =
    useState<boolean>(false);
  const [filePreviewSrc, setFilePreviewSrc] = useState<string | null>(null);

  async function toggleDragNDropInput() {
    setShowDragNDropAvatar(true);
  }

  async function deleteStudioAvatar() {
    console.log("delete studio avatar!");
  }

  async function dragNDropHandleChange(image: Blob) {
    setStudioImage(image);
    const filePath = URL.createObjectURL(image);
    setFilePreviewSrc(filePath);
  }

  async function dragNDropHandleSave(id: string) {
    if (confirm("Подтвердите изменение логотипа студии")) {
      if (!filePreviewSrc || !studioImage) return console.log(studioImage);
      const formData = new FormData();
      // const imageNameWithPath = `${user.getOneUser.studio.id}/avatars/  ${id}.png`;

      formData.append("image", studioImage, `${id}.png`);

      axiosInstance
        .post("/studios/updateStudioLogo", formData, {
          headers: { "content-type": "multipart/form-data" },
        })
        .then(() => {
          setShowDragNDropAvatar(false);
          toast.success("Логотип студии был успешно обновлен!");
          setTimeout(() => {
            navigate(0);
          }, 2000);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  if (studiosLoading) return <Loading />;
  return (
    <>
      <PageHeader
        title="Панель администратора. Студии"
        description="Список всех доступных студий в Eitrynne"
      >
        <div className="flex space-x-5">
          <Button
            label="Добавить"
            icon={<GoPlus />}
            onClick={() => setDialogAddStudio(true)}
            filled
          />
          <ButtonBack />
        </div>
      </PageHeader>
      <div className="card">
        <table className="w-full">
          <thead className=" text-gray-400">
            <tr className="border-b dark:border-none">
              <td className="px-3 pb-2">№</td>
              <td className="px-3 pb-2">Логотип студии</td>
              <td className="px-3 pb-2">ID студии</td>
              <td className="px-3 pb-2">Имя студии</td>
              <td className="px-3 pb-2">Комментарий</td>
              <td className="px-3 pb-2 text-center">Всего клиентов</td>
              <td className="px-3 pb-2 text-center">Действия</td>
            </tr>
          </thead>
          <tbody className="text-main text-sm">
            {studios.getAllStudios.map((item: any, index: number) => (
              <tr
                key={item.id}
                className={
                  (index + 1) % 2 !== 0 ? "bg-gray-50 dark:bg-slate-900/40" : ""
                }
              >
                <td className="px-2 py-2.5">{index + 1}</td>
                <td className="px-2 py-2.5">
                  {<Avatar href={item.id} className="h-40 rounded" />}
                </td>
                <td className="px-2 py-2.5">{item.id}</td>
                <td className="px-2 py-2.5">{item.name}</td>
                <td className="px-2 py-2.5">{item.comment}</td>
                <td className="px-2 py-2.5">{item.users.length}</td>
                <td className="flex justify-center px-2 py-2.5">
                  <ModalMenu
                    items={[
                      {
                        title: "Изменить",
                        icon: <IoPencilSharp className="!text-sm" />,
                        callback: () => toggleEditStudio(item),
                      },
                      {
                        title: "Удалить",
                        icon: <IoTrashBinSharp className={"!text-sm"} />,
                        callback: () => toggleDeleteStudio(item),
                      },
                    ]}
                    children={<IoEllipsisVerticalSharp />}
                    position="right-8 -top-7"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <DragNDropInput
        title="Добавление аватара студии"
        show={showDragNDropAvatar}
        onClose={() => setShowDragNDropAvatar(false)}
        filePreview={filePreviewSrc}
        handleChange={dragNDropHandleChange}
        handleSave={() => dragNDropHandleSave(studio.id)}
        loading={false}
      />
      <ModalDialog
        onClose={() => closeDialog()}
        show={dialogAddStudio}
        title={studio.id ? "Редактирование группы" : "Создание новой группы"}
        loading={loading}
      >
        <div className="w-full">
          <div className="mb-5 flex space-x-5">
            <div className="relative max-h-40 w-40">
              <Avatar href={studio.id} className="rounded" />
              <div className="absolute bottom-0 flex w-full items-center justify-around bg-black/75">
                <Button
                  icon={<IoPencilSharp />}
                  style="white"
                  className="w-full rounded-none p-1"
                  onClick={() => toggleDragNDropInput()}
                />
                <Button
                  icon={<IoTrash />}
                  style="white"
                  className="text-red w-full rounded-none p-1"
                  onClick={() => deleteStudioAvatar()}
                />
              </div>
            </div>
            {/* INPUTS */}
            <div className="w-full">
              <Input
                icon={<IoTextSharp />}
                onChange={(e) =>
                  setStudio({
                    ...studio,
                    name: e.target.value,
                  })
                }
                type="text"
                value={studio.name}
                label="Название студии"
                className={"w-full"}
                placeholder="Название студии..."
              />
              <Input
                icon={<IoTextOutline />}
                onChange={(e) =>
                  setStudio({
                    ...studio,
                    comment: e.target.value,
                  })
                }
                type="text"
                value={studio.comment}
                label="Комментарий"
                placeholder="Комментарий"
                className="w-full"
              />
            </div>
          </div>
          <div className="flex justify-end">
            {studio.id ? (
              <Button
                icon={<IoSaveSharp />}
                label="Изменить"
                onClick={() => runEditStudio()}
                loading={loading}
              ></Button>
            ) : (
              <Button
                icon={<IoSaveSharp />}
                label="Создать"
                onClick={() => saveStudio()}
                loading={loading}
              ></Button>
            )}
          </div>
        </div>
      </ModalDialog>
    </>
  );
};

export default Studios;
