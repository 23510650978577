import { FC } from "react";
import TheLogo from "./TheLogo";
import TheSidebarMenu from "./TheSidebarMenu";

import Divider from "./UI/Divider";
import Button from "./UI/Button";
import { MdClose } from "react-icons/md";
import TheUserInfo from "./TheUserInfo";

interface TheSidebarProps {
  active: boolean;
  menuCloseButtonCallback: () => void;
}

const TheSidebar: FC<TheSidebarProps> = ({
  active,
  menuCloseButtonCallback,
}) => {
  return (
    <aside
      className={`shadowed group absolute z-20 h-screen ${
        !active ? "w-0 lg:hover:w-64 lg:hover:delay-200" : "w-full"
      } rounded bg-white transition-[width] ease-out  dark:bg-slate-800 dark:text-gray-400 lg:absolute lg:top-0 lg:left-0 lg:bottom-0 lg:block lg:w-16`}
    >
      <div className="flex justify-between">
        <TheLogo />

        <Button
          icon={<MdClose />}
          style="dark"
          onClick={() => menuCloseButtonCallback()}
          className={`${active ? "absolute top-2 right-2" : "hidden"} text-3xl`}
        />
      </div>
      <Divider />
      <TheSidebarMenu menuClickCallback={() => menuCloseButtonCallback()} />
      <Divider />
      <TheUserInfo />
    </aside>
  );
};

export default TheSidebar;
