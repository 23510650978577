import { gql } from "@apollo/client";

export const GET_STUDIOS = gql`
  query {
    getAllStudios {
      id
      name
      comment
      logo
      users {
        id
        name
      }
    }
  }
`;

export const CREATE_STUDIO = gql`
  mutation createStudio($createStudio: CreateStudioInput!) {
    createStudio(createStudio: $createStudio) {
      id
    }
  }
`;

export const UPDATE_STUDIO = gql`
  mutation updateStudio($updateStudio: UpdateStudioInput!) {
    updateStudio(updateStudio: $updateStudio) {
      id
    }
  }
`;

export const DELETE_STUDIO = gql`
  mutation deleteStudio($id: String!) {
    deleteStudio(id: $id) {
      id
    }
  }
`;
