import { FC } from "react";
import { Line } from "react-chartjs-2";
import { ChartData, ChartOptions } from "chart.js";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";

Chart.register(CategoryScale);

interface LineChartProps {
  options?: ChartOptions<"line">;
  data: ChartData<"line">;
}

const LineChart: FC<LineChartProps> = ({ data }) => {
  return <Line data={data} />;
};

export default LineChart;
