import { useMutation, useQuery } from "@apollo/client";
import { FC, useEffect, useState } from "react";
import { IoPencilSharp, IoPersonSharp, IoSaveSharp } from "react-icons/io5";
import { MdCancel } from "react-icons/md";
import { toast } from "react-toastify";
import PricesSkeleton from "../../components/skeletons/clients/prices";
import Button from "../../components/UI/Button";
import ButtonBack from "../../components/UI/ButtonBack";
import Icon from "../../components/UI/Icon";
import Input from "../../components/UI/Input";
import PageHeader from "../../components/UI/PageHeader";
import { GET_PRICES, UPDATE_PRICE, UPDATE_USER } from "../../graphql/users";
import { Category, Price } from "../../types";
import removeAuth from "../../hooks/removeAuth";
import Cookie from "js-cookie";
import Cookies from "js-cookie";
import getPageName from "../../utils/getPageName";

const UsersPrices: FC = () => {
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [employees, setEmployees] = useState([{}]);

  const {
    data: prices,
    loading: pricesLoading,
    refetch,
    error,
  } = useQuery(GET_PRICES);
  const [updateUser] = useMutation(UPDATE_USER);
  const [updatePrice] = useMutation(UPDATE_PRICE);
  useEffect(() => {
    refetch();
    document.title = getPageName("Прайс-лист");
  }, []);
  if (pricesLoading) return <PricesSkeleton />;

  async function saveChanges() {
    // TODO: need to change that functions
    let errors = false;
    const editedEmployees = employees.filter(
      // @ts-ignore
      (employee) => employee.edited === true
    );

    console.log(editedEmployees);
    for await (const employee of editedEmployees) {
      setLoading(true);
      try {
        updateUser({
          variables: {
            updateUser: {
              //@ts-ignore
              id: employee.id,
              //@ts-ignore
              role: employee.role,
              //@ts-ignore
              category: employee.category,
              //@ts-ignore
              record_fee_45: parseInt(employee.record_fee_45),
              //@ts-ignore
              record_fee_60: parseInt(employee.record_fee_60),
              //@ts-ignore
              group_fee: parseInt(employee.group_fee),
              //@ts-ignore
              role: employee.role,
            },
          },
        });

        // @ts-ignore
        for await (const price of employee.prices) {
          updatePrice({
            variables: {
              updatePrice: {
                id: price.id,
                value: parseInt(price.value),
              },
            },
          });
        }
      } catch (e) {
        errors = true;
        console.log("При обновлении прайс-листа произошла ошибка >>> ", e);
      } finally {
        await refetch();
        setLoading(false);
        setEditMode(false);
      }
    }

    if (!errors) {
      toast.success(`Прайс-лист был успешно обновлен!`);
      return;
    }

    toast.error(
      "При обновлении прайс-листа произошла ошибка! Попробуйте снова или обратитесь к администратору!"
    );
  }

  async function toggleEditPrice() {
    // ? возможно можно сделать код более красивым!
    if (!editMode) {
      const editedPrices: any = [];
      for (const employee of prices.getAllEmployees) {
        const priceValues: any = [];

        employee.prices.forEach((price: any) => {
          priceValues.push({
            id: price.id,
            category: price.category.value,
            value: price.value,
          });
        });

        editedPrices.push({
          id: employee.id,
          role: employee.role,
          name: employee.name,
          category: employee.category.value,
          prices: priceValues,
          record_fee_45: employee.record_fee_45,
          record_fee_60: employee.record_fee_60,
          group_fee: employee.group_fee,
          edited: false,
        });
      }
      setEmployees(editedPrices);
    }
    setEditMode(!editMode);
  }

  if (error) removeAuth();

  return (
    <>
      <PageHeader title="Прайс-лист" description="Расценки всех сотрудников">
        <div className="flex justify-between">
          {!editMode && (
            <div className="flex items-center space-x-5">
              <Button
                label="Изменить"
                icon={<IoPencilSharp />}
                filled
                onClick={toggleEditPrice}
              />

              <ButtonBack />
            </div>
          )}
          {editMode && (
            <div className="flex items-center space-x-3">
              <Button
                label="Сохранить"
                icon={<IoSaveSharp />}
                filled
                style="green"
                onClick={() => saveChanges()}
                loading={loading}
              />
              <Button
                label="Отменить"
                icon={<MdCancel />}
                onClick={toggleEditPrice}
                style="red"
                loading={loading}
              />
            </div>
          )}
        </div>
      </PageHeader>
      <div className="card overflow-x-auto">
        <table className="w-max">
          <thead className=" text-gray-400">
            <tr className="border-b ">
              <th className="w-64 px-3 pb-2">Сотрудник</th>
              <>
                <th className="w-20 px-3 pb-2 text-left text-xs">
                  Ставка (45)
                </th>
                <th className="w-20 px-3 pb-2 text-left text-xs">
                  Ставка (60)
                </th>
                <th className="w-20 px-3 pb-2 text-left text-xs">
                  Ставка (Группа)
                </th>
              </>
              {prices.getAllCategories.map((category: Category) => (
                <th className="w-20 px-3 pb-2 text-center" key={category.id}>
                  {category.value.toUpperCase()}
                </th>
              ))}
            </tr>
          </thead>
          {!editMode && (
            <tbody className="text-main text-sm">
              {prices.getAllEmployees.map((employee: any) => (
                <tr key={Math.random().toString(16)}>
                  <td className="flex items-center space-x-5 px-2 py-2.5">
                    <Icon>
                      <IoPersonSharp />
                    </Icon>
                    <span>{employee.name}</span>
                  </td>
                  <>
                    <td className="px-2 py-2.5">
                      <span>{employee.record_fee_45 || "-"}</span>
                    </td>
                    <td className="px-2 py-2.5">
                      <span>{employee.record_fee_60 || "-"}</span>
                    </td>
                    <td className="px-2 py-2.5">
                      <span>{employee.group_fee || "-"}</span>
                    </td>
                  </>
                  {employee.prices &&
                    employee.prices.map((price: Price) => (
                      <td
                        className="w-24 px-2 py-2 text-center"
                        key={Math.random().toString(16)}
                      >
                        <span className="w-full">{price.value}</span>
                      </td>
                    ))}
                </tr>
              ))}
            </tbody>
          )}
          {editMode && (
            <tbody className="text-main text-sm">
              {employees.map((employee: any) => (
                <tr key={employee.id}>
                  <td className="flex w-32 items-center space-x-5 px-2 py-2.5">
                    <Icon>
                      <IoPersonSharp />
                    </Icon>
                    <span>{employee.name}</span>
                  </td>
                  <td className="w-32 px-2 py-2.5">
                    <Input
                      icon={<IoPencilSharp />}
                      type="text"
                      value={employee.record_fee_45 || ""}
                      onChange={(e) => {
                        employee.record_fee_45 = e.target.value;
                        employee.edited = true;
                        setEmployees([...employees]);
                      }}
                      color="white"
                    />
                  </td>
                  <td className="w-32 px-2 py-2.5">
                    <Input
                      icon={<IoPencilSharp />}
                      type="text"
                      value={employee.record_fee_60 || ""}
                      onChange={(e) => {
                        employee.record_fee_60 = e.target.value;
                        employee.edited = true;
                        setEmployees([...employees]);
                      }}
                      color="white"
                    />
                  </td>
                  <td className="w-32 px-2 py-2.5">
                    <Input
                      icon={<IoPencilSharp />}
                      type="text"
                      value={employee.group_fee || ""}
                      onChange={(e) => {
                        employee.edited = true;
                        employee.group_fee = e.target.value;
                        setEmployees([...employees]);
                      }}
                      color="white"
                    />
                  </td>
                  {employee.prices &&
                    employee.prices.map((price: Price) => (
                      <td className="w-28 px-2 py-2 text-center" key={price.id}>
                        <Input
                          icon={<IoPencilSharp />}
                          type="text"
                          value={price.value}
                          onChange={(e) => {
                            employee.edited = true;
                            price.value = e.target.value;
                            setEmployees([...employees]);
                          }}
                          color="white"
                        />
                      </td>
                    ))}
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
    </>
  );
};

export default UsersPrices;
