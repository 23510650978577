import { useMutation } from "@apollo/client";
import React, { FC, useState } from "react";
import { GoPlus } from "react-icons/go";
import { IoCalendarSharp, IoTrash, IoSaveSharp } from "react-icons/io5";
import { MdPayment, MdMoney, MdComment } from "react-icons/md";
import { toast } from "react-toastify";
import { CREATE_PAYMENT } from "../../../graphql/users";
import useValidDate from "../../../hooks/validDate";
import UserSearch from "../../UserSearch";
import Button from "../Button";
import Input from "../Input";
import ModalDialog from "../ModalDialog";
import Select from "../Select";

interface ModalAddPaymentsProps {
  onClose: () => void;
  refresh: () => void;
  show: boolean;
}

const ModalAddPayments: FC<ModalAddPaymentsProps> = ({
  onClose,
  refresh,
  show,
}) => {
  const [loading, setLoading] = useState(false);

  const initialPayment = {
    id: Math.random().toString(16).slice(2),
    user: {},
    value: "",
    type: {
      value: "QR",
    },
    date: new Date().toLocaleDateString("ru"),
    comment: "Оплата за ",
  };
  const [newPayments, setNewPayments] = useState<any[]>([initialPayment]);
  const [createPayment] = useMutation(CREATE_PAYMENT);

  function removeNewPayment(c: any) {
    if (newPayments.length > 1) {
      const clients = newPayments.filter((item) => item !== c);
      setNewPayments(clients);
      return;
    }

    setNewPayments([initialPayment]);
  }

  async function saveNewPayments() {
    setLoading(true);
    try {
      for await (const { value, comment, user, date, type } of newPayments) {
        await createPayment({
          variables: {
            createPayment: {
              value: parseInt(value),
              comment,
              userId: user.id,
              type:
                type.value === "QR"
                  ? "qr"
                  : type.value === "Наличными"
                  ? "cash"
                  : "transfer",
              date: useValidDate(date),
            },
          },
        });
      }

      toast.success("Платежи были успешно добавлены!");
      onClose();
      setNewPayments([initialPayment]);
    } catch (e) {
      console.log("При сохранении новых платежей произошла ошибка >>>", e);
      toast.error(
        "При сохранении новых платежей произошла ошибка! Попробуйте снова или обратитесь к администратору программы"
      );
    } finally {
      setLoading(false);
      refresh();
    }
  }

  return (
    <>
      <ModalDialog
        onClose={() => onClose()}
        show={show}
        title="Добавление нового платежа"
        loading={loading}
      >
        <div className="text-main text-sm">
          {newPayments.map((payment, index) => (
            <div
              key={payment.id}
              className="flex flex-col-reverse gap-3 border-t py-5 dark:border-gray-700 lg:flex-row-reverse lg:items-center"
            >
              <Button
                icon={<IoTrash />}
                onClick={() => removeNewPayment(payment)}
                loading={loading}
                className="self-end text-xl"
              />

              <Input
                type="text"
                label="Комментарий"
                icon={<MdComment />}
                value={payment.comment}
                onChange={(e) => {
                  payment.comment = e.target.value;
                  setNewPayments([...newPayments]);
                }}
                placeholder="Комментарий к платежу"
              />
              <Select
                items={[
                  {
                    value: "QR",
                  },
                  {
                    value: "Наличными",
                  },
                  {
                    value: "Перевод",
                  },
                ]}
                value={payment.type}
                icon={<MdMoney />}
                label="Способ оплаты"
                onChange={(v) => {
                  payment.type = v;
                  setNewPayments([...newPayments]);
                }}
              />

              <Input
                type="text"
                label="Сумма платежа"
                icon={<MdPayment />}
                value={payment.value}
                onChange={(e) => {
                  payment.value = e.target.value;
                  setNewPayments([...newPayments]);
                }}
                placeholder="Сумма платежа"
              />
              <Input
                type="text"
                label="Дата"
                icon={<IoCalendarSharp />}
                value={payment.date}
                onChange={(e) => {
                  payment.date = e.target.value;
                  setNewPayments([...newPayments]);
                }}
                mask={`99.99.${new Date().getFullYear()}`}
                maskPlaceholder="_"
              />

              <div className="relative">
                <UserSearch
                  label="Посетитель"
                  onChange={(e) => {
                    payment.user = e;
                    setNewPayments([...newPayments]);
                  }}
                  value={payment.user}
                />
                <span
                  className={`${
                    payment.user.deposit &&
                    payment.user.deposit - payment.user.score >= 0
                      ? "text-green"
                      : "text-red"
                  }
                  ${!payment.user.score && !payment.user.deposit && "hidden"}
                    absolute text-xs`}
                >
                  {payment.user.deposit &&
                  payment.user.deposit - payment.user.score >= 0
                    ? `Баланс: ${payment.user.deposit - payment.user.score}`
                    : `Долг: ${payment.user.deposit - payment.user.score}`}
                </span>
              </div>
            </div>
          ))}
          <div className="flex justify-between">
            <Button
              icon={<GoPlus />}
              onClick={() =>
                setNewPayments([
                  ...newPayments,
                  {
                    ...initialPayment,
                    id: Math.random().toString(16).slice(2),
                    date: newPayments[newPayments.length - 1].date,
                  },
                ])
              }
            />
            <Button
              label="Сохранить"
              icon={<IoSaveSharp />}
              onClick={() => saveNewPayments()}
            />
          </div>
        </div>
      </ModalDialog>
    </>
  );
};

export default ModalAddPayments;
