import React, { FC } from "react";
import Icon from "./Icon";
import Loading from "./Loading";

interface ButtonProps {
  label?: string;
  icon?: JSX.Element;
  type?: "button" | "submit" | "reset";
  style?:
    | "primary"
    | "red"
    | "green"
    | "disabled"
    | "loading"
    | "dark"
    | "white";
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  filled?: boolean;
  loading?: boolean;
  disabled?: boolean;
  className?: string;
  skeleton?: boolean;
}

const Button: FC<ButtonProps> = ({
  label,
  loading = false,
  disabled = false,
  icon,
  type = "button",
  style = "primary",
  onClick,
  filled = false,
  className,
  skeleton = false,
}) => {
  if (skeleton) {
    return (
      <button
        onClick={onClick}
        className={`form-element bg-${style} flex w-28 cursor-not-allowed items-center justify-center py-[11px] text-center font-bold ${className} gap-2`}
      >
        <Loading />
      </button>
    );
  }
  if (filled) {
    return (
      <button
        onClick={onClick}
        className={`form-element flex max-w-max items-center justify-center text-center font-bold ${className} gap-2 ${
          loading || disabled
            ? "bg-disabled cursor-not-allowed text-white dark:!bg-slate-700 dark:text-gray-400"
            : `cursor-pointer bg-${style}`
        }`}
        type={type}
        disabled={loading || disabled}
      >
        {loading ? <Loading /> : icon} {label}
      </button>
    );
  }
  return (
    <button
      onClick={onClick}
      className={`form-element font-bold !shadow-none text-${style} ${className} flex items-center justify-center gap-2 ${
        loading || disabled
          ? "text-disabled cursor-not-allowed"
          : `cursor-pointer text-${style}`
      }`}
      type={type}
      disabled={loading || disabled}
    >
      {loading ? <Loading /> : icon} {label}
    </button>
  );
};

export default Button;
