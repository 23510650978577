import React, { FC } from "react";
import "./AuthAnimatedBackground.css";

interface AuthAnimatedBackgroundProps {
  children: JSX.Element;
}

const AuthAnimatedBackground: FC<AuthAnimatedBackgroundProps> = ({
  children,
}) => {
  return (
    <div className="area">
      <div>{children}</div>
      <ul className="circles">
        <li className="bg-gray-300 dark:bg-slate-700"></li>
        <li className="bg-gray-300 dark:bg-slate-700"></li>
        <li className="bg-gray-300 dark:bg-slate-700"></li>
        <li className="bg-gray-300 dark:bg-slate-700"></li>
        <li className="bg-gray-300 dark:bg-slate-700"></li>
        <li className="bg-gray-300 dark:bg-slate-700"></li>
        <li className="bg-gray-300 dark:bg-slate-700"></li>
        <li className="bg-gray-300 dark:bg-slate-700"></li>
        <li className="bg-gray-300 dark:bg-slate-700"></li>
        <li className="bg-gray-300 dark:bg-slate-700"></li>
      </ul>
    </div>
  );
};

export default AuthAnimatedBackground;
