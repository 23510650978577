import { Routes, Route } from "react-router-dom";
import Layout from "./components/layout";
import Expenses from "./pages/expenses";
import Login from "./pages/login";
import PrivateRoutes from "./utils/privateRoutes";
import { ToastContainer } from "react-toastify";
import Cookie from "js-cookie";

import "react-toastify/dist/ReactToastify.css";
import "./styles/index.css";
import UsersIndex from "./pages/clients";
import UsersCategories from "./pages/clients/categories";
import UsersPrices from "./pages/clients/prices";
import RecordsIndex from "./pages/records";
import RecordsAdd from "./pages/records/add";
import ClientsDetails from "./pages/clients/[id]";
import Payments from "./pages/clients/payments";
import UsersGroup from "./pages/clients/groups";
import AdminRoutes from "./utils/adminRoutes";
import Studios from "./pages/admin/studios";
import AdminFunctions from "./pages/admin/functions";
import Reports from "./pages/reports";
import NotFoundPage from "./pages/404";

export default function App() {
  return (
    <div className="app">
      <Routes>
        {/* Protected routes */}
        <Route element={<PrivateRoutes />}>
          <Route element={<Layout />} path="/">
            <Route element={<Expenses />} index />
            {/* Clients pages */}
            <Route path="clients">
              <Route element={<UsersIndex />} index />
              <Route element={<UsersCategories />} path="categories" />
              <Route element={<UsersPrices />} path="prices" />
              <Route element={<ClientsDetails />} path=":id" />
              <Route element={<Payments />} path="payments" />
              <Route element={<UsersGroup />} path="groups" />
            </Route>

            {/* Records pages */}
            <Route path="records">
              <Route element={<RecordsIndex />} index />
              <Route element={<RecordsAdd />} path="add" />
            </Route>

            {/* 404 page */}
            <Route path="*" element={<NotFoundPage />} />
            <Route element={<Reports />} path="/reports" />
            {Cookie.get("eitrynne-client-app_username") ===
              "Daniil Dmitriev" && (
              <Route element={<AdminRoutes />}>
                <Route element={<Studios />} path="admin/studios" />
                <Route element={<AdminFunctions />} path="admin/functions" />
              </Route>
            )}
          </Route>
        </Route>

        {/* Login page */}
        <Route element={<Login />} path="/login"></Route>
      </Routes>

      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
}
