import { gql } from "@apollo/client";

export const CREATE_RECORD = gql`
  mutation createRecord($createRecord: CreateRecordsInput!) {
    createRecord(createRecord: $createRecord) {
      id
      date
      prices {
        id
        value
        user {
          id
          name
        }
      }
    }
  }
`;

export const CREATE_USER_MONTH_PAYMENT = gql`
  mutation createMonthSubsPayment($userId: String!) {
    createMonthSubsPayment(userId: $userId) {
      id
    }
  }
`;

export const CHANGE_RECORD_STATUS = gql`
  mutation changeRecordStatus($recordStatus: ChangeRecordStatusInput!) {
    changeRecordStatus(recordStatus: $recordStatus) {
      id
    }
  }
`;

export const GET_RECORDS = gql`
  query (
    $employeeName: String!
    $count: Float!
    $offset: Float!
    $recordsStatus: String!
  ) {
    getAllRecords(
      employeeName: $employeeName
      count: $count
      offset: $offset
      recordsStatus: $recordsStatus
    ) {
      id
      employee {
        id
        name
      }
      duration
      date
      prices {
        id
        value
        user {
          id
          name
        }
        status
      }
    }
  }
`;

export const DELETE_RECORD = gql`
  mutation deleteRecord($id: String!) {
    deleteRecord(id: $id) {
      id
    }
  }
`;
