import { useQuery } from "@apollo/client";
import { Combobox, Transition } from "@headlessui/react";
import React, { FC, useEffect, useState } from "react";
import { IoPersonSharp, IoCodeOutline } from "react-icons/io5";
import { GET_USERS } from "../graphql/users";
import useDebounce from "../hooks/debounce";
import { Client } from "../types";
import Icon from "./UI/Icon";
import Loading from "./UI/Loading";
import removeAuth from "../hooks/removeAuth";
import { Navigate, useNavigate } from "react-router-dom";
import Avatar from "./UI/Avatar";

interface UserSearchProps {
  label: string;
  value: any;
  role?: string;
  onChange: (e: any) => void;
}
const UserSearch: FC<UserSearchProps> = ({
  label,
  value,
  role = "all",
  onChange,
}) => {
  const [query, setQuery] = useState("");
  const debouncedValue = useDebounce(query);

  const {
    data: employees,
    loading: employeesLoading,
    error,
    refetch,
  } = useQuery(GET_USERS, {
    variables: {
      searchUsers: {
        name: debouncedValue.trim(),
        role,
      },
    },
  });
  useEffect(() => {
    refetch();
  }, []);
  const navigate = useNavigate();

  const [selectedPerson] = useState({
    id: "",
    name: "",
  });

  if (error) {
    removeAuth();
    navigate("/login");
  }

  return (
    <div className="text-xs">
      {/* LiveSearch component */}
      <Combobox value={value} onChange={onChange}>
        <Combobox.Label className={"text-sm"}>{label}</Combobox.Label>
        <div className="form-element bordered  mt-1 flex w-full items-center rounded bg-slate-50 !py-[3px] !px-0 dark:border-none dark:bg-slate-700 dark:text-gray-400">
          <Icon>
            {/* @ts-ignore */}
            {selectedPerson.id ? (
              <img
                src={`https://placekitten.com/401/401`}
                alt="avatar"
                className="h-6 w-6 rounded-full py-0"
              />
            ) : (
              <IoPersonSharp className="text-sm" />
            )}
          </Icon>
          <Combobox.Input
            onChange={(event) => setQuery(event.target.value)}
            className="w-full bg-slate-50 outline-none dark:bg-slate-700 lg:w-auto"
            displayValue={(person: Client) => person.name}
            placeholder="Начните вводить ФИО"
            autoComplete="none"
            name={`${Math.random().toString(8)}`}
          />
          <Combobox.Button className=" inset-y-0 right-0 flex items-center pr-0">
            <Icon className="rotate-90" aria-hidden="true">
              <IoCodeOutline className="text-xs" />
            </Icon>
          </Combobox.Button>
        </div>
        <Transition
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-0 opacity-0"
          enterTo="transform scale-100 opacity-100"
        >
          <Combobox.Options
            className={
              "shadowed absolute mt-1 w-full rounded bg-white dark:bg-slate-700"
            }
          >
            {employeesLoading ? (
              <div className="flex h-8 items-center justify-center text-xl">
                <Loading />
              </div>
            ) : employees.getUsers.length > 0 &&
              debouncedValue.trim() !== "" ? (
              employees.getUsers.map((person: Client, index: number) => (
                <Combobox.Option
                  key={person.id}
                  value={person}
                  className={({ active }) =>
                    `text-main relative flex cursor-pointer select-none items-center gap-2 rounded py-1.5 pl-2 pr-4 ${
                      active
                        ? "bg-slate-100 dark:bg-slate-600 dark:text-gray-300"
                        : "text-gray-900 dark:text-gray-400"
                    }`
                  }
                >
                  <Avatar href={person.id} className="h-6 w-6 rounded-full" />
                  {person.name}
                </Combobox.Option>
              ))
            ) : debouncedValue.trim() !== "" ? (
              <div className="text-disabled py-2 text-center">
                Ничего не найдено!
              </div>
            ) : (
              <div className="text-disabled py-2 text-center">
                Начните вводить ФИО!
              </div>
            )}
          </Combobox.Options>
        </Transition>
      </Combobox>
    </div>
  );
};

export default UserSearch;
