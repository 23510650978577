import Cookie from "js-cookie";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoutes = () => {
  try {
    return Cookie.get("eitrynne-client-app_username") ? (
      <Outlet />
    ) : (
      <Navigate to="/login" />
    );
  } catch (e) {
    console.log(e);
    return <div>Error handlers</div>;
  }
};

export default PrivateRoutes;
