import { FC } from "react";
import Button from "../../UI/Button";
import ButtonBack from "../../UI/ButtonBack";
import Loading from "../../UI/Loading";
import PageHeader from "../../UI/PageHeader";

const PricesSkeleton: FC = () => {
  return (
    <>
      <PageHeader title="" skeleton>
        <div className="flex items-center space-x-5">
          <Button className="h-full" skeleton />
          <ButtonBack />
        </div>
      </PageHeader>
      <div className="card h-[200px]">
        <table className="w-full">
          <thead className=" text-gray-400">
            <tr className="border-b ">
              <td className="px-3 pb-8">Сотрудник</td>
              <td className="px-3 pb-8">Ставка</td>
            </tr>
          </thead>
        </table>
        <div className="text-main flex h-full w-full items-center justify-center text-2xl">
          <Loading />
        </div>
      </div>
    </>
  );
};

export default PricesSkeleton;
