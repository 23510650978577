import {
  IoColorFilterOutline,
  IoColorFilterSharp,
  IoDocumentTextOutline,
  IoDocumentTextSharp,
  IoListOutline,
  IoListSharp,
  IoPeopleOutline,
  IoPeopleSharp,
  IoPersonSharp,
  IoPricetagOutline,
  IoPricetagSharp,
  IoSettingsOutline,
  IoSettingsSharp,
} from "react-icons/io5";
import { RiPlayListAddLine } from "react-icons/ri";
import {
  MdCategory,
  MdOutlineCategory,
  MdOutlinePayments,
  MdPayment,
  MdWorkspaces,
  MdWorkspacesOutline,
} from "react-icons/md";
import { GiDeathSkull } from "react-icons/gi";
import Cookie from "js-cookie";

export interface MenuItem {
  id: string;
  title: string;
  path: string;
  icons: {
    default: JSX.Element;
    selected: JSX.Element;
  };
  items?: MenuItem[];
}

const menuItems: MenuItem[] = [
  // {
  //   id: "1",
  //   title: "Главная",
  //   path: "/",
  //   icons: {
  //     default: <IoHomeOutline />,
  //     selected: <IoHomeSharp />,
  //   },
  // },
  {
    id: "2",
    title: "Записи",
    path: "/records",
    icons: {
      default: <IoListOutline />,
      selected: <IoListSharp />,
    },
    items: [
      {
        id: "2_1",
        title: "Все записи",
        path: "/records",
        icons: {
          default: <IoListOutline />,
          selected: <IoListSharp />,
        },
      },
      // This page was replaced by a modal window in one of the last updates
      // {
      //   id: "2_2",
      //   title: "Добавить записи",
      //   path: "/records/add",
      //   icons: {
      //     default: <RiPlayListAddLine />,
      //     selected: <RiPlayListAddLine />,
      //   },
      // },
    ],
  },
  {
    id: "3",
    title: "Посетители",
    path: "/clients",
    icons: {
      default: <IoPeopleOutline />,
      selected: <IoPersonSharp />,
    },
    items: [
      {
        id: "2_1",
        title: "Все посетители",
        path: "/clients",
        icons: {
          default: <IoPeopleOutline />,
          selected: <IoPeopleSharp />,
        },
      },
      {
        id: "2_2",
        title: "Категории",
        path: "/clients/categories",
        icons: {
          default: <MdOutlineCategory />,
          selected: <MdCategory />,
        },
      },
      {
        id: "2_3",
        title: "Прайс-лист",
        path: "/clients/prices",
        icons: {
          default: <IoPricetagOutline />,
          selected: <IoPricetagSharp />,
        },
      },
      {
        id: "2_4",
        title: "Платежи",
        path: "/clients/payments",
        icons: {
          default: <MdOutlinePayments />,
          selected: <MdPayment />,
        },
      },
      {
        id: "2_5",
        title: "Группы",
        path: "/clients/groups",
        icons: {
          default: <IoColorFilterOutline />,
          selected: <IoColorFilterSharp />,
        },
      },
    ],
  },
  {
    id: "4",
    title: "Отчеты",
    path: "/reports",
    icons: {
      default: <IoDocumentTextOutline />,
      selected: <IoDocumentTextSharp />,
    },
  },
];
if (Cookie.get("eitrynne-client-app_username") === "Daniil Dmitriev")
  menuItems.push({
    id: "88888888",
    title: "Администратор",
    path: "/records",
    icons: {
      default: <GiDeathSkull />,
      selected: <GiDeathSkull />,
    },
    items: [
      {
        id: "2_1",
        title: "Список студий",
        path: "/admin/studios",
        icons: {
          default: <MdWorkspacesOutline />,
          selected: <MdWorkspaces />,
        },
      },
      {
        id: "2_2",
        title: "Доп. функции",
        path: "/admin/functions",
        icons: {
          default: <IoSettingsOutline />,
          selected: <IoSettingsSharp />,
        },
      },
    ],
  });

export default menuItems;
