import { useQuery } from "@apollo/client";
import { Combobox, Transition } from "@headlessui/react";
import React, { useState } from "react";
import { IoPersonSharp, IoCodeOutline, IoSearchSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { GET_USERS } from "../graphql/users";
import useDebounce from "../hooks/debounce";
import removeAuth from "../hooks/removeAuth";
import { Client } from "../types";
import Avatar from "./UI/Avatar";
import Icon from "./UI/Icon";
import Loading from "./UI/Loading";

const TheHeaderSearch = () => {
  const initialValue = {
    id: "",
    name: "",
  };
  const [value, setValue] = useState(initialValue);
  const [query, setQuery] = useState("");
  const debouncedValue = useDebounce(query);

  const navigate = useNavigate();

  const {
    data: clients,
    loading: clientsLoading,
    error,
    refetch,
  } = useQuery(GET_USERS, {
    variables: {
      searchUsers: {
        name: debouncedValue.trim(),
      },
    },
  });

  function onKeyUp({ code }: any) {
    if (code === "Enter") {
      navigate(`/clients/${value.id}`);
      setValue(initialValue);
    }
  }

  if (error) {
    removeAuth();
  }

  return (
    <div className=" text-xs">
      {/* LiveSearch component */}
      <Combobox value={value} onChange={setValue}>
        <div className="form-element text-main mt-1 flex w-full items-center rounded bg-slate-50 !py-[3px] !px-0 dark:bg-slate-700">
          <Icon className="!text-sm">
            <IoSearchSharp />
          </Icon>
          <Combobox.Input
            onChange={(event) => setQuery(event.target.value)}
            className="bg-slate-50 outline-none dark:bg-slate-700"
            displayValue={(person: Client) => person.name}
            placeholder="Поиск в системе..."
            autoComplete="none"
            name={`${Math.random().toString(8)}`}
            onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => onKeyUp(e)}
          />
        </div>
        <Transition
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-0 opacity-0"
          enterTo="transform scale-100 opacity-100"
        >
          <Combobox.Options
            className={
              "shadowed absolute mt-1 w-full rounded bg-white  dark:bg-slate-700 dark:text-gray-400"
            }
          >
            {clientsLoading ? (
              <div className="flex h-8 items-center justify-center text-xl">
                <Loading />
              </div>
            ) : clients.getUsers.length > 0 && debouncedValue.trim() !== "" ? (
              clients.getUsers.map((person: Client, index: number) => (
                <Combobox.Option
                  key={person.id}
                  value={person}
                  className={({ active }) =>
                    `text-main relative flex cursor-pointer select-none items-center gap-2 rounded py-1.5 pl-2 pr-4 ${
                      active
                        ? "bg-slate-100 dark:bg-slate-600 dark:text-gray-300"
                        : "text-gray-900 dark:text-gray-400"
                    }`
                  }
                  onClick={() => {
                    navigate(`/clients/${person.id}`);
                    setValue(initialValue);
                  }}
                >
                  <div className="h-6 w-6">
                    <Avatar href={person.id} />
                  </div>
                  {person.name}
                </Combobox.Option>
              ))
            ) : debouncedValue.trim() !== "" ? (
              <div className="text-disabled py-2 text-center">
                По вашему запросу ничего не найдено!
              </div>
            ) : (
              <div className="text-disabled py-2 text-center">
                Введите запрос!
              </div>
            )}
          </Combobox.Options>
        </Transition>
      </Combobox>
    </div>
  );
};

export default TheHeaderSearch;
