import { Listbox, Transition } from "@headlessui/react";
import { FC, Fragment, useState } from "react";
import { IoCheckmarkSharp, IoCodeOutline } from "react-icons/io5";
import Icon from "./Icon";

interface SelectElement {
  value: string;
}

interface SelectProps {
  items: SelectElement[];
  icon?: JSX.Element;
  className?: string;
  label?: string;
  value?: SelectElement;
  onChange: (e: any) => void;
  color?: string;
}

const Select: FC<SelectProps> = ({
  items,
  icon,
  label,
  className,
  value,
  onChange,
  color,
}) => {
  const [selected, setSelected] = useState(items[0]);
  return (
    <div
      className={`text-main flex flex-col ${className}  ${
        label ? "space-y-1" : null
      }`}
    >
      <label className={`w-full text-sm`}>{label ? `${label}:` : ""}</label>
      <Listbox value={value} onChange={(e) => onChange(e)}>
        <div className="text-main relative mt-1 flex">
          <Listbox.Button
            className={`form-element relative z-0 flex w-full items-center border border-gray-300 dark:border-none dark:bg-slate-700 ${
              color ? `bg-${color}` : "bg-slate-50"
            } !px-8 text-left`}
          >
            <span className="absolute left-3">{icon}</span>
            <span className="block truncate">
              {(value && value.value) || "Выберите верный вариант"}
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <Icon className="rotate-90" aria-hidden="true">
                <IoCodeOutline className="text-xs" />
              </Icon>
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute z-10 mt-10 max-h-60 w-full min-w-fit overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-slate-700 dark:shadow-slate-900 sm:text-sm">
              {items.map((item, index) => (
                <Listbox.Option
                  key={index}
                  className={({ active }) =>
                    `relative z-10 cursor-pointer select-none py-3 pl-8 pr-4 ${
                      active ? "bg-slate-100 dark:bg-slate-600" : "text-main"
                    }`
                  }
                  value={item}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${
                          value?.value == item.value
                            ? "font-bold"
                            : "font-normal"
                        }`}
                      >
                        {item.value}
                      </span>
                      {value?.value == item.value ? (
                        <span className="text-main absolute inset-y-0 left-1 flex items-center">
                          <Icon className="text-[10px]" aria-hidden="true">
                            <IoCheckmarkSharp />
                          </Icon>
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
};

export default Select;
