import React, { FC } from "react";
import Chart from "chart.js/auto";
import { CategoryScale, ChartData, ChartOptions } from "chart.js";
import { Doughnut } from "react-chartjs-2";

Chart.register(CategoryScale);

interface DoughnutChartProps {
  options?: ChartOptions<"doughnut">;
  data: ChartData<"doughnut">;
}

const DoughnutChart: FC<DoughnutChartProps> = ({ data, options }) => {
  return (
    <>
      <Doughnut data={data} />
    </>
  );
};

export default DoughnutChart;
