import { useMutation, useQuery } from "@apollo/client";
import React, { FC, useState } from "react";
import { BsFillCalendarMonthFill } from "react-icons/bs";
import { toast } from "react-toastify";
import Button from "../../components/UI/Button";
import ButtonBack from "../../components/UI/ButtonBack";
import PageHeader from "../../components/UI/PageHeader";
import {
  CREATE_RECORD,
  CREATE_USER_MONTH_PAYMENT,
} from "../../graphql/records";
import { GET_GROUP_WITH_SUBS, GET_USER } from "../../graphql/users";

const AdminFunctions: FC = () => {
  const [loading, setLoading] = useState(false);
  const {
    data: groupsWithSubsFee,
    loading: groupsWithSubsFeeLoading,
    refetch,
  } = useQuery(GET_GROUP_WITH_SUBS);
  const [createMonthSubsPayment] = useMutation(CREATE_USER_MONTH_PAYMENT);
  async function addSubsFeeForUsers() {
    setLoading(true);
    await refetch();
    try {
      for await (const group of groupsWithSubsFee.getAllGroupsWithSubsFee) {
        for await (const user of group.users) {
          await createMonthSubsPayment({
            variables: {
              userId: user.id,
            },
          });
        }
      }

      toast.success("Месячная плата по абонементам была добавлена");
    } catch (e) {
      console.log(e);
      toast.error("При обновлении пользователей произошла ошибка!");
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <PageHeader
        title="Панель администратора"
        description="Функции суперпользователя"
      >
        <div>
          <ButtonBack />
        </div>
      </PageHeader>

      <div className="card">
        <table className="w-full text-xs">
          <thead className=" text-gray-400">
            <tr className="border-b dark:border-none">
              <td className="px-3 pb-2">№</td>
              <td className="px-3 pb-2">Описание</td>
              <td className="px-3 pb-2"></td>
            </tr>
          </thead>
          <tbody className="text-dark text-sm dark:text-gray-400">
            <tr>
              <td className="px-2 py-2.5">1</td>
              <td className="px-2 py-2.5">
                Начисление платы по абонементу каждому посетителю
              </td>
              <td className="px-2 py-2.5">
                <Button
                  filled
                  label="Плата по абонементу"
                  icon={<BsFillCalendarMonthFill />}
                  onClick={() => addSubsFeeForUsers()}
                  loading={loading}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};
export default AdminFunctions;
