import React, { FC } from "react";
import { IoReturnDownForwardOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const ButtonBack: FC = () => {
  const navigate = useNavigate();

  return (
    <button
      onClick={() => navigate(-1)}
      className={`form-element text-primary hidden cursor-pointer items-center justify-center gap-2 font-bold !shadow-none lg:flex`}
    >
      <IoReturnDownForwardOutline /> Назад
    </button>
  );
};

export default ButtonBack;
