// TODO : Добавить информацию по ЗП для отчета по конкретному педагогу

import { useQuery } from "@apollo/client";
import { Disclosure, Transition } from "@headlessui/react";
import { FC, useEffect, useState } from "react";
import {
  IoCalendarSharp,
  IoCashSharp,
  IoChevronDown,
  IoChevronUpSharp,
  IoCreateSharp,
  IoQrCodeSharp,
  IoRefreshSharp,
  IoSettingsSharp,
} from "react-icons/io5";
import BarChart from "../components/reports/BarChart";
import DoughnutChart from "../components/reports/DoughnutChart";
import LineChart from "../components/reports/LineChart";
import Button from "../components/UI/Button";
import ButtonBack from "../components/UI/ButtonBack";
import Input from "../components/UI/Input";
import PageHeader from "../components/UI/PageHeader";
import { axiosInstance } from "../config/axiosConfig";
import { GET_REPORTS_DATA } from "../graphql/users";
import numberWithComma from "../hooks/numberWithComma";
import useValidDate from "../hooks/validDate";
import Cookie from "js-cookie";
import getPageName from "../utils/getPageName";
import Select from "../components/UI/Select";
import UserSearch from "../components/UserSearch";
import { AxiosResponse } from "axios";
import RecordsAdd from "./records/add";
import { toast } from "react-toastify";
import getCorrectWordEnding from "../hooks/correctWordEnding";
import Loading from "../components/UI/Loading";
import { TbMoodEmpty } from "react-icons/tb";

interface IPaymentTotal {
  payment_type: string;
  payment_sum: number;
}
const Reports: FC = () => {
  useEffect(() => {
    document.title = getPageName("Отчеты");
  }, []);

  const [loading, setLoading] = useState(false);

  const [showAllReports, setShowAllReports] = useState(false);
  const [showDetailsReports, setShowDetailsReports] = useState(false);
  const currentDate = new Date();
  const initialReport = {
    type: { value: "Общий отчет" },
    user: {},
    // begin: new Date(
    //   currentDate.getFullYear(),
    //   currentDate.getMonth(),
    //   1
    // ).toLocaleDateString("ru"),
    begin: new Date().toLocaleDateString("ru"),
    end: new Date().toLocaleDateString("ru"),
  };
  const [reportParams, setReportParams] = useState<any>({ ...initialReport });
  const [reportQueryParams, setReportQueryParams] = useState<any>({
    begin: new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    ).toLocaleDateString("ru"),
    end: new Date().toLocaleDateString("ru"),
  });
  // Detailed report
  const [topEmployee, setTopEmployee] = useState([]);
  const [allClientRecords, setAllClientRecords] = useState([]);
  const [allEmployeeRecords, setAllEmployeeRecords] = useState([]);
  const [allPayments, setAllPayments] = useState([]);

  // All payments for the specified period
  const [paymentTotalData, setPaymentTotalData] = useState<any>({});
  const [paymentTotalDescription, setPaymentTotalDescription] = useState<
    IPaymentTotal[]
  >([]);
  const [paymentTotalSum, setPaymentTotalSum] = useState<number>(0);
  const [paymentRentTotalSum, setPaymentRentTotalSum] = useState<number>(0);

  // All payments by date
  const [paymentByDateData, setPaymentByDateData] = useState<any>({});

  // All payments by Employee
  const [paymentsByEmployee, setPaymentsByEmployee] = useState<any>([]);

  // Employee records
  const [employeeRecords, setEmployeeRecords] = useState<any>([]);

  // Employee salary
  const [employeeSalary, setEmployeeSalary] = useState<number>(0);

  // Employees total salary for Total Reports
  const [employeeTotalSalary, setEmployeeTotalSalary] = useState<number>(0);

  // Employee salary-rent
  const [employeeSalaryRent, setEmployeeSalaryRent] = useState<number>(0);

  // Employee records count
  const [employeeCountRecords, setEmployeeCountRecords] = useState<any>({});

  // User total debts
  const [userDebts, setUserDebts] = useState<number>(0);

  async function createReport() {
    setLoading(true);
    if (reportParams.type.value == "По одному посетителю") {
      try {
        return axiosInstance
          .post(`/reports`, {
            begin: useValidDate(reportParams.begin),
            end: useValidDate(reportParams.end),
            user: reportParams.user,
          })
          .then(({ data }: AxiosResponse) => {
            console.log(
              "🚀 ~ file: reports.tsx ~ line 86 ~ .then ~ data",
              data
            );
            const currentBorderColor =
              localStorage.getItem("theme") === "dark" ? "#1E293B" : "#E6E6E6";

            setPaymentTotalData({
              labels: data.payments.byType.map(
                (item: any) => item.payment_type
              ),
              datasets: [
                {
                  label: "Всего платежей",
                  data: data.payments.byType.map(
                    (item_1: any) => item_1.payment_sum
                  ),
                  backgroundColor: [
                    "#4F46E5",
                    "#EA526F",
                    "#3196A0",
                    "#FCC521",
                    "#364652",
                  ],
                  borderColor: [currentBorderColor],
                  borderWidth: 1,
                },
              ],
            });

            setAllPayments(data.payments.list);
            setAllClientRecords(data.records.clientList);
            setAllEmployeeRecords(data.records.employeeList);
            setPaymentTotalDescription(data.payments.byType);
            setPaymentTotalSum(data.payments.totalSum);
            setTopEmployee(data.records.byEmployee);
            setEmployeeSalary(data.userTotalSalary);
            setEmployeeSalaryRent(data.userTotalSalaryRent);
            setEmployeeCountRecords(data.userCountRecords);

            setUserDebts(data.userTotalDebts || 0);

            setShowAllReports(false);
            setShowDetailsReports(true);
          });
      } catch (e) {
        console.log("🚀 ~ file: reports.tsx ~ line 115 ~ createReport ~ e", e);
      } finally {
        setLoading(false);
      }
    }

    // all reports
    try {
      const { data } = await axiosInstance.post(`/reports`, {
        begin: useValidDate(reportParams.begin),
        end: useValidDate(reportParams.end),
      });
      console.log("🚀 ~ file: reports.tsx ~ line 115 ~ .then ~ data", data);

      setPaymentsByEmployee(data.paymentsByEmployee);
      setEmployeeRecords(data.employeeRecords);

      // * Payment Total Data
      const currentBorderColor =
        localStorage.getItem("theme") === "dark" ? "#1E293B" : "#E6E6E6";

      setPaymentTotalData({
        labels: data.paymentsByType.all.map((item: any) => item.payment_type),
        datasets: [
          {
            label: "Всего платежей",
            data: data.paymentsByType.all.map(
              (item_1: any) => item_1.payment_sum
            ),
            backgroundColor: [
              "#4F46E5",
              "#EA526F",
              "#3196A0",
              "#FCC521",
              "#364652",
            ],
            borderColor: [currentBorderColor],
            borderWidth: 1,
          },
        ],
      });
      setPaymentRentTotalSum(data.paymentsByType.rent);

      setPaymentByDateData({
        labels: data.paymentsByDate.all.map((item_2: any) =>
          new Date(item_2.payment_date).toLocaleDateString("ru")
        ),
        datasets: [
          {
            label: "Все платежи",
            data: data.paymentsByDate.all.map(
              (item_3: any) => item_3.payment_sum
            ),
            backgroundColor: "#4F46E5",
            borderColor: "#4F46E5",
          },
          {
            label: "Аренда",
            data: data.paymentsByDate.rent.map(
              (item_4: any) => item_4.payment_sum
            ),
            backgroundColor: "#EA526F",
            borderColor: "#EA526F",
          },
        ],
      });

      setPaymentTotalDescription(data.paymentsByType.all);
      setPaymentTotalSum(
        data.paymentsByType.all.reduce((a: any, b: any) => {
          return a + parseInt(b.payment_sum);
        }, 0)
      );
      setShowAllReports(true);
      setShowDetailsReports(false);
      setEmployeeTotalSalary(data.employeeTotalSalary);
    } catch (e) {
      console.log("🚀 ~ file: reports.tsx ~ line 108 ~ .then ~ e", e);
      toast.error(
        "Ошибка при формировании отчета! Проверьте правильность введенных данных!"
      );
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <PageHeader
        title="Отчеты"
        description="Формирование отчетов по данным, внесенным в программу"
      >
        <div className="flex items-center justify-between">
          <ButtonBack />
        </div>
      </PageHeader>

      {/* REPORT PARAMS */}
      <div className="card space-y-3">
        <div>
          <h2 className="text-dark text-md font-bold">
            Укажите параметры отчета
          </h2>
          <p className="text-xs font-light text-gray-400">
            В данный момент скачивание отчетов не доступно
          </p>
        </div>
        <div className="text-main flex flex-col justify-start gap-5 lg:flex-row">
          <div className="flex flex-col-reverse gap-5 text-xs lg:flex-row-reverse lg:items-center">
            <Input
              type="text"
              label="Дата конца"
              icon={<IoCalendarSharp />}
              value={reportParams.end}
              onChange={(e) => {
                setReportParams({ ...reportParams, end: e.target.value });
              }}
              mask={`99.99.2099`}
              maskPlaceholder="_"
            />
            <Input
              type="text"
              label="Дата начала"
              icon={<IoCalendarSharp />}
              value={reportParams.begin}
              onChange={(e) => {
                setReportParams({ ...reportParams, begin: e.target.value });
              }}
              mask={`99.99.2099`}
              maskPlaceholder="_"
            />
            {reportParams.type.value === "По одному посетителю" && (
              <UserSearch
                label="Имя посетителя"
                onChange={(v) => {
                  reportParams.user = { ...v };
                  setReportParams({
                    ...reportParams,
                  });

                  console.log(reportParams);
                }}
                value={reportParams.user || ""}
              />
            )}
            <Select
              items={[
                {
                  value: "Общий отчет",
                },
                // {
                //   value: "По всем посетителям",
                // },
                {
                  value: "По одному посетителю",
                },
              ]}
              label="Тип отчета"
              icon={<IoSettingsSharp />}
              onChange={(v) => setReportParams({ ...reportParams, type: v })}
              value={reportParams.type}
              className="w-full lg:w-56"
            />
          </div>
          <div className="flex w-full justify-between lg:w-auto">
            <Button
              label="Сформировать"
              icon={<IoCreateSharp />}
              filled
              onClick={() => {
                createReport();
              }}
              className="mt-6"
              loading={loading}
            />
            <Button
              icon={<IoRefreshSharp />}
              onClick={() => {
                setReportParams({ ...initialReport });
                setShowAllReports(false);
              }}
              className="mt-6"
            />
          </div>
        </div>
      </div>
      {showDetailsReports && (
        <>
          <div className="card flex w-full flex-col gap-5 lg:flex-row">
            {allPayments.length > 0 && (
              <div className="flex w-full justify-around">
                <div
                  className="
               h-72 w-72"
                >
                  <DoughnutChart data={paymentTotalData} />
                </div>
              </div>
            )}
            <div className="space-y-3 ">
              <div className="border-b pb-3 dark:border-b-gray-700">
                <h5 className="text-md text-dark w-full font-bold">
                  Всего платежей:
                </h5>
                <p className="text-xs text-gray-400">
                  Оплаченные за период с {reportParams.begin} -{" "}
                  {reportParams.end}
                </p>
              </div>
              <ul className="text-sm font-light ">
                {paymentTotalDescription.map((item: IPaymentTotal) => (
                  <li key={item.payment_type} className=" py-1">
                    {item.payment_type} - {numberWithComma(item.payment_sum)} р.
                    ({Math.round((item.payment_sum / paymentTotalSum) * 100)}
                    %)
                  </li>
                ))}
                <li className="border-b py-1 font-bold dark:border-gray-700">
                  Всего: {numberWithComma(paymentTotalSum)} р.
                </li>

                {reportParams.user.role === "employee" && (
                  <>
                    <li className="mt-2 py-1 dark:border-t-gray-700">
                      ЗП за текущий период: {employeeSalary} р.
                      <ul className="mt-2 text-xs italic dark:border-gray-700">
                        <li>{employeeCountRecords[45]} по 45 мин.;</li>
                        <li>{employeeCountRecords[60]} по 60 мин.;</li>
                        <li>
                          {employeeCountRecords["group"]} групп
                          {getCorrectWordEnding(
                            "female",
                            employeeCountRecords["group"]
                          )}
                          ;
                        </li>
                      </ul>
                    </li>
                    <li className=" border-b py-1 dark:border-gray-700">
                      Аренда с уроков: {employeeSalaryRent} р.
                    </li>
                  </>
                )}

                <li className="py-1 dark:border-t-gray-700">
                  Записей на сумму: {numberWithComma(userDebts)} р.
                </li>

                <li className="py-1 font-bold dark:border-t-gray-700">
                  Баланс за период:{" "}
                  <span
                    className={`${
                      paymentTotalSum - userDebts < 0
                        ? "text-red"
                        : "text-green"
                    }`}
                  >
                    {numberWithComma(paymentTotalSum - userDebts)} р.
                  </span>
                </li>
              </ul>
            </div>
            <div className="w-full justify-around space-y-3 border-t py-3 dark:border-gray-700 lg:w-1/2 lg:border-none lg:py-0">
              <div>
                <h2 className="text-dark font-bold">
                  Топ-5{" "}
                  {reportParams.user.role === "employee"
                    ? "клиентов"
                    : "педагогов"}
                  :
                </h2>
                <p className="text-xs text-gray-400">По количеству записей</p>
              </div>
              <table className="w-full text-sm">
                <thead className="text-left text-xs text-gray-400">
                  <tr>
                    <th>№</th>
                    <th>Имя</th>
                    <th>Кол-во уроков</th>
                    <th>Сумма</th>
                  </tr>
                </thead>
                <tbody>
                  {topEmployee.map((employee: any, index: number) => (
                    <tr
                      key={employee.name + index}
                      className={`${
                        (index + 1) % 2 !== 0
                          ? "bg-gray-50 dark:bg-slate-900/40"
                          : ""
                      }`}
                    >
                      <td className="px-2 py-2.5">{index + 1}</td>
                      <td className="px-2 py-2.5">{employee.name}</td>
                      <td className="px-2 py-2.5">{employee.count}</td>
                      <td className="px-2 py-2.5">{employee.totalPrice}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="flex flex-col gap-3 xl:flex-row">
            {reportParams.user.role === "employee" && (
              <div className="card w-full space-y-3 overflow-x-auto xl:w-1/2">
                <div>
                  <h2 className="text-dark font-bold">
                    Все записи как сотрудника:
                  </h2>
                  <p className="text-xs text-gray-400">
                    Список всех записей за указанный период
                  </p>
                </div>
                <table className="text-main w-max text-xs">
                  <thead className="text-left text-xs text-gray-400">
                    <tr>
                      <th className="px-2 py-3">Дата</th>
                      <th className="px-2 py-3">
                        {reportParams.user.role === "employee"
                          ? "Клиент"
                          : "Педагог"}
                      </th>
                      <th className="px-2 py-3">Длительность</th>
                      <th className="px-2 py-3">Стоимость</th>
                      <th className="px-2 py-3">Соло / В паре</th>
                      <th className="px-2 py-3">Статус</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allEmployeeRecords.length === 0 ? (
                      <tr>
                        <td colSpan={6} className="text-disabled py-5">
                          {loading ? (
                            <div className="flex items-center justify-center">
                              <Loading />
                            </div>
                          ) : (
                            <div className="flex items-center justify-center gap-5">
                              <TbMoodEmpty className="text-2xl" /> Так сложились
                              обстоятельства, что записи отсутствуют!
                            </div>
                          )}
                        </td>
                      </tr>
                    ) : (
                      allEmployeeRecords.map((record: any, index) => (
                        <tr
                          key={record.name + index}
                          className={`${
                            (index + 1) % 2 !== 0
                              ? "bg-gray-50 dark:bg-slate-900/40"
                              : ""
                          }`}
                        >
                          <td className="px-2 py-1.5">
                            {new Date(record.date).toLocaleDateString("ru")}
                          </td>
                          <td className="px-2 py-1.5">{record.name}</td>
                          <td className="px-2 py-1.5">{record.duration}</td>
                          <td className="px-2 py-1.5">{record.value}</td>
                          <td className="px-2 py-1.5">
                            {record.record_type === "couple"
                              ? "В паре"
                              : "Соло"}
                          </td>
                          <td className="px-2 py-1.5">
                            {record.status ? (
                              <span className="text-green text-xs">
                                Оплачено
                              </span>
                            ) : (
                              <span className="text-red text-xs">
                                Не оплачено
                              </span>
                            )}
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            )}

            <div className="card w-full space-y-3 overflow-x-auto xl:w-1/2">
              <div>
                <h2 className="text-dark font-bold">Все записи как клиента:</h2>
                <p className="text-xs text-gray-400">
                  Список всех записей за указанный период
                </p>
              </div>
              <table className="text-main w-max text-xs">
                <thead className="text-left text-xs text-gray-400">
                  <tr>
                    <th className="px-2 py-3">Дата</th>
                    <th className="px-2 py-3">
                      {reportParams.user.role === "employee"
                        ? "Клиент"
                        : "Педагог"}
                    </th>
                    <th className="px-2 py-3">Длительность</th>
                    <th className="px-2 py-3">Стоимость</th>
                    <th className="px-2 py-3">Соло / В паре</th>
                    <th className="px-2 py-3">Статус</th>
                  </tr>
                </thead>
                <tbody>
                  {allClientRecords.length === 0 ? (
                    <tr>
                      <td colSpan={6} className="text-disabled py-5">
                        {loading ? (
                          <div className="flex items-center justify-center">
                            <Loading />
                          </div>
                        ) : (
                          <div className="flex items-center justify-center gap-5">
                            <TbMoodEmpty className="text-2xl" /> Так сложились
                            обстоятельства, что записи отсутствуют!
                          </div>
                        )}
                      </td>
                    </tr>
                  ) : (
                    allClientRecords.map((record: any, index) => (
                      <tr
                        key={record.name + index}
                        className={`${
                          (index + 1) % 2 !== 0
                            ? "bg-gray-50 dark:bg-slate-900/40"
                            : ""
                        }`}
                      >
                        <td className="px-2 py-1.5">
                          {new Date(record.date).toLocaleDateString("ru")}
                        </td>
                        <td className="px-2 py-1.5">{record.name}</td>
                        <td className="px-2 py-1.5">{record.duration}</td>
                        <td className="px-2 py-1.5">{record.value}</td>
                        <td className="px-2 py-1.5">
                          {record.record_type === "couple" ? "В паре" : "Соло"}
                        </td>
                        <td className="px-2 py-1.5">
                          {record.status ? (
                            <span className="text-green text-xs">Оплачено</span>
                          ) : (
                            <span className="text-red text-xs">
                              Не оплачено
                            </span>
                          )}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>

            {allPayments.length > 0 && (
              <div className="card w-full space-y-3 xl:w-1/2">
                <div>
                  <h2 className="text-dark font-bold">Все платежи:</h2>
                  <p className="text-xs text-gray-400">
                    Список всех платежей за указанный период
                  </p>
                </div>
                <table className="text-main w-full text-xs">
                  <thead className="text-left text-xs text-gray-400">
                    <tr>
                      <th className="px-2 py-3">Дата</th>
                      <th className="px-2 py-3">Сумма</th>
                      <th className="px-2 py-3">Тип</th>
                      <th className="px-2 py-3">Комментарий</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allPayments.map((payment: any, index) => (
                      <tr
                        key={payment.id}
                        className={`${
                          (index + 1) % 2 !== 0
                            ? "bg-gray-50 dark:bg-slate-900/40"
                            : ""
                        }`}
                      >
                        <td className="px-2 py-1.5">
                          {new Date(payment.date).toLocaleDateString("ru")}
                        </td>
                        <td className="px-2 py-1.5">{payment.value}</td>
                        <td className="px-2 py-1.5">
                          <div className="flex justify-center">
                            <span
                              className={`flex w-24 items-center gap-2 rounded px-2 py-0.5 text-xs font-bold ${
                                payment.type === "cash"
                                  ? "bg-green"
                                  : "text-dark bg-yellow"
                              }`}
                            >
                              {payment.type === "cash" ? (
                                <>
                                  <IoCashSharp /> Наличные
                                </>
                              ) : payment.type === "qr" ? (
                                <>
                                  <IoQrCodeSharp /> QR
                                </>
                              ) : (
                                <>
                                  <IoCashSharp /> Перевод
                                </>
                              )}
                            </span>
                          </div>
                        </td>
                        <td className="px-2 py-1.5">{payment.comment}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </>
      )}
      {/* REPORT RESULT */}
      {showAllReports && (
        <>
          <div className="card flex w-full flex-col gap-5 lg:flex-row">
            <div className="flex w-full justify-around lg:w-2/6">
              <div
                className="
               h-80 w-80"
              >
                <DoughnutChart data={paymentTotalData} />
              </div>
            </div>
            <>
              <div className="w-full lg:w-1/6">
                <h5 className="text-md text-dark w-full font-bold">
                  Всего платежей:
                </h5>
                <ul className="text-sm font-light ">
                  {paymentTotalDescription.map((item: IPaymentTotal) => (
                    <li key={item.payment_type} className=" py-1">
                      {item.payment_type} - {numberWithComma(item.payment_sum)}{" "}
                      ({Math.round((item.payment_sum / paymentTotalSum) * 100)}
                      %)
                    </li>
                  ))}
                  <li className="mt-2 border-t py-1 font-bold dark:border-t-gray-700">
                    Всего: {numberWithComma(paymentTotalSum)} р.
                    <span className="block pt-1.5 text-xs font-normal text-gray-400">
                      Аренда зала:{" "}
                      {paymentRentTotalSum
                        ? numberWithComma(paymentRentTotalSum)
                        : 0}{" "}
                      р. (
                      {paymentRentTotalSum
                        ? Math.round(
                            (paymentRentTotalSum / paymentTotalSum) * 100
                          )
                        : 0}{" "}
                      %)
                    </span>
                    <span className="block pt-1.5 text-xs font-normal text-gray-400">
                      ЗП педагогов: {numberWithComma(employeeTotalSalary)} р.
                    </span>
                    <span className="block pt-1.5 text-xs font-bold text-gray-400">
                      Чистая прибыль:{" "}
                      {numberWithComma(paymentTotalSum - employeeTotalSalary)}{" "}
                      р.
                    </span>
                  </li>
                </ul>
              </div>
              <div className="flex w-full justify-around lg:w-3/6">
                <div
                  className="
               h-80 w-full"
                >
                  <LineChart data={paymentByDateData} />
                </div>
              </div>
            </>
          </div>
          <div className="flex flex-col gap-5 lg:flex-row">
            <div className="card w-full overflow-x-auto lg:w-1/2">
              <div>
                <div className="mx-2 mb-4 flex items-center justify-between">
                  <h2 className="text-dark font-bold">
                    Сумма платежей по тренерам:
                  </h2>
                </div>
                <table className="w-full">
                  <thead className=" text-xs text-gray-400">
                    <tr className="border-b dark:border-none">
                      <th className="px-3 pb-2 text-left">Имя</th>
                      <th className="px-3 pb-2 text-left">Ожидаемая сумма</th>
                      <th className="px-3 pb-2 text-left">Оплачено</th>
                    </tr>
                  </thead>
                  <tbody className="text-dark text-sm dark:text-gray-400">
                    {paymentsByEmployee.map((item: any, index: number) => (
                      <tr
                        key={item.name + index}
                        className={
                          (index + 1) % 2 !== 0
                            ? "bg-gray-50 dark:bg-slate-900/40"
                            : ""
                        }
                      >
                        <td className="px-2 py-2.5">{item.name}</td>
                        <td className="px-2 py-2.5">{`${numberWithComma(
                          item.expect
                        )} р.`}</td>
                        <td className="px-2 py-2.5">{`${numberWithComma(
                          item.real
                        )} р.`}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="card w-full overflow-x-auto lg:w-1/2">
              <>
                <div className="mx-2 mb-4 flex items-center justify-between">
                  <h2 className="text-dark font-bold">Зарплаты педагогов:</h2>
                </div>
                <table className="w-full">
                  <thead className=" text-xs text-gray-400">
                    <tr className="border-b dark:border-none">
                      <th className="w-1/2 px-3 pb-2 text-left">Имя</th>
                      <th className="w-1/4 px-3 pb-2 text-left">Зарплата</th>
                      <th className="w-1/4 px-3 pb-2 text-left">Аренда</th>
                    </tr>
                  </thead>
                </table>
                {employeeRecords.map((employee: any, index: number) => (
                  <Disclosure key={index}>
                    {({ open }) => (
                      <>
                        <Disclosure.Button
                          className={`${
                            open ? "bg-gray-100 dark:bg-slate-700" : ""
                          } mt-3 flex w-full items-center justify-between rounded px-2 py-2.5 text-left text-sm `}
                        >
                          <span className="w-1/2">{employee.name}</span>
                          <div className="flex w-1/2 px-2 text-xs">
                            <span className="w-1/2">
                              ЗП: {`${numberWithComma(employee.totalFee)} р.`}
                            </span>
                            <span className="w-1/2">
                              Аренда:{" "}
                              {`${numberWithComma(
                                employee.totalPrice - employee.totalFee
                              )} р.`}
                            </span>
                            {open ? <IoChevronUpSharp /> : <IoChevronDown />}
                          </div>
                        </Disclosure.Button>
                        <Transition
                          show={open}
                          enter="transition duration-100 ease-out"
                          enterFrom="transform scale-95 opacity-0"
                          enterTo="transform scale-100 opacity-100"
                          leave="transition duration-75 ease-out"
                          leaveFrom="transform scale-100 opacity-100"
                          leaveTo="transform scale-95 opacity-0"
                        >
                          <Disclosure.Panel static className="py-2">
                            <table className="w-full">
                              <thead className=" text-xs text-gray-400">
                                <tr className="border-b dark:border-none">
                                  <th className="px-3 pb-2">№</th>
                                  <th className="px-3 pb-2">Дата</th>
                                  <th className="px-3 pb-2">Клиенты</th>
                                  <th className="px-3 pb-2">Длит.</th>
                                  <th className="px-3 pb-2">Стоимость</th>
                                  <th className="px-3 pb-2">ЗП</th>
                                  <th className="px-3 pb-2">Аренда</th>
                                </tr>
                              </thead>
                              <tbody className="text-xs">
                                {employee.records.map(
                                  (record: any, index: number) => (
                                    <tr
                                      key={record.id}
                                      className={
                                        (index + 1) % 2 !== 0
                                          ? "bg-gray-50 dark:bg-slate-900/40"
                                          : ""
                                      }
                                    >
                                      <td className="px-2 py-1">{index + 1}</td>
                                      <td className="px-2 py-1">
                                        {new Date(
                                          record.date
                                        ).toLocaleDateString("ru")}
                                      </td>
                                      <td className="px-2 py-1 text-xs">
                                        {record.clients.map((client: any) => (
                                          <span className="block w-full">
                                            {client.name}
                                          </span>
                                        ))}
                                      </td>
                                      <td className="px-2 py-1 text-center">
                                        {record.duration}
                                      </td>
                                      <td className="px-2 py-1 text-center">
                                        {record.price}
                                      </td>
                                      <td className="px-2 py-1 text-center">
                                        {record.fee}
                                      </td>
                                      <td className="px-2 py-1 text-center">
                                        {record.price - record.fee > 0
                                          ? record.price - record.fee
                                          : 0}
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </Disclosure.Panel>
                        </Transition>
                      </>
                    )}
                  </Disclosure>
                ))}
              </>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Reports;
