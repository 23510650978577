import { useMutation, useQuery } from "@apollo/client";
import React, { FC, useEffect, useState } from "react";
import {
  IoEllipsisVerticalSharp,
  IoInformationSharp,
  IoPencilSharp,
  IoSaveSharp,
  IoTextOutline,
  IoTrash,
  IoTrashBinSharp,
} from "react-icons/io5";
import { GoPlus } from "react-icons/go";
import { MdCancel, MdEdit, MdOutlineComment } from "react-icons/md";
import { toast } from "react-toastify";
import Button from "../../components/UI/Button";
import ButtonBack from "../../components/UI/ButtonBack";
import Input from "../../components/UI/Input";
import PageHeader from "../../components/UI/PageHeader";
import {
  CREATE_CATEGORY,
  DELETE_CATEGORY,
  GET_CATEGORIES,
  UPDATE_CATEGORY,
} from "../../graphql/users";
import ModalMenu from "../../components/UI/ModalMenu";
import CategoriesSkeleton from "../../components/skeletons/clients/categories";
import ModalDialog from "../../components/UI/ModalDialog";
import { Category, Client } from "../../types";
import removeAuth from "../../hooks/removeAuth";
import { Disclosure, Tab } from "@headlessui/react";
import classNames from "../../hooks/classNames";
import { NavLink, useNavigate } from "react-router-dom";
import getPageName from "../../utils/getPageName";

const UsersCategories: FC = () => {
  const [dialogAddCategory, setDialogAddCategory] = useState(false);
  const [dialogDeleteCategory, setDialogDeleteCategory] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [category, setCategory] = useState<Category>({
    id: null,
    value: "",
    comment: "",
    users: [],
  });

  const {
    data: categories,
    loading: loadingGetCategories,
    error,
    refetch,
  } = useQuery(GET_CATEGORIES);

  useEffect(() => {
    refetch();
    document.title = getPageName("Все категории");
  }, []);
  const [createCategory] = useMutation(CREATE_CATEGORY);

  const [updateCategory] = useMutation(UPDATE_CATEGORY);

  const [deleteCategory] = useMutation(DELETE_CATEGORY);

  async function saveCategory() {
    try {
      setLoading(true);
      if (category.value.length === 0)
        throw new Error("Недопустимое значение для категории");

      if (category.comment.length === 0)
        throw new Error("Недопустимое значение для комментария категории");

      const { data } = await createCategory({
        variables: {
          createCategory: {
            value: category.value.toLowerCase(),
            comment: category.comment,
          },
        },
      });
      await refetch();

      closeDialog();

      toast.success(
        `Категория «${data.createCategory.value}» была успешно добавлена!`
      );
    } catch (e) {
      console.log("Ошибка при добавлении категории!", e);
      toast.error(
        `Ошибка при добавлении категории! Попробуйте снова или обратитесь к администратору.`
      );
    } finally {
      setCategory({
        id: null,
        value: "",
        comment: "",
        users: [],
      });

      setLoading(false);
    }
  }

  function closeDialog() {
    setCategory({
      id: null,
      value: "",
      comment: "",
      users: [],
    });

    setDialogAddCategory(false);
  }
  async function toggleEditCategory(c: Category) {
    setCategory({ ...c, value: c.value.toUpperCase() });
    setDialogAddCategory(true);
  }
  async function runEditCategory() {
    setLoading(true);
    try {
      await updateCategory({
        variables: {
          updateCategory: {
            id: category.id,
            value: category.value.toLowerCase(),
            comment: category.comment,
          },
        },
      });
      await refetch();
      toast.success("Категория была изменена");
    } catch (e) {
      console.log("Ошибка во время изменения категории >>>", e);
      toast.error(
        "Во время изменения категории произошла ошибка! Попробуйте снова или обратитесь к администратору."
      );
    } finally {
      setLoading(false);
      closeDialog();
    }
  }

  async function toggleDeleteCategory(c: Category) {
    if (!c.id) return;
    localStorage.setItem("deleteCategory", c.id);
    setDialogDeleteCategory(true);
  }

  async function runDeleteCategory() {
    setLoading(true);

    try {
      await deleteCategory({
        variables: { id: localStorage.getItem("deleteCategory") },
      });
      toast.success(`Категория была успешно удалена!`);
      await refetch();
    } catch (e) {
      console.log("Ошибка при удалении категории >>> ", e);
      toast.error(
        "Ошибка при удалении категории! Попробуйте снова или обратитесь к администратору."
      );
    } finally {
      localStorage.removeItem("deleteCategory");
      closeDialog();
      setLoading(false);
      setDialogDeleteCategory(false);
      setDialogAddCategory(false);
    }
  }

  // RENDER
  if (loadingGetCategories) return <CategoriesSkeleton />;
  if (error) {
    removeAuth();
  }

  return (
    <>
      <PageHeader
        title="Категории"
        description="Список всех категорий посетителей"
      >
        <div className="flex items-center space-x-5">
          <Button
            label="Добавить"
            icon={<GoPlus />}
            onClick={() => setDialogAddCategory(true)}
            filled
          />
          <ButtonBack />
        </div>
      </PageHeader>

      {/* Table with categories */}
      <Tab.Group>
        <Tab.List className={"translate-y-[1px]"}>
          {categories.getAllCategories.map((item: Category, index: number) => (
            <Tab
              key={item.id}
              className={({ selected }) =>
                classNames(
                  "text-main w-fit min-w-[100px] rounded-tl-md rounded-tr-md py-1 px-3",
                  "border-gray-200 outline-none ring-0 dark:border-none",
                  "",
                  selected
                    ? "text-primary border border-b-white bg-white font-bold dark:border-none dark:bg-slate-800"
                    : "text-disabled "
                )
              }
            >
              {item.value.toUpperCase()}
            </Tab>
          ))}
        </Tab.List>
        <div className="card shadowed overflow-x-auto !rounded-tl-none border !shadow-none dark:border-none">
          <Tab.Panels>
            {categories.getAllCategories.map(
              (category: Category, index: number) => (
                <Tab.Panel
                  key={category.id}
                  className={
                    "text-main border-primary w-full space-y-5 py-1 px-2 outline-none ring-0"
                  }
                >
                  {/* Header */}
                  <div className="flex justify-between">
                    <div>
                      <h2 className="text-dark text-md font-bold">
                        {category.comment}
                      </h2>
                      <p className="text-xs font-light text-gray-400">
                        Всего посетителей: {category.users.length} чел.
                      </p>
                    </div>
                    <div className="flex space-x-5">
                      <Button
                        label="Добавить"
                        icon={<GoPlus />}
                        className="text-xs"
                        onClick={() => navigate("/clients?add=true")}
                      />
                      <Button
                        label="Изменить"
                        icon={<MdEdit />}
                        className="text-xs"
                        onClick={() => toggleEditCategory(category)}
                      />
                    </div>
                  </div>
                  <div>
                    <table className="w-full text-xs">
                      <thead className=" text-gray-400">
                        <tr className="border-b ">
                          <td className="px-3 pb-2">№</td>
                          <td className="px-3 pb-2">Имя</td>
                          <td className="px-3 pb-2">Дата рождения</td>
                          <td className="px-3 pb-2">Роль</td>
                          <td className="px-3 pb-2">Группа</td>
                          <td className="px-3 pb-2 text-center">Баланс:</td>
                        </tr>
                      </thead>
                      <tbody className="text-dark text-sm">
                        {category.users &&
                          category.users.map((user: Client, index: number) => (
                            <tr
                              key={user.id}
                              className={classNames(
                                "cursor-pointer",
                                (index + 1) % 2 !== 0
                                  ? "bg-gray-50 dark:bg-slate-900/40"
                                  : ""
                              )}
                              onClick={() => navigate(`/clients/${user.id}`)}
                            >
                              <td className="px-2 py-2.5">{index + 1}</td>
                              <td className="px-2 py-2.5">{user.name}</td>
                              <td className="px-2 py-2.5">
                                {user.birthdate
                                  ? new Date(user.birthdate).toLocaleDateString(
                                      "ru"
                                    )
                                  : "Не указана"}
                              </td>
                              <td className="px-2 py-2.5">
                                {user.role === "client"
                                  ? "Клиент"
                                  : "Сотрудник"}
                              </td>
                              <td className="px-2 py-2.5">
                                {user.group.value}
                              </td>
                              <td
                                className={`px-2 py-2.5 text-center font-bold ${
                                  // @ts-ignore
                                  user.deposit - user.score >= 0
                                    ? "text-green"
                                    : "text-red"
                                }`}
                              >
                                {/* @ts-ignore */}
                                {user.deposit - user.score}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    {categories.getAllCategories < 1 && (
                      <div className="text-main flex h-[125px] w-full items-center justify-center">
                        Так сложились обстоятельства, что клиенты не были
                        найдены!
                      </div>
                    )}
                  </div>
                </Tab.Panel>
              )
            )}
          </Tab.Panels>
          {categories.getAllCategories.length < 1 && (
            <div className="text-main flex h-[125px] w-full items-center justify-center">
              Так сложились обстоятельства, что категории не были найдены!
            </div>
          )}
        </div>
      </Tab.Group>

      {/* Dialog (modal) addCategory + editCategory */}
      <ModalDialog
        onClose={() => closeDialog()}
        show={dialogAddCategory}
        title={
          category.id ? "Редактирование категории" : "Создание новой категории"
        }
        loading={loading}
      >
        <div className="w-full">
          <div className="mb-5 flex space-x-5">
            <Input
              icon={<IoTextOutline />}
              onChange={(e) =>
                setCategory({
                  ...category,
                  value: e.target.value,
                })
              }
              type="text"
              value={category.value}
              label="Буква"
              className="!w-24"
              placeholder="A"
              mask="aa"
            />
            <Input
              icon={<MdOutlineComment />}
              onChange={(e) =>
                setCategory({
                  ...category,
                  comment: e.target.value,
                })
              }
              type="text"
              value={category.comment}
              placeholder="Комментарий..."
              label="Комментарий по категории"
              className="w-full"
            />
          </div>
          <div className="flex justify-end">
            {category.id ? (
              <div className="flex w-full justify-between">
                <Button
                  icon={<IoTrash />}
                  label="Удалить"
                  style="red"
                  onClick={() => toggleDeleteCategory(category)}
                  loading={loading}
                ></Button>
                <Button
                  icon={<IoSaveSharp />}
                  label="Изменить"
                  onClick={() => runEditCategory()}
                  loading={loading}
                ></Button>
              </div>
            ) : (
              <Button
                icon={<IoSaveSharp />}
                label="Создать"
                onClick={() => saveCategory()}
                loading={loading}
              ></Button>
            )}
          </div>
        </div>
      </ModalDialog>

      {/* Dialog (modal) toggleDeleteCategory */}
      {/* Dialog (modal) add Category  */}
      <ModalDialog
        show={dialogDeleteCategory}
        onClose={() => setDialogDeleteCategory(false)}
        title="Подтвердите действие"
        loading={loading}
      >
        <div className="w-full">
          <p className="text-main mb-5">
            Вы действительно хотите удалить данную категорию? При удалении
            категории также <strong>уничтожаются</strong> данные{" "}
            <strong>всех клиентов</strong> этой категории, а также связанные с
            ними данные!
          </p>
          <div className="flex justify-between">
            <Button
              onClick={() => setDialogDeleteCategory(false)}
              label="Отменить"
              icon={<MdCancel />}
              loading={loading}
            />
            <Button
              onClick={() => runDeleteCategory()}
              label="Удалить"
              icon={<IoTrashBinSharp />}
              style="red"
              loading={loading}
            />
          </div>
        </div>
      </ModalDialog>
    </>
  );
};

export default UsersCategories;
