import {useNavigate} from "react-router-dom";
import Popup from "../components/UI/Popup";
import {useEffect} from "react";

export default function Expenses() {
    const navigate = useNavigate();
    useEffect(() => {
        navigate('/records')
    }, [])
    return (
        <main style={{padding: "1rem 0"}}>
            <Popup></Popup>
        </main>
    );
}
