import { Transition } from "@headlessui/react";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import SnowAnimatedBackground from "../backgrounds/SnowAnimatedBackground";
import TheHeader from "../TheHeader";
import TheSidebar from "../TheSidebar";

function Layout() {
  function triggerMenuButton() {
    console.log(window.screen.width);
    if (window.screen.width > 1535) return;
    sidebarActive ? setSidebarActive(false) : setSidebarActive(true);
  }

  const [sidebarActive, setSidebarActive] = useState<boolean>(false);
  return (
    <SnowAnimatedBackground>
      <div className="relative mx-auto flex h-screen overflow-hidden lg:max-w-screen-2xl">
        <TheHeader
          sidebarActive={sidebarActive}
          menuButtonCallback={() => triggerMenuButton()}
        />
        <TheSidebar
          active={sidebarActive}
          menuCloseButtonCallback={() => triggerMenuButton()}
        />
        <div className="mt-10 w-full overflow-y-auto p-5 lg:mt-20 lg:ml-20">
          <Transition
            show={true}
            enter="transition-opacity duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            {<Outlet />}
          </Transition>
        </div>
      </div>
    </SnowAnimatedBackground>
  );
}

export default Layout;
