export default function getCorrectWordEnding(
  gender: "male" | "female",
  count: number | string
) {
  if (gender) {
    if (count == 1) {
      return gender === "female" ? "а" : "";
    }

    if (count > 1 && count < 5) {
      return gender === "female" ? "ы" : "а";
    }

    return gender === "female" ? "" : "ов";
  }
}
