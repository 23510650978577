import React, { FC } from "react";
import {
  IoHelpCircleOutline,
  IoHelpCircleSharp,
  IoHelpSharp,
} from "react-icons/io5";

interface PopupProps {
  children?: JSX.Element;
}
const Popup: FC<PopupProps> = ({ children }) => {
  return (
    <>
      <div className="group relative">
        <IoHelpCircleOutline className="text-disabled z-10  cursor-pointer" />
        <div className="absolute top-5 z-20 hidden rounded bg-black/90 px-2 py-1 text-xs group-hover:block">
          {children}
        </div>
      </div>
    </>
  );
};

export default Popup;
