import { FC } from "react";
import Icon from "./UI/Icon";
import { IoChevronDownOutline } from "react-icons/io5";
import { Link, useLocation, useNavigate } from "react-router-dom";
import menuItems from "../data/menuItems";

interface TheSidebarMenuProps {
  menuClickCallback: () => void;
}
const TheSidebarMenu: FC<TheSidebarMenuProps> = ({ menuClickCallback }) => {
  const location = useLocation();
  let pathname = location.pathname;
  return (
    <ul onClick={() => menuClickCallback()}>
      {menuItems &&
        menuItems.map(({ id, title, path, icons, items }) => (
          <li
            key={id}
            className="relative flex items-center justify-between lg:text-sm"
          >
            {/*Active indicator*/}
            {!items && pathname === path && (
              <div className="bg-primary absolute -left-1 h-10 w-2 rounded-md"></div>
            )}

            {items ? (
              <div className="w-full cursor-pointer py-3 text-lg lg:text-sm">
                {/*Active indicator*/}
                {items.find((nestedItem) => nestedItem.path === pathname) && (
                  <div className="absolute -left-1 top-2 h-10 w-2 rounded-md bg-indigo-600"></div>
                )}
                <button
                  className={`flex items-center space-x-4 ${
                    items.find((nestedItem) => nestedItem.path === pathname)
                      ? "text-primary font-bold"
                      : null
                  }`}
                >
                  <Icon className="ml-4 mr-4 w-8 text-base transition-transform lg:group-hover:translate-x-0">
                    {items.find((nestedItem) => nestedItem.path === pathname)
                      ? icons.selected
                      : icons.default}
                  </Icon>
                  <span className="w-full transition-transform lg:translate-x-20 lg:group-hover:translate-x-0 lg:group-hover:delay-100">
                    {title}
                  </span>
                  <Icon className="absolute right-4 opacity-0 transition-opacity lg:group-hover:opacity-100 lg:group-hover:delay-200 lg:group-hover:duration-500">
                    <IoChevronDownOutline className={"text-xs"} />
                  </Icon>
                </button>
                <ul className="mt-2 flex flex-col justify-center overflow-hidden transition-transform lg:h-0 lg:translate-x-52 lg:delay-200 lg:group-hover:h-auto lg:group-hover:translate-x-0 lg:group-hover:delay-200">
                  {items.map((nestedItem) => (
                    <li
                      key={nestedItem.id}
                      className="ml-8 border-l border-gray-200 pr-2 dark:border-gray-700"
                    >
                      <Link
                        to={nestedItem.path}
                        className={`ml-2 flex cursor-pointer items-center py-3 pl-4 text-sm hover:bg-slate-50 dark:hover:rounded dark:hover:bg-slate-700 lg:py-0 lg:text-xs ${
                          nestedItem.path === pathname &&
                          "text-primary font-bold"
                        }`}
                      >
                        <Icon className="mr-4 text-xl lg:text-base">
                          {nestedItem.path === pathname
                            ? nestedItem.icons.selected
                            : nestedItem.icons.default}
                        </Icon>
                        <span className="w-full">{nestedItem.title}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <Link
                to={path}
                className={`flex w-full cursor-pointer items-center space-x-4 py-2 hover:bg-slate-50 dark:hover:bg-slate-700 ${
                  pathname === path ? "text-primary font-bold" : null
                }`}
              >
                <Icon className={`ml-3.5 mr-3.5`}>
                  {pathname === path ? icons.selected : icons.default}
                </Icon>
                <span className="text-lg transition-transform lg:translate-x-20 lg:text-sm lg:group-hover:translate-x-0 lg:group-hover:delay-100">
                  {title}
                </span>
              </Link>
            )}
          </li>
        ))}
    </ul>
  );
};

export default TheSidebarMenu;
