import { useMutation, useQuery } from "@apollo/client";
import { FC, useEffect, useMemo, useState } from "react";
import { GoPlus } from "react-icons/go";
import {
  IoColorFilterSharp,
  IoEllipsisVerticalSharp,
  IoInformationSharp,
  IoLockClosedSharp,
  IoMail,
  IoPersonSharp,
  IoSaveSharp,
  IoTrash,
  IoTrashBinSharp,
} from "react-icons/io5";
import { MdCancel, MdCategory } from "react-icons/md";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ClientsSkeleton from "../../components/skeletons/clients/clients";
import Button from "../../components/UI/Button";
import ButtonBack from "../../components/UI/ButtonBack";
import Input from "../../components/UI/Input";
import ModalDialog from "../../components/UI/ModalDialog";
import ModalMenu from "../../components/UI/ModalMenu";
import PageHeader from "../../components/UI/PageHeader";
import Select from "../../components/UI/Select";
import {
  CREATE_USER,
  DELETE_USER,
  GET_CATEGORIES,
  GET_GROUPS,
  GET_USERS,
} from "../../graphql/users";
import useDebounce from "../../hooks/debounce";
import { Client } from "../../types";
import removeAuth from "../../hooks/removeAuth";
import Cookie from "js-cookie";
import Loading from "../../components/UI/Loading";
import getPageName from "../../utils/getPageName";
import InfinityPagination from "../../components/UI/InfinityPagination";
import { TbMoodEmpty } from "react-icons/tb";

const UsersIndex: FC = () => {
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const [query, setQuery] = useState("");
  const debouncedClient = useDebounce(query);

  useEffect(() => {
    setClientsList([]);
    setClientsPage(0);
  }, [debouncedClient]);

  // Infinity pagination
  const [countClient, setCountClient] = useState<number>(50);
  const [clientsList, setClientsList] = useState<any>([]);
  const [clientsPage, setClientsPage] = useState<number>(0);

  function fetchClients() {
    if (!clientsDataLoading && clientsList.length !== 0) {
      if (clientsPage * countClient > clientsList.length) return;
      setClientsPage((prev) => prev + 1);
    }
  }

  const {
    data: clients,
    loading: clientsDataLoading,
    error,
    refetch,
  } = useQuery(GET_USERS, {
    variables: {
      searchUsers: {
        take: countClient,
        skip: clientsPage,
        name: debouncedClient,
      },
    },
  });

  useEffect(() => {
    document.title = getPageName("Все посетители");
  }, []);

  useEffect(() => {
    if (!clientsDataLoading && clients.getUsers.length > 0) {
      setClientsList((prev: any) => [...prev, ...clients.getUsers]);
    }
  }, [clients]);

  const { data: categories, loading: categoriesLoading } =
    useQuery(GET_CATEGORIES);
  const { data: groups, loading: groupsLoading } = useQuery(GET_GROUPS);

  const [saveUser] = useMutation(CREATE_USER);
  const [deleteUser] = useMutation(DELETE_USER);

  const [client] = useState({
    id: null,
    name: "",
  });
  const [dialogAddClient, setDialogAddClient] = useState(false);
  const [dialogDeleteUser, setDialogDeleteUser] = useState(false);
  const initialUser = {
    id: Math.random().toString(16).slice(2),
    name: "",
    category: {
      value: "z",
    },
    group: {
      id: "",
      value: "Без абонемента",
    },

    role: {
      value: "Клиент",
    },
    email: "",
    password: "",
  };
  const [newClients, setNewClients] = useState([initialUser]);

  function toggleAddClients() {
    setDialogAddClient(true);
  }

  function pushClient() {
    setNewClients([
      ...newClients,
      {
        ...initialUser,
        id: Math.random().toString(16).slice(2),
      },
    ]);
  }

  function removeNewClient(c: any) {
    const clients = newClients.filter((item) => item !== c);
    setNewClients(clients);
  }

  async function createClient() {
    setLoading(true);
    for await (const client of newClients) {
      try {
        await saveUser({
          variables: {
            createUser: {
              name: client.name,
              role: client.role.value === "Клиент" ? "client" : "employee",
              category: client.category.value,
              email: client.email,
              password: client.password,
              groupId: client.group.id,
            },
          },
        });

        setClientsPage(0);
        toast.success(
          `${client.role.value} ${client.name} был успешно добавлен!`
        );
      } catch (e) {
        console.log("Ошибка при добавлении клиента >>>", e);
        toast.error(
          `Ошибка при добавлении ${client.name}! Попробуйте снова или обратитесь к администратору.`
        );
      } finally {
        await refetch();
        closeDialog();
      }
    }

    setLoading(false);
  }

  function closeDialog() {
    setDialogAddClient(false);
    setDialogDeleteUser(false);
    setNewClients([initialUser]);
  }

  async function toggleDetailsUser(id: string) {
    navigate(`/clients/${id}`);
  }

  async function toggleDeleteUser(id: string) {
    localStorage.setItem("deleteUser", id);
    setDialogDeleteUser(true);
  }

  async function runDeleteUser() {
    setLoading(true);
    try {
      await deleteUser({
        variables: { id: localStorage.getItem("deleteUser") },
      });

      toast.success(`Посетитель был успешно удален из системы!`);

      await refetch();
    } catch (e) {
      console.log("Ошибка при удалении >>>", e);
      toast.error(
        "При удалении посетителя произошла ошибка! Попробуйте снова или обратитесь к администратору."
      );
    } finally {
      closeDialog();
      setLoading(false);
      setClientsList((prev: any) => [
        ...prev.filter((client: Client) => {
          client.id !== localStorage.getItem("deleteUser");
        }),
      ]);
      localStorage.removeItem("deleteUser");
    }
  }

  // if (clientsDataLoading || categoriesLoading || groupsLoading)
  //   return <ClientsSkeleton />;
  if (error) {
    removeAuth();
  }

  return (
    <InfinityPagination callback={() => fetchClients()}>
      <>
        <PageHeader title="Клиенты" description="Список всех клиентов">
          <div className="flex w-full flex-col gap-3 lg:flex-row lg:justify-end">
            <Input
              placeholder="Поиск по клиентам..."
              icon={<IoPersonSharp />}
              onChange={(e) => setQuery(e.target.value)}
              type="text"
              color="white"
              value={query}
            />
            <Button
              label="Добавить"
              icon={<GoPlus />}
              filled
              onClick={() => toggleAddClients()}
            />
            <ButtonBack />
          </div>
        </PageHeader>

        <div className="card overflow-x-auto">
          <table className="w-full">
            <thead className=" text-gray-400">
              <tr className="border-b dark:border-none">
                <td className="px-3 pb-2">№</td>
                <td className="px-3 pb-2">Имя</td>
                <td className="px-3 pb-2">Дата рождения</td>
                <td className="px-3 pb-2 text-center">Категория</td>
                <td className="px-3 pb-2">Роль</td>
                <td className="px-3 pb-2">Группа</td>
                <td className="px-3 pb-2 text-center">Баланс:</td>
                <td className="px-3 pb-2 text-center">Действия</td>
              </tr>
            </thead>
            <tbody className="text-dark text-sm">
              {clientsList.length === 0 ? (
                <tr>
                  <td colSpan={8} className="text-disabled py-5">
                    {clientsDataLoading ? (
                      <div className="flex items-center justify-center">
                        <Loading />
                      </div>
                    ) : (
                      <div className="flex items-center justify-center gap-5">
                        <TbMoodEmpty className="text-2xl" /> Так сложились
                        обстоятельства, что клиенты пока отсутствуют!
                      </div>
                    )}
                  </td>
                </tr>
              ) : (
                clientsList &&
                clientsList.map((item: Client, index: number) => (
                  <tr
                    key={item.id}
                    className={
                      (index + 1) % 2 !== 0
                        ? "bg-gray-50 dark:bg-slate-900/40"
                        : ""
                    }
                  >
                    <td className="px-2 py-2.5">{index + 1}</td>
                    <td className="px-2 py-2.5">
                      <NavLink to={`/clients/${item.id}`}>{item.name}</NavLink>
                    </td>
                    <td className="px-2 py-2.5">
                      {item.birthdate
                        ? new Date(item.birthdate).toLocaleDateString("ru")
                        : "Не указана"}
                    </td>
                    <td className="px-2 py-2.5 text-center font-bold">
                      {item.category.value.toUpperCase()}
                    </td>
                    <td className="px-2 py-2.5">
                      {item.role === "client" ? "Клиент" : "Сотрудник"}
                    </td>
                    <td className="px-2 py-2.5">{item.group.value}</td>
                    <td
                      className={`px-2 py-2.5 text-center font-bold ${
                        // @ts-ignore
                        item.deposit - item.score >= 0
                          ? "text-green"
                          : "text-red"
                      }`}
                    >
                      {/* @ts-ignore */}
                      {item.deposit - item.score}
                    </td>
                    <td className="flex justify-center px-2 py-2.5">
                      <ModalMenu
                        items={[
                          {
                            title: "Подробнее",
                            icon: <IoInformationSharp className="!text-sm" />,
                            callback: () => toggleDetailsUser(item.id),
                          },
                          {
                            title: "Удалить",
                            icon: <IoTrashBinSharp className={"!text-sm"} />,
                            callback: () => toggleDeleteUser(item.id),
                          },
                        ]}
                        children={<IoEllipsisVerticalSharp />}
                        position="right-8 -top-7"
                      />
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>

          {/* Dialog (modal) addCategory + editCategory */}
          <ModalDialog
            onClose={() => closeDialog()}
            show={dialogAddClient}
            title={
              client.id
                ? "Редактирование категории"
                : "Добавление новых клиентов"
            }
            loading={loading}
          >
            <div className="text-main w-full">
              {newClients.map((client, index) => (
                <div
                  className="mb-5 flex w-full flex-col gap-5 lg:flex-row lg:items-center"
                  key={client.id}
                >
                  <Input
                    icon={<IoPersonSharp />}
                    onChange={(e) => {
                      client.name = e.target.value;
                      setNewClients([...newClients]);
                    }}
                    type="text"
                    value={client.name}
                    placeholder="Иван Иванов Иванович"
                    label="ФИО клиента"
                  />
                  {!categoriesLoading && (
                    <Select
                      items={categories.getAllCategories}
                      icon={<MdCategory />}
                      label="Категория клиента"
                      value={client.category}
                      onChange={(v: any) => {
                        client.category = v;
                        setNewClients([...newClients]);
                      }}
                    />
                  )}
                  <Select
                    items={[{ value: "Клиент" }, { value: "Сотрудник" }]}
                    icon={<MdCategory />}
                    label="Роль клиента"
                    className="w-40"
                    value={client.role}
                    onChange={(v: any) => {
                      client.role = v;
                      setNewClients([...newClients]);
                    }}
                  />
                  {!groupsLoading && (
                    <Select
                      items={groups.getAllGroups}
                      icon={<IoColorFilterSharp />}
                      className={"w-auto"}
                      label="Группа"
                      value={client.group}
                      onChange={(v: any) => {
                        client.group = v;
                        setNewClients([...newClients]);
                      }}
                    />
                  )}

                  {Cookie.get("eitrynne-client-app_username") ===
                    "Daniil Dmitriev" && (
                    <>
                      <Input
                        icon={<IoMail />}
                        onChange={(e) => {
                          client.email = e.target.value;
                          setNewClients([...newClients]);
                        }}
                        type="email"
                        value={client.email}
                        placeholder="admin@admin.ru"
                        label="Почта"
                      />
                      <Input
                        icon={<IoLockClosedSharp />}
                        onChange={(e) => {
                          client.password = e.target.value;
                          setNewClients([...newClients]);
                        }}
                        type="password"
                        value={client.password}
                        placeholder="********"
                        label="Пароль"
                      />
                    </>
                  )}
                  <Button
                    icon={<IoTrash />}
                    className={`mt-5 ${index === 0 && "opacity-0"}`}
                    onClick={() => removeNewClient(client)}
                    loading={loading}
                    disabled={index === 0}
                  />
                </div>
              ))}
              <div className="flex justify-between">
                <Button
                  icon={<GoPlus />}
                  onClick={() => pushClient()}
                  loading={loading}
                />
                {client.id ? (
                  <Button
                    icon={<IoSaveSharp />}
                    label="Изменить"
                    loading={loading}
                  ></Button>
                ) : (
                  <Button
                    icon={<IoSaveSharp />}
                    label="Сохранить"
                    onClick={() => createClient()}
                    loading={loading}
                  ></Button>
                )}
              </div>
            </div>
          </ModalDialog>

          {/* Dialog (modal) toggleDeleteUser */}
          <ModalDialog
            show={dialogDeleteUser}
            onClose={() => setDialogDeleteUser(false)}
            title="Подтвердите действие"
            loading={loading}
          >
            <div className="w-full">
              <p className="text-main mb-5">
                Вы действительно хотите удалить данного посетителя? <br />
                При удалении также <strong>уничтожатся все записи</strong>{" "}
                посетителя!{" "}
              </p>
              <div className="flex justify-between">
                <Button
                  onClick={() => setDialogDeleteUser(false)}
                  label="Отменить"
                  icon={<MdCancel />}
                  loading={loading}
                />
                <Button
                  onClick={() => runDeleteUser()}
                  label="Удалить"
                  icon={<IoTrashBinSharp />}
                  style="red"
                  loading={loading}
                />
              </div>
            </div>
          </ModalDialog>
        </div>
      </>
    </InfinityPagination>
  );
};

export default UsersIndex;
