import React, { FC } from "react";
import Loading from "./Loading";
interface IconProps {
  children: JSX.Element;
  loading?: boolean;
  onClick?: React.MouseEventHandler<HTMLSpanElement>;
  className?: string;
}
const Icon: FC<IconProps> = ({
  children,
  loading = false,
  onClick,
  className,
}) => {
  return (
    <span
      onClick={onClick}
      className={`text-xl flex items-center justify-center p-2 cursor-pointer ${className}`}
    >
      {loading ? <Loading /> : children}
    </span>
  );
};

export default Icon;
