import { Transition, Menu } from "@headlessui/react";
import React, { FC } from "react";
import {
  IoEllipsisVerticalSharp,
  IoPencilSharp,
  IoTrashBinSharp,
} from "react-icons/io5";
import Icon from "./Icon";

interface MenuProps {
  icon?: JSX.Element;
  title?: string;
  callback: () => Promise<void>;
}

interface ModalMenuProps {
  items: MenuProps[];
  children: JSX.Element;
  position: string;
}
const ModalMenu: FC<ModalMenuProps> = ({ items, children, position }) => {
  return (
    <Menu as="div" className="relative">
      <Menu.Button className="inline-flex w-full justify-center rounded-md text-sm font-medium hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
        {children}
      </Menu.Button>
      <Transition
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          as="div"
          className={`absolute ${position} shadowed divide-y divide-gray-100 rounded-md bg-white ring-1 ring-black ring-opacity-5 focus:outline-none dark:divide-gray-700 dark:bg-slate-800`}
        >
          {items.map((item, index) => (
            <Menu.Item key={index}>
              {({ active }) => (
                <button
                  className={`${
                    active ? "bg-slate-50 dark:bg-slate-700" : "text-main"
                  } group flex w-full items-center gap-3 py-1 px-2 text-sm `}
                  onClick={item.callback}
                >
                  <Icon className="text-primary">
                    {item.icon ? item.icon : <></>}
                  </Icon>
                  {item.title}
                </button>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default ModalMenu;
