import { FC, useEffect, useState } from "react";
import {
  IoAlarmSharp,
  IoCalendarSharp,
  IoSaveSharp,
  IoTrash,
} from "react-icons/io5";
import Button from "../../components/UI/Button";
import ButtonBack from "../../components/UI/ButtonBack";
import PageHeader from "../../components/UI/PageHeader";
import UserSearch from "../../components/UserSearch";
import UserSearchMultiple from "../../components/UserSearchMultiple";
import Input from "../../components/UI/Input";
import Select from "../../components/UI/Select";
import { GoPlus } from "react-icons/go";
import useValidDate from "../../hooks/validDate";
import { CHANGE_RECORD_STATUS, CREATE_RECORD } from "../../graphql/records";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { CREATE_PAYMENT } from "../../graphql/users";
import { MdMoney } from "react-icons/md";
import getPageName from "../../utils/getPageName";

interface Record {
  id: string;
  clients: any[];
  employee: any;
  date: string;
  duration: any;
  payed: boolean;
  paymentType: any;
}

const RecordsAdd: FC = () => {
  const [loading, setLoading] = useState(false);

  const initialRecord: Record = {
    id: Math.random().toString(16).slice(2),
    clients: [],
    employee: {},
    date: new Date().toLocaleDateString("ru"),
    duration: {
      value: "45",
    },
    payed: true,
    paymentType: {
      value: "QR",
    },
  };
  const [newRecords, setNewRecords] = useState([{ ...initialRecord }]);
  const [createRecord] = useMutation(CREATE_RECORD);
  const [changeRecordStatus] = useMutation(CHANGE_RECORD_STATUS);

  const [createPayment] = useMutation(CREATE_PAYMENT);

  useEffect(() => {
    document.title = getPageName("Добавить записи");
  }, []);
  async function pushRecord() {
    setNewRecords([
      ...newRecords,
      {
        ...initialRecord,
        id: Math.random().toString(16).slice(2),
        date: newRecords[newRecords.length - 1].date,
        employee: newRecords[newRecords.length - 1].employee,
        payed: newRecords[newRecords.length - 1].payed,
        paymentType: newRecords[newRecords.length - 1].paymentType,
      },
    ]);
  }

  function removeFromNewRecords(r: any) {
    if (newRecords.length === 1) {
      return setNewRecords([initialRecord]);
    }
    const recordsFiltered = newRecords.filter((item) => item !== r);
    setNewRecords(recordsFiltered);
  }
  async function saveRecords() {
    setLoading(true);
    let error = false;
    // * Adding an Array of Records Line by Line
    for await (const record of newRecords) {
      try {
        // console.log({ ...record, date: useValidDate(record.date) });
        // @ts-ignore
        const employee_id = record.employee.id;

        let clients_id = "";
        for await (const client of record.clients) {
          // @ts-ignore
          clients_id += client.id + "/";
        }
        const { data } = await createRecord({
          variables: {
            createRecord: {
              date: useValidDate(record.date),
              employee_id,
              clients_id: clients_id.slice(0, -1),
              duration: parseInt(record.duration.value),
            },
          },
        });

        if (record.payed) {
          const paymentType =
            record.paymentType.value === "QR"
              ? "qr"
              : record.paymentType.value === "Наличными"
              ? "cash"
              : "transfer";

          for await (const client of record.clients) {
            await changeRecordStatus({
              variables: {
                recordStatus: {
                  id: data.createRecord.id,
                  status: true,
                  payment_date: useValidDate(record.date),
                  payment_type: paymentType,
                  payment_comment: `Платеж создан автоматически (Отметка при добавлении записи) ${record.paymentType.value}`,
                  userId: client.id,
                },
              },
            });
          }
          // for await (const price of data.createRecord.prices) {
          //   await createPayment({
          //     variables: {
          //       createPayment: {
          //         value: price.value,
          //         comment: `Платеж создан автоматически (Отметка при добавлении записи) ${record.paymentType.value}`,
          //         date: new Date(),
          //         type: paymentType,
          //         userId: price.user.id,
          //       },
          //     },
          //   });
          // }
        }
      } catch (e) {
        error = true;
        console.log("An error occurred while adding an entry >>> ", e);
      }
    }
    if (error) {
      setLoading(false);
      return toast.error(
        "При добавлении записи произошла ошибка! Попробуйте снова или обратитесь к администратору"
      );
    }

    toast.success("Записи были успешно добавлены!");
    setLoading(false);
    return setNewRecords([initialRecord]);
  }

  return (
    <>
      <PageHeader title={"Записи"} description={"Добавление новых записей"}>
        <div className="flex space-x-5">
          <Button
            label="Сохранить"
            filled
            icon={<IoSaveSharp />}
            disabled={
              newRecords[0].date.includes("_") || newRecords[0].date === ""
            }
            loading={loading}
            onClick={() => saveRecords()}
          />
          <ButtonBack />
        </div>
      </PageHeader>

      <div className="card">
        {newRecords.map((record, index) => (
          <div className={`mb-5 flex space-x-5 `} key={record.id}>
            <UserSearch
              label="Сотрудник:"
              role="employee"
              value={record.employee}
              onChange={(v) => {
                record.employee = v;
                setNewRecords([...newRecords]);
              }}
            />
            <UserSearchMultiple
              label="Клиенты:"
              value={record.clients}
              onChange={(v: any) => {
                record.clients = v;
                setNewRecords([...newRecords]);
              }}
              onBackspace={() => {
                record.clients.pop();
                setNewRecords([...newRecords]);
              }}
              deleteItem={(index) => {
                record.clients.splice(index, 1);
                setNewRecords([...newRecords]);
              }}
              onEnter={(user) => {
                record.clients = [...record.clients, user];
                setNewRecords([...newRecords]);
              }}
            />
            <Input
              type="text"
              icon={<IoCalendarSharp />}
              value={record.date}
              onChange={(e) => {
                record.date = e.target.value;
                setNewRecords([...newRecords]);
              }}
              mask={`99.99.${new Date().getFullYear()}`}
              maskPlaceholder="_"
              placeholder={`01.01.${new Date().getFullYear()}`}
              label="Дата записи"
              className="w-80"
            />
            <Select
              items={[
                { value: "30" },
                { value: "45" },
                { value: "60" },
                { value: "90" },
              ]}
              onChange={(v) => {
                record.duration = v;
                setNewRecords([...newRecords]);
              }}
              value={record.duration}
              icon={<IoAlarmSharp />}
              label="Длительность"
            />
            <div className="text-main flex flex-col flex-nowrap text-center text-sm">
              <span>Оплачено:</span>
              <div className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  className={`${
                    record.payed ? "opacity-100" : "opacity-50"
                  } accent-primary cursor-pointer`}
                  checked={record.payed}
                  onChange={() => {
                    record.payed = !record.payed;
                    setNewRecords([...newRecords]);
                  }}
                />

                <Select
                  items={[
                    { value: "QR" },
                    { value: "Наличными" },
                    { value: "Перевод" },
                  ]}
                  onChange={(v) => {
                    record.paymentType = v;
                    setNewRecords([...newRecords]);
                  }}
                  value={record.paymentType}
                  icon={<MdMoney />}
                  className="w-40 text-left"
                />
              </div>
            </div>
            <Button
              icon={<IoTrash />}
              onClick={() => removeFromNewRecords(record)}
              className={"mt-5 px-0 text-xl"}
            />
          </div>
        ))}
      </div>
      <div className="flex items-center">
        <Button icon={<GoPlus />} onClick={() => pushRecord()} />
        {/* <Button icon={<IoSaveSharp />} label="Сохранить" filled /> */}
      </div>
    </>
  );
};

export default RecordsAdd;
