export default function usePriceStatus(prices: any) {
  const pricesLength = prices.length;

  const paid: any[] = [];
  const unpaid: any[] = [];
  for (const price of prices) {
    if (price.status === true) {
      paid.push(price);
    } else {
      unpaid.push(price);
    }
  }
  if (unpaid.length === 0) return 2;
  if (unpaid.length !== 0 && paid.length > 0) return 1;

  return 0;
}
