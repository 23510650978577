import { FormEvent, useEffect, useState } from "react";
import { axiosInstance } from "../config/axiosConfig";
import {
  IoKeyOutline,
  IoLogInSharp,
  IoMailOutline,
  IoMoon,
  IoSunnySharp,
} from "react-icons/io5";
import Input from "../components/UI/Input";
import Button from "../components/UI/Button";
import Cookie from "js-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import { Theme } from "react-toastify";
import AuthAnimatedBackground from "../components/AuthAnimatedBackground";

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [currentTheme, setCurrentTheme] = useState<Theme>("light");

  useEffect(() => {
    if (
      location.pathname === "/login" &&
      Cookie.get("eitrynne-client-app_username")
    )
      navigate("/");
    if (localStorage.getItem("theme") === "dark") {
      document.documentElement.classList.add("dark");
      setCurrentTheme("dark");
    }
  }, []);

  function changeTheme() {
    if (document.documentElement.classList.contains("dark")) {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("theme", "light");
      setCurrentTheme("light");

      return;
    }

    document.documentElement.classList.add("dark");

    localStorage.setItem("theme", "dark");
    setCurrentTheme("dark");
  }

  async function login(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setLoading(true);
    await axiosInstance
      .post("/auth/login", {
        ...credentials,
      })
      .then((res) => {
        Cookie.set("eitrynne-client-app_username", res.data.name, {
          expires: 1,
        });
        Cookie.set("eitrynne-client-app_role", res.data.role, { expires: 1 });
        Cookie.set("eitrynne-client-app_id", res.data.id, { expires: 1 });

        console.log(res);
        navigate("/");
      })
      .catch((e) => {
        console.log(e);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <AuthAnimatedBackground>
      <div className="flex max-w-screen-2xl bg-white dark:bg-slate-900">
        <div className="relative z-40 flex h-screen w-full flex-col justify-center p-5 lg:w-1/2 lg:p-8 xl:p-20">
          <div className="fixed top-10 ">
            {currentTheme === "dark" ? (
              <Button
                icon={<IoSunnySharp />}
                className="text-xs"
                loading={loading}
                onClick={changeTheme}
                label="Светлая тема"
              />
            ) : (
              <Button
                icon={<IoMoon />}
                className="text-xs"
                loading={loading}
                onClick={changeTheme}
                label="Темная тема"
              />
            )}
          </div>
          <div className="mb-5 xl:mb-8">
            <h1 className="text-primary text-3xl font-bold lg:mb-2 lg:text-4xl">
              С возвращением!
            </h1>
            {/* <h2 className="text-md">Рады снова тебя видеть!</h2> */}
            <p className="text-main text-xs">
              Чтобы начать работу в приложении, пожалуйста пройдите процедуру
              авторизации.
            </p>
          </div>
          <form
            onSubmit={(e) => login(e)}
            className="text-main flex w-full flex-col gap-4 text-sm"
          >
            <input
              type="hidden"
              name="csrfToken"
              defaultValue={Math.random().toString(16).slice(2)}
            />
            <div className="flex flex-col items-start justify-start gap-3 lg:flex-row lg:items-end">
              <Input
                placeholder="Введите email адрес"
                label="E-mail"
                type="email"
                value={credentials.email}
                icon={<IoMailOutline />}
                onChange={(e) =>
                  setCredentials({ ...credentials, email: e.target.value })
                }
                bordered
                className="w-full"
              />
              <Input
                label="Пароль"
                placeholder="Введите пароль"
                type="password"
                value={credentials.password}
                icon={<IoKeyOutline />}
                onChange={(e) =>
                  setCredentials({ ...credentials, password: e.target.value })
                }
                bordered
                className="w-full"
              />
              <Button
                label="Войти"
                type="submit"
                loading={loading}
                icon={<IoLogInSharp />}
                filled
                className="mt-5 lg:mt-3"
              />
            </div>
            {error && (
              <p className="text-red pt-3 text-left text-xs">
                Ошибка авторизации. Пожалуйста, проверьте правильность введенных
                данных!
              </p>
            )}
          </form>
        </div>
      </div>
    </AuthAnimatedBackground>
  );
  {
    /* <form
          onSubmit={(e) => login(e)}
          className="text-main bg-red absolute flex w-80 flex-col items-center justify-center gap-4 text-sm"
        >
          <input
            type="hidden"
            name="csrfToken"
            defaultValue={Math.random().toString(16).slice(2)}
          />
          <Input
            label="Email"
            placeholder="Введите email адрес"
            type="email"
            value={credentials.email}
            icon={<IoMailOutline />}
            onChange={(e) =>
              setCredentials({ ...credentials, email: e.target.value })
            }
            bordered
            color="white"
          />
          <Input
            label="Пароль"
            placeholder="Введите пароль"
            type="password"
            value={credentials.password}
            icon={<IoKeyOutline />}
            onChange={(e) =>
              setCredentials({ ...credentials, password: e.target.value })
            }
            bordered
            color="white"
          />
          <Button
            label="Войти"
            type="submit"
            loading={loading}
            icon={<IoLogInSharp />}
          />

          {/* <input
            type="email"
            value={credentials.email}
            onChange={(e) =>
              setCredentials({ ...credentials, email: e.target.value })
            }
            placeholder="email"
            className="border !bg-black text-center text-white outline-none placeholder:text-gray-700"
          />
          <input
            type="password"
            value={credentials.password}
            onChange={(e) =>
              setCredentials({ ...credentials, password: e.target.value })
            }
            placeholder="password"
            className="border bg-black text-center text-white outline-none placeholder:text-gray-700"
          />
          <button type="submit" className="text-white">
            Войти
          </button> 
          {error && (
            <p className="text-red pt-3 text-center text-xs">
              Ошибка авторизации. Пожалуйста, проверьте правильность введенных
              данных!
            </p>
          )}
        </form> */
  }
};

export default Login;
