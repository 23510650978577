import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { IoImageSharp, IoSaveSharp } from "react-icons/io5";
import { MdCancel } from "react-icons/md";
import Button from "../Button";
import Icon from "../Icon";
import ModalDialog from "../ModalDialog";

interface IDragNDropInput {
  show: boolean;
  onClose: () => void;
  title: string;
  loading: boolean;
  handleChange: (file: Blob) => void;
  handleSave: () => void;
  filePreview: string | null;
}

const DragNDropInput: FC<IDragNDropInput> = ({
  show,
  onClose,
  title,
  loading,
  handleChange,
  handleSave,
  filePreview,
}) => {
  const fileTypes = ["JPG", "PNG", "GIF"];

  return (
    <ModalDialog
      show={show}
      onClose={onClose}
      title={title}
      loading={loading}
      description="Добавление аватара пользователя, который будет отображаться в системе"
    >
      <div className="space-y-6">
        <div>
          <FileUploader
            handleChange={handleChange}
            name="file"
            label="Выберите или просто бросьте файл в эту зону"
            className={"border-gray-400"}
            fileTypes={fileTypes}
          >
            <div className="cursor-pointer rounded border-2 border-dashed p-4 text-center text-xs ">
              <Icon>
                <IoImageSharp />
              </Icon>{" "}
              Выберите файл или просто перетащите его сюда!
            </div>
          </FileUploader>
        </div>
        {filePreview && (
          <>
            <div className="flex items-end justify-start gap-3">
              {filePreview && (
                <img src={filePreview} className="h-48 w-48 rounded" />
              )}
              {filePreview && (
                <img src={filePreview} className="h-28 w-28 rounded" />
              )}
              {filePreview && (
                <img src={filePreview} className="h-10 w-10 rounded-full" />
              )}
            </div>
            <div className="flex items-center justify-between">
              <Button label="Отменить" icon={<MdCancel />} style="white" />
              <Button
                label="Сохранить"
                filled={true}
                icon={<IoSaveSharp />}
                onClick={handleSave}
              />
            </div>
          </>
        )}
      </div>
    </ModalDialog>
  );
};

export default DragNDropInput;
