import { FC } from "react";

const Divider: FC = () => {
  return (
    <div className="relative my-3 flex w-full items-center justify-center">
      <span className="hidden opacity-100 transition-opacity lg:block lg:group-hover:opacity-0">
        ...
      </span>
      <hr className="absolute w-full transition-opacity dark:border-gray-700 lg:opacity-0 lg:group-hover:opacity-100" />
    </div>
  );
};

export default Divider;
