import { gql } from "@apollo/client";

export const GET_NOTIFICATIONS = gql`
  query ($userId: String!) {
    getNotifications(userId: $userId) {
      id
      header
      body
      readed
      created_at
    }
  }
`;

export const UPDATE_NOTIFICATION = gql`
  mutation updateNotification($updateNotification: UpdateNotificationInput!) {
    updateNotification(updateNotification: $updateNotification) {
      id
      readed
    }
  }
`;

export const DELETE_NOTIFICATION = gql`
  mutation deleteNotification($id: String!) {
    deleteNotification(id: $id) {
      id
    }
  }
`;
