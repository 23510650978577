import { RefetchQueriesFunction, useMutation } from "@apollo/client";
import React, { FC, useState } from "react";
import { GoPlus } from "react-icons/go";
import {
  IoCalendarSharp,
  IoAlarmSharp,
  IoTrash,
  IoSaveSharp,
} from "react-icons/io5";
import { MdMoney } from "react-icons/md";
import { toast } from "react-toastify";
import { CREATE_RECORD, CHANGE_RECORD_STATUS } from "../../../graphql/records";
import { CREATE_PAYMENT } from "../../../graphql/users";
import useValidDate from "../../../hooks/validDate";
import UserSearch from "../../UserSearch";
import UserSearchMultiple from "../../UserSearchMultiple";
import Button from "../Button";
import Input from "../Input";
import ModalDialog from "../ModalDialog";
import Select from "../Select";

// ? TODO: need to implement add records by userId!
// TODO: need to implement add monthly subscribtion to users

interface Record {
  id: string;
  clients: any[];
  employee: any;
  date: string;
  duration: any;
  payed: boolean;
  paymentType: any;
}

interface ModalAddRecordsProps {
  onClose: () => void;
  refetch: () => void;
  show: boolean;
}

const ModalAddRecords: FC<ModalAddRecordsProps> = ({
  show,
  onClose,
  refetch,
}) => {
  const [showModalAddRecords, setShowModalAddRecords] = useState(true);
  const [loading, setLoading] = useState(false);
  const [createRecord] = useMutation(CREATE_RECORD);
  const [changeRecordStatus] = useMutation(CHANGE_RECORD_STATUS);
  const [createPayment] = useMutation(CREATE_PAYMENT);

  const initialRecord: Record = {
    id: Math.random().toString(16).slice(2),
    clients: [],
    employee: {},
    date: new Date().toLocaleDateString("ru"),
    duration: {
      value: "45",
    },
    payed: false,
    paymentType: {
      value: "QR",
    },
  };

  const [newRecords, setNewRecords] = useState([{ ...initialRecord }]);

  async function pushRecord() {
    setNewRecords([
      ...newRecords,
      {
        ...initialRecord,
        id: Math.random().toString(16).slice(2),
        date: newRecords[newRecords.length - 1].date,
        employee: newRecords[newRecords.length - 1].employee,
        payed: newRecords[newRecords.length - 1].payed,
        paymentType: newRecords[newRecords.length - 1].paymentType,
      },
    ]);
  }

  function removeFromNewRecords(r: any) {
    if (newRecords.length === 1) {
      return setNewRecords([initialRecord]);
    }
    const recordsFiltered = newRecords.filter((item) => item !== r);
    setNewRecords(recordsFiltered);
  }

  async function saveRecords() {
    setLoading(true);
    let error = false;
    // * Adding an Array of Records Line by Line
    for await (const record of newRecords) {
      try {
        // @ts-ignore
        const employee_id = record.employee.id;

        let clients_id = "";
        for await (const client of record.clients) {
          // @ts-ignore
          clients_id += client.id + "/";
        }

        const { data } = await createRecord({
          variables: {
            createRecord: {
              date: useValidDate(record.date),
              employee_id,
              clients_id: clients_id.slice(0, -1),
              duration: parseInt(record.duration.value),
            },
          },
        });

        if (record.payed) {
          const paymentType =
            record.paymentType.value === "QR"
              ? "qr"
              : record.paymentType.value === "Наличными"
              ? "cash"
              : "transfer";

          for await (const client of record.clients) {
            await changeRecordStatus({
              variables: {
                recordStatus: {
                  id: data.createRecord.id,
                  status: true,
                  payment_date: useValidDate(record.date),
                  payment_type: paymentType,
                  payment_comment: `Платеж создан автоматически (Отметка при добавлении записи) ${record.paymentType.value}`,
                  userId: client.id,
                },
              },
            });
          }
          // for await (const price of data.createRecord.prices) {
          //   await createPayment({
          //     variables: {
          //       createPayment: {
          //         value: price.value,
          //         comment: `Платеж создан автоматически (Отметка при добавлении записи) ${record.paymentType.value}`,
          //         date: new Date(),
          //         type: paymentType,
          //         userId: price.user.id,
          //       },
          //     },
          //   });
          // }
        }
      } catch (e) {
        error = true;
        console.log("An error occurred while adding an entry >>> ", e);
      }
    }
    if (error) {
      setLoading(false);
      return toast.error(
        "При добавлении записи произошла ошибка! Попробуйте снова или обратитесь к администратору"
      );
    }

    toast.success("Записи были успешно добавлены!");
    setLoading(false);
    onClose();
    refetch();
    return setNewRecords([initialRecord]);
  }

  return (
    <ModalDialog
      loading={loading}
      onClose={onClose}
      title="Добавить новые записи"
      show={show}
    >
      <>
      <div className="flex justify-between border-1 border-b pb-6">
      <Button icon={<GoPlus />} onClick={() => pushRecord()} label="Добавить"/>
      <Button
            label="Сохранить"
            filled
            icon={<IoSaveSharp />}
            disabled={
              newRecords[0].date.includes("_") || newRecords[0].date === ""
            }
            loading={loading}
            onClick={() => saveRecords()}
          />
      </div>
      {/*
        PAY ATTENTION THAT THE REVERSE DIRECTION IS USED FOR FLEX PROPERTIES
        FIXES A BUG WITH Z-INDEX OF OFFERED OPTIONS
       */}
      <ul className="flex flex-col-reverse">
        <li className="mt-6 flex items-center justify-between">
          {/* <Button icon={<GoPlus />} onClick={() => pushRecord()} />
          <Button
            label="Сохранить"
            filled
            icon={<IoSaveSharp />}
            disabled={
              newRecords[0].date.includes("_") || newRecords[0].date === ""
            }
            loading={loading}
            onClick={() => saveRecords()}
          /> */}
          {/* <Button icon={<IoSaveSharp />} label="Сохранить" filled /> */}
        </li>

        {/*
         PAY ATTENTION THAT THE REVERSE DIRECTION IS USED FOR FLEX PROPERTIES
         FIXES A BUG WITH Z-INDEX OF OFFERED OPTIONS
       */}
        {newRecords.map((record, index) => (
          <li
            className={`${"justify-around border-b py-5 dark:border-gray-700"} flex flex-col-reverse gap-3 lg:flex-row-reverse lg:items-start lg:space-x-5`}
            key={record.id}
          >
            <Button
              icon={<IoTrash />}
              onClick={() => removeFromNewRecords(record)}
              className={"mt-5 self-end px-0 text-xl lg:self-auto"}
            />

            <div className="text-main flex flex-col flex-nowrap text-start text-sm">
              <span>Оплачено:</span>
              <div className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  className={`${
                    record.payed ? "opacity-100" : "opacity-50"
                  } accent-primary cursor-pointer`}
                  checked={record.payed}
                  onChange={() => {
                    record.payed = !record.payed;
                    setNewRecords([...newRecords]);
                  }}
                />

                <Select
                  items={[
                    { value: "QR" },
                    { value: "Наличными" },
                    { value: "Перевод" },
                  ]}
                  onChange={(v) => {
                    record.paymentType = v;
                    setNewRecords([...newRecords]);
                  }}
                  value={record.paymentType}
                  icon={<MdMoney />}
                  className="w-full text-left lg:w-40"
                />
              </div>
            </div>
            <Input
              type="text"
              icon={<IoCalendarSharp />}
              value={record.date}
              onChange={(e) => {
                record.date = e.target.value;
                setNewRecords([...newRecords]);
              }}
              mask={`99.99.${new Date().getFullYear().toString().slice(2)}`}
              maskPlaceholder="_"
              placeholder={`01.01.${new Date()
                .getFullYear()
                .toString()
                .slice(2)}`}
              label="Дата записи"
              className="w-full lg:w-80"
            />
            <Select
              items={[
                { value: "30" },
                { value: "45" },
                { value: "60" },
                { value: "90" },
              ]}
              onChange={(v) => {
                record.duration = v;
                setNewRecords([...newRecords]);
              }}
              value={record.duration}
              icon={<IoAlarmSharp />}
              label="Длительность"
            />
            <UserSearchMultiple
              label="Клиенты:"
              value={record.clients}
              onChange={(v: any) => {
                record.clients = v;
                setNewRecords([...newRecords]);
              }}
              onBackspace={() => {
                record.clients.pop();
                setNewRecords([...newRecords]);
              }}
              deleteItem={(index) => {
                record.clients.splice(index, 1);
                setNewRecords([...newRecords]);
              }}
              onEnter={(user) => {
                record.clients = [...record.clients, user];
                setNewRecords([...newRecords]);
              }}
            />

            <UserSearch
              label="Сотрудник:"
              role="employee"
              value={record.employee}
              onChange={(v) => {
                record.employee = v;
                setNewRecords([...newRecords]);
              }}
            />
          </li>
        ))}
      </ul>
      </>

    </ModalDialog>
  );
};

export default ModalAddRecords;
