import { useMutation, useQuery } from "@apollo/client";
import { Menu, Popover, Transition } from "@headlessui/react";
import React, { FC, Fragment, useEffect, useState } from "react";
import {
  IoClose,
  IoCloseSharp,
  IoEyeOffSharp,
  IoNotificationsSharp,
  IoTrashSharp,
} from "react-icons/io5";
import {
  DELETE_NOTIFICATION,
  GET_NOTIFICATIONS,
  UPDATE_NOTIFICATION,
} from "../graphql/notifications";
import Avatar from "./UI/Avatar";
import Button from "./UI/Button";
import Icon from "./UI/Icon";
import Cookie from "js-cookie";
import { toast } from "react-toastify";
import Loading from "./UI/Loading";

// Plugin for correct display of elapsed time
import TimeAgo from "javascript-time-ago";
import ru from "javascript-time-ago/locale/ru";

const TheNotifications: FC = () => {
  // TIME FORMATTER
  TimeAgo.addLocale(ru);
  TimeAgo.setDefaultLocale("ru");
  const timeAgo = new TimeAgo("ru-RU");

  const {
    data: notifications,
    loading: notificationsLoading,
    error: notificationsError,
    refetch: notificationRefetch,
  } = useQuery(GET_NOTIFICATIONS, {
    variables: {
      userId: Cookie.get("eitrynne-client-app_id"),
    },
    fetchPolicy: "no-cache",
  });

  const [updateNotificationMutation] = useMutation(UPDATE_NOTIFICATION);
  const [deleteNotificationMutation] = useMutation(DELETE_NOTIFICATION);

  const [unreadedNotifications, setUnreadedNotifications] = useState<number>(0);

  useEffect(() => {
    console.log("component notification was rendered");
  }, []);

  useEffect(() => {
    if (notifications) {
      let unreaded = 0;
      for (const notification of notifications.getNotifications) {
        if (!notification.readed) unreaded++;
      }

      setUnreadedNotifications(unreaded);
    }
  }, [notifications]);

  // Function that changes the notification status to "read"
  async function changeNotificationStatus(id: string) {
    try {
      await updateNotificationMutation({
        variables: {
          updateNotification: {
            id,
            readed: true,
          },
        },
      });
    } catch (e) {
      console.log(e);
      toast.error("При отправке запроса возникли технические неполадки");
    } finally {
      notificationRefetch();
    }
  }

  // Function that delete the notification
  async function deleteNotification(id: string) {
    try {
      await deleteNotificationMutation({
        variables: {
          id,
        },
      });
    } catch (e) {
      console.log(e);
      toast.error("При отправке запроса возникли технические неполадки");
    } finally {
      notificationRefetch();
    }
  }

  if (notificationsError) {
    toast.error("При отправке запроса возникли технические неполадки!");
  }

  if (notificationsLoading) {
    return (
      <Icon className="text-primary border-none text-xl">
        <Loading />
      </Icon>
    );
  }

  async function updateNotifications() {}

  return (
    <>
      <Popover>
        <Popover.Button
          className="relative"
          onClick={() => notificationRefetch()}
        >
          <Icon className="text-primary border-none text-xl">
            <IoNotificationsSharp />
          </Icon>
          <span
            className={`${
              unreadedNotifications ? "bg-red" : "hidden"
            } absolute top-1.5 right-1.5 h-3 w-3 rounded-full text-[0.5rem] font-bold`}
          >
            {unreadedNotifications < 10 ? unreadedNotifications : "9+"}
          </span>
        </Popover.Button>
        <Transition>
          <>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 z-30 bg-black bg-opacity-25 dark:bg-opacity-70" />
            </Transition.Child>
            <div className="text-main fixed inset-0 z-30 overflow-y-auto">
              <div className="flex min-h-full items-start justify-end p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Popover.Panel className="shadowed absolute right-0 left-0 mx-auto my-14 w-11/12 rounded bg-white px-5 py-5 dark:bg-slate-800 lg:right-auto lg:left-auto lg:w-2/6">
                    {({ close }) => (
                      <>
                        {/* HEADER */}
                        <div className="flex items-start justify-between">
                          <h4 className="text-main text-start text-2xl font-semibold">
                            Уведомления
                          </h4>
                          <Button
                            className="text-lg"
                            icon={<IoCloseSharp />}
                            onClick={() => close()}
                          />{" "}
                        </div>{" "}
                        <hr className="my-3 dark:border-slate-700" />{" "}
                        {/* NOTIFICATIONS */}{" "}
                        <div className="max-h-full overflow-y-auto lg:max-h-80">
                          {notifications.getNotifications.length < 1 ? (
                            <div className="text-sm">Уведомлений нет</div>
                          ) : (
                            notifications.getNotifications.map((item: any) => (
                              <div
                                className={`${
                                  item.readed && "opacity-50"
                                } text-main flex items-center gap-4 border-b py-3 text-sm  dark:border-slate-700`}
                                key={item.id}
                              >
                                <div>
                                  <Avatar className="h-14 w-14" href="#" />
                                </div>
                                <div className="flex w-5/6 flex-col gap-1 text-start">
                                  {/* HEADER */}
                                  <h5 className="text-main text-base font-semibold">
                                    {item.header}
                                  </h5>
                                  {/* BODY */}{" "}
                                  <span className="text-md">{item.body}</span>{" "}
                                  {/* FOOTER */}{" "}
                                  <span className="text-xs opacity-50">
                                    {" "}
                                    {timeAgo.format(new Date(item.created_at))}
                                  </span>
                                </div>

                                {item.readed ? (
                                  <Button
                                    icon={<IoTrashSharp />}
                                    className="text-xl"
                                    onClick={() => deleteNotification(item.id)}
                                  />
                                ) : (
                                  <Button
                                    icon={<IoEyeOffSharp />}
                                    className="text-xl"
                                    onClick={() =>
                                      changeNotificationStatus(item.id)
                                    }
                                  />
                                )}
                              </div>
                            ))
                          )}
                        </div>
                      </>
                    )}
                  </Popover.Panel>
                </Transition.Child>
              </div>
            </div>
          </>
        </Transition>
      </Popover>
    </>
  );
};

export default TheNotifications;
