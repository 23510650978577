import { useMutation, useQuery } from "@apollo/client";
import { FC, useEffect, useRef, useState } from "react";
import { GoPlus } from "react-icons/go";
import {
  IoCalendarSharp,
  IoListSharp,
  IoSaveSharp,
  IoSearchSharp,
  IoTrash,
} from "react-icons/io5";
import { MdCancel, MdMoney, MdMoneyOff } from "react-icons/md";
import { TbMoodEmpty } from "react-icons/tb";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "../../components/UI/Button";
import ButtonBack from "../../components/UI/ButtonBack";
import Input from "../../components/UI/Input";
import PageHeader from "../../components/UI/PageHeader";
import Popup from "../../components/UI/Popup";
import {
  CHANGE_RECORD_STATUS,
  DELETE_RECORD,
  GET_RECORDS,
} from "../../graphql/records";
import useDebounce from "../../hooks/debounce";
import usePriceStatus from "../../hooks/priceStatus";
import calcTotalPrice from "../../hooks/totalPrice";
import removeAuth from "../../hooks/removeAuth";
import Select from "../../components/UI/Select";
import ModalDialog from "../../components/UI/ModalDialog";
import useValidDate from "../../hooks/validDate";
import getPageName from "../../utils/getPageName";
import ModalAddRecords from "../../components/UI/Modal/modalAddRecords";
import Avatar from "../../components/UI/Avatar";
import Loading from "../../components/UI/Loading";
import InfinityPagination from "../../components/UI/InfinityPagination";

const RecordsIndex: FC = () => {
  const navigate = useNavigate();
  const [employeeName, setEmployeeName] = useState("");
  const debouncedEmployee = useDebounce(employeeName);
  const [type, setType] = useState({ value: "Все" });

  useEffect(() => {
    setRecordsList([]);
    setRecordsPage(0);
  }, [debouncedEmployee, type]);

  const [showModalAddRecords, setShowModalAddRecords] = useState(false);
  const [recordsCount, setRecordsCount] = useState<number>(50);
  const [recordsPage, setRecordsPage] = useState<number>(0);
  const {
    data: records,
    loading: recordsLoading,
    refetch,
    error,
  } = useQuery(GET_RECORDS, {
    variables: {
      employeeName: debouncedEmployee,
      count: recordsCount,
      offset: recordsPage,
      recordsStatus:
        type.value === "Все"
          ? "all"
          : type.value === "Оплаченные"
          ? "paid"
          : "unpaid",
    },
    fetchPolicy: "no-cache",
  });

  const [recordsList, setRecordsList] = useState<any>([]);

  useEffect(() => {
    document.title = getPageName("Все записи");
    setRecordsList([]);
  }, []);

  useEffect(() => {
    if (!recordsLoading && records.getAllRecords.length > 0) {
      setRecordsList((prev: any) => [...prev, ...records.getAllRecords]);
    }
  }, [records]);

  const [dialogMarkRecord, setDialogMarkRecord] = useState(false);
  const [markRecordData, setMarkRecordData] = useState<any>({
    status: false,
    date: new Date().toLocaleDateString("ru"),
    type: {
      value: "QR",
    },
  });
  const [deleteRecord] = useMutation(DELETE_RECORD);
  const [changeRecordStatus] = useMutation(CHANGE_RECORD_STATUS);

  const [loading, setLoading] = useState(false);
  const [checkedRecords, setCheckedRecords] = useState<any[]>([]);

  function fetchRecords() {
    // TODO : check this issue

    if (!recordsLoading && recordsList.length !== 0) {
      if (recordsPage * recordsCount > recordsList.length) return;
      setRecordsPage((prev) => prev + 1);
    }
  }

  function pushCheckedRecord(record: any) {
    if (checkedRecords.includes(record)) {
      return setCheckedRecords(
        checkedRecords.filter((item) => item !== record)
      );
    }

    return setCheckedRecords([...checkedRecords, record]);
  }

  async function deleteRecords() {
    setLoading(true);
    try {
      for await (const record of checkedRecords) {
        await deleteRecord({
          variables: {
            id: record.id,
          },
        });
      }

      toast.success("Записи были успешно удалены!");
      setCheckedRecords([]);
      setRecordsList([]);
    } catch (e) {
      console.log("При удалении записей произошла ошибка >>>", e);
      toast.error(
        "При удалении записей произошла ошибка! Попробуйте снова или обратитесь к администратору."
      );
    } finally {
      await refetch();
      setLoading(false);
    }
  }

  async function markRecordPayed() {
    setMarkRecordData({ ...markRecordData, status: true });
    setDialogMarkRecord(true);
  }

  async function runMarkRecord() {
    setLoading(true);
    try {
      for await (const record of checkedRecords) {
        for await (const { user } of record.prices) {
          await changeRecordStatus({
            variables: {
              recordStatus: {
                id: record.id,
                status: markRecordData.status,
                payment_date: useValidDate(markRecordData.date),
                payment_type:
                  markRecordData.type.value === "QR"
                    ? "qr"
                    : markRecordData.type.value === "Наличными"
                    ? "cash"
                    : "transfer",
                payment_comment: `Отмечено как ${
                  markRecordData.status
                    ? '"Оплачено" в списке всех записей ' +
                      "[" +
                      record.employee.name +
                      "]"
                    : '"Не оплачено" в списке всех записей' +
                      "[" +
                      record.employee.name +
                      "]"
                }`,
                userId: user.id,
              },
            },
          });
        }
      }
      setRecordsPage(0);
      setRecordsList([]);
      setCheckedRecords([]);

      toast.success("Запись успешно обновлена!");
    } catch (e) {
      toast.error(
        "При обновлении статуса записи произошла ошибка! Попробуйте снова или обратитесь к администратору!"
      );
      console.log(e);
    } finally {
      setLoading(false);
      setDialogMarkRecord(false);
      await refetch();
    }
  }

  async function markRecordUnpayed() {
    setMarkRecordData({ ...markRecordData, status: false });
    setDialogMarkRecord(true);
  }

  if (error) {
    removeAuth();
  }
  return (
    <InfinityPagination callback={() => fetchRecords()}>
      <>
        <PageHeader title={"Записи"} description={"Список всех записей"}>
          {checkedRecords.length > 0 ? (
            <div className="flex w-full flex-wrap gap-3 lg:flex-row lg:space-x-5">
              <Button
                label="Отметить как оплачено"
                icon={<MdMoney />}
                loading={loading}
                onClick={() => markRecordPayed()}
                filled
              />
              <Button
                label="Отметить как не оплачено"
                icon={<MdMoneyOff />}
                loading={loading}
                onClick={() => markRecordUnpayed()}
                filled
              />
              <Button
                onClick={() => setCheckedRecords([])}
                label="Отменить"
                icon={<MdCancel />}
                loading={loading}
                filled
              ></Button>
              <Button
                onClick={() => deleteRecords()}
                label="Удалить"
                icon={<IoTrash />}
                filled
                style="red"
                loading={loading}
              ></Button>
            </div>
          ) : (
            <div className="flex w-full flex-wrap gap-3 lg:flex-row lg:flex-nowrap lg:space-x-5">
              <Input
                icon={<IoSearchSharp />}
                type="text"
                onChange={(e) => setEmployeeName(e.target.value)}
                placeholder="Поиск..."
                color="white"
                className="w-full"
                value={employeeName}
              />
              <Select
                items={[
                  { value: "Все" },
                  {
                    value: "Оплаченные",
                  },
                  {
                    value: "Не оплаченные",
                  },
                ]}
                onChange={(v) => setType(v)}
                value={type}
                color="white"
                className="-mt-1"
                icon={
                  type.value === "Все" ? (
                    <IoListSharp />
                  ) : type.value === "Оплаченные" ? (
                    <MdMoney className="text-green" />
                  ) : (
                    <MdMoney className="text-red" />
                  )
                }
              />

              <Button
                onClick={() => setShowModalAddRecords(true)}
                label="Добавить"
                icon={<GoPlus />}
                filled
              ></Button>
              <ButtonBack />
            </div>
          )}
        </PageHeader>
        <div className="card overflow-x-auto">
          <ModalAddRecords
            refetch={() => {
              setRecordsList([]);
              setRecordsPage(0);
              refetch();
            }}
            show={showModalAddRecords}
            onClose={() => setShowModalAddRecords(false)}
          />

          <table className="w-full">
            <thead className=" text-gray-400">
              <tr className="border-b dark:border-none">
                <td className="px-3 pb-2">№</td>
                <td className="px-3 pb-2">Дата</td>
                <td className="px-3 pb-2">Сотрудник</td>
                <td className="px-3 pb-2">Клиенты</td>
                <td className="px-3 pb-2 text-center">Длительность</td>
                <td className="px-3 pb-2 text-center">Стоимость</td>
                <td className="px-3 pb-2 text-center">Статус</td>
                <td className="px-3 pb-2 text-center"></td>
              </tr>
            </thead>
            <tbody className="text-sm">
              {recordsList.length === 0 ? (
                <tr>
                  <td colSpan={8} className="text-disabled py-5">
                    {loading || recordsLoading ? (
                      <div className="flex items-center justify-center">
                        <Loading />
                      </div>
                    ) : (
                      <div className="flex items-center justify-center gap-5">
                        <TbMoodEmpty className="text-2xl" /> Так сложились
                        обстоятельства, что записи отсутствуют!
                      </div>
                    )}
                  </td>
                </tr>
              ) : (
                recordsList &&
                recordsList.map((record: any, index: number) => (
                  <tr
                    key={record.id + index.toString()}
                    className={`${
                      (index + 1) % 2 !== 0
                        ? "bg-gray-50 dark:bg-slate-900/40"
                        : ""
                    }`}
                  >
                    <td className="px-2 py-2.5">{index + 1}</td>
                    <td className="px-2 py-2.5">
                      {new Date(record.date).toLocaleDateString("ru")}
                    </td>
                    <td className="px-2 py-2.5">
                      <NavLink
                        to={`/clients/${record.employee.id}`}
                        className="flex items-center gap-3"
                      >
                        <div className="h-6 w-6 ">
                          <Avatar
                            href={record.employee.id}
                            className="rounded-full"
                          />
                        </div>
                        {record.employee.name}
                      </NavLink>
                    </td>
                    <td className="px-2 py-2.5">
                      {record.prices.map((price: any, index: number) => (
                        <span
                          key={price.id}
                          onClick={() => navigate(`/clients/${price.user.id}`)}
                          className={"cursor-pointer"}
                        >
                          {price.user.name}
                          {index + 1 !== record.prices.length ? " + " : ""}
                        </span>
                      ))}
                    </td>
                    <td className="px-2 py-2.5 text-center">
                      {record.duration}
                    </td>
                    <td className="px-2 py-2.5 text-center">
                      {calcTotalPrice(record.prices)}
                    </td>
                    <td className="px-2 py-2.5 text-center">
                      <div className="flex w-full items-center justify-start gap-3">
                        {usePriceStatus(record.prices) === 0 && (
                          <span className="text-red font-bold">
                            Не оплачено
                          </span>
                        )}
                        {usePriceStatus(record.prices) === 1 && (
                          <span className="font-bold text-orange-400">
                            Оплачено частично
                          </span>
                        )}
                        {usePriceStatus(record.prices) === 2 && (
                          <span className="text-green font-bold">Оплачено</span>
                        )}
                        <Popup>
                          <div className="text-left">
                            {record.prices.map((price: any) => {
                              if (price.status === true) {
                                return (
                                  <span
                                    className="text-green block w-full font-bold"
                                    key={price.id}
                                  >
                                    {price.user.name} {price.value}
                                  </span>
                                );
                              }
                              return (
                                <span
                                  className="text-red block w-full font-bold"
                                  key={price.id}
                                >
                                  {price.user.name} {price.value}
                                </span>
                              );
                            })}
                          </div>
                        </Popup>
                      </div>
                    </td>
                    <td className="px-2 py-2.5">
                      {/* // TODO: make it possible to use shift to select multiple lines at once */}
                      <input
                        type="checkbox"
                        className={`${
                          checkedRecords.includes(record)
                            ? "opacity-100"
                            : "opacity-50"
                        } accent-primary cursor-pointer`}
                        checked={checkedRecords.includes(record)}
                        onChange={() => pushCheckedRecord(record)}
                      />
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
          <ModalDialog
            show={dialogMarkRecord}
            onClose={() => setDialogMarkRecord(false)}
            title={`Пометить записи как ${
              markRecordData.status ? "оплаченные" : "не оплаченные"
            }`}
            loading={loading}
            description={`${
              markRecordData.status
                ? "Укажите верные данные и подтвердите действие"
                : "Подтвердите действие"
            }`}
          >
            <div className="w-full">
              <div className="text-main mb-5 flex space-x-5">
                {markRecordData.status ? (
                  <>
                    <Input
                      type="text"
                      label="Дата"
                      icon={<IoCalendarSharp />}
                      value={markRecordData.date}
                      onChange={(e) => {
                        markRecordData.date = e.target.value;
                        setMarkRecordData({ ...markRecordData });
                      }}
                      mask={`99.99.${new Date().getFullYear()}`}
                      maskPlaceholder="_"
                    />
                    <Select
                      items={[
                        {
                          value: "QR",
                        },
                        {
                          value: "Наличными",
                        },
                        {
                          value: "Перевод",
                        },
                      ]}
                      onChange={(v) =>
                        setMarkRecordData({ ...markRecordData, type: v })
                      }
                      value={markRecordData.type}
                      icon={<MdMoney />}
                      label="Способ оплаты"
                      className="w-40"
                    />
                  </>
                ) : (
                  <>
                    <p className="text-sm">
                      После подтверждения выбранные записи станут помечены как
                      неоплаченные.
                      <br /> Стоимость записи будет списана с баланса
                      посетителя.
                    </p>
                  </>
                )}
              </div>
              <div className="flex justify-between">
                <Button
                  onClick={() => setDialogMarkRecord(false)}
                  label="Отменить"
                  icon={<MdCancel />}
                  loading={loading}
                />
                <Button
                  onClick={() => runMarkRecord()}
                  label="Подтвердить"
                  icon={<IoSaveSharp />}
                  loading={loading}
                  filled
                />
              </div>
            </div>
          </ModalDialog>
        </div>
      </>
    </InfinityPagination>
  );
};

export default RecordsIndex;
