import { useMutation, useQuery } from "@apollo/client";
import { FC, useEffect, useState } from "react";
import { GoPlus } from "react-icons/go";
import {
  IoCashSharp,
  IoPersonSharp,
  IoQrCodeSharp,
  IoTrash,
} from "react-icons/io5";
import { TbMoodEmpty } from "react-icons/tb";
import { toast } from "react-toastify";
import Button from "../../components/UI/Button";
import ButtonBack from "../../components/UI/ButtonBack";
import Input from "../../components/UI/Input";
import PageHeader from "../../components/UI/PageHeader";
import { DELETE_PAYMENT, GET_PAYMENTS } from "../../graphql/users";
import useDebounce from "../../hooks/debounce";
import removeAuth from "../../hooks/removeAuth";
import { NavLink } from "react-router-dom";
import getPageName from "../../utils/getPageName";
import InfinityPagination from "../../components/UI/InfinityPagination";
import ModalAddPayments from "../../components/UI/Modal/modalAddPayments";

const Payments: FC = () => {
  const [showDialogAddPayment, setShowDialogAddPayment] = useState(false);

  // * live search in payments
  const [query, setQuery] = useState("");
  const debouncedUser = useDebounce(query);

  const [paymentsListPage, setPaymentsListPage] = useState<number>(0);
  const [paymentsListCount, setPaymentsListCount] = useState<number>(30);
  const [paymentsList, setPaymentsList] = useState<any>([]);

  const {
    data: payments,
    loading: paymentsLoading,
    error,
    refetch,
  } = useQuery(GET_PAYMENTS, {
    variables: {
      count: paymentsListCount,
      offset: paymentsListPage,
      userName: debouncedUser,
    },
  });

  const [deletePayment] = useMutation(DELETE_PAYMENT);

  /**
   * * Uploading new data to the list of payments when scrolling the page
   * @returns
   */
  function fetchPayments() {
    if (paymentsLoading) return;

    if (paymentsList.length === 0) return;

    if (paymentsListPage * paymentsListCount > paymentsList.length) return;

    setPaymentsListPage((prev: number) => prev + 1);
  }

  /**
   * * Function for updating payment records
   */
  function refreshPayments() {
    paymentsList.length !== 0 && setPaymentsList([]);
    paymentsListPage !== 0 && setPaymentsListPage(0);
    refetch();
  }

  /**
   * *Confirmation of the payment records deletion, after confirmation through the default browser popup window
   * ? TODO: change it for more user-friendly window
   * @param payment
   */
  async function toggleDeletePayment(payment: any) {
    if (confirm("Подтвердите удаление данного платежа!")) {
      await deletePayment({
        variables: {
          id: payment.id,
        },
      });

      toast.success(`Платеж был удален!`);

      refreshPayments();
    }
  }

  /**
   * * Updating the list of payments when the component is loaded for the first time.
   * * Assigning the correct name to a tab in the browser
   */
  useEffect(() => {
    refetch();
    document.title = getPageName("Все платежи");
  }, []);

  /**
   * * Updating the list of payments after changing the value in the search line
   */
  useEffect(() => {
    setPaymentsList([]);
    setPaymentsListPage(0);
  }, [debouncedUser]);

  /**
   * * Updating the local list of payments after loading data from database
   */
  useEffect(() => {
    if (paymentsLoading) return;
    if (payments.getAllPayments.length === 0) return;

    setPaymentsList((prev: any) => [...prev, ...payments.getAllPayments]);
  }, [payments]);

  /**
   * ? Removing user authorization records if an error came from the server
   * ? TODO: Fix this bug. Sometimes works with slow internet
   */
  if (error) removeAuth();

  return (
    <InfinityPagination callback={() => fetchPayments()}>
      <>
        <PageHeader title="Платежи" description="Список всех платежей в студии">
          <div className="flex w-full items-center gap-5">
            <Input
              placeholder="Поиск по клиентам..."
              icon={<IoPersonSharp />}
              onChange={(e) => setQuery(e.target.value)}
              type="text"
              color="white"
              className="w-full"
              value={query}
            />
            <Button
              label="Добавить"
              icon={<GoPlus />}
              filled
              onClick={() => setShowDialogAddPayment(true)}
            />
            <ButtonBack />
          </div>
        </PageHeader>
        <div className="card overflow-x-auto">
          <table className="w-full">
            <thead className=" text-gray-400">
              <tr className="border-b ">
                <td className="px-3 pb-2">№</td>
                <td className="px-3 pb-2">Дата</td>
                <td className="px-3 pb-2">Посетитель</td>
                <td className="px-3 pb-2">Сумма</td>
                <td className="px-3 pb-2 text-center">Способ оплаты</td>
                <td className="px-3 pb-2">Комментарий</td>
                <td className="px-3 pb-2 text-center"></td>
              </tr>
            </thead>
            <tbody className="text-main text-sm">
              {paymentsList.length === 0 ? (
                <tr>
                  <td colSpan={6} className="text-disabled py-5">
                    <div className="flex items-center justify-center gap-5">
                      <TbMoodEmpty className="text-2xl" /> Так сложились
                      обстоятельства, что записи отсутствуют!
                    </div>
                  </td>
                </tr>
              ) : (
                paymentsList.map((payment: any, index: number) => (
                  <tr key={payment.id}>
                    <td className="px-3 py-1">{index + 1}</td>
                    <td className="px-3 py-1">
                      {new Date(payment.date).toLocaleDateString("ru")}
                    </td>
                    <td className="px-3 py-1">
                      <NavLink to={`/clients/${payment.user.id}`}>
                        {payment.user.name}
                      </NavLink>
                    </td>
                    <td className="px-3 py-1">{payment.value}</td>
                    <td className="px-3 py-1">
                      <div className="flex justify-center">
                        <span
                          className={`flex w-24 items-center gap-2 rounded px-2 py-0.5 text-xs font-bold ${
                            payment.type === "cash"
                              ? "bg-green"
                              : "text-dark bg-yellow"
                          }`}
                        >
                          {payment.type === "cash" ? (
                            <>
                              <IoCashSharp /> Наличные
                            </>
                          ) : payment.type === "qr" ? (
                            <>
                              <IoQrCodeSharp /> QR
                            </>
                          ) : (
                            <>
                              <IoCashSharp /> Перевод
                            </>
                          )}
                        </span>
                      </div>
                    </td>
                    <td className="px-3 py-1">{payment.comment}</td>
                    <td className="px-3 py-1">
                      <Button
                        icon={<IoTrash />}
                        onClick={() => toggleDeletePayment(payment)}
                      />
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
          {/* Make this modal window a separate component */}
          <ModalAddPayments
            show={showDialogAddPayment}
            onClose={() => setShowDialogAddPayment(false)}
            refresh={() => refreshPayments()}
          />
        </div>
      </>
    </InfinityPagination>
  );
};

export default Payments;
