import React, { FC } from "react";
import "./ShowAnimatedBackground.css";
interface ShowAnimatedBackgroundProps {
  children: JSX.Element;
}

const SnowAnimatedBackground: FC<ShowAnimatedBackgroundProps> = ({
  children,
}) => {
  return (
    <div>
      <div className="intro">{children}</div>
      {/* {[...Array(10)].map((e, index) => (
        <div className="snowflake" key={index}>
          ❅
        </div>
      ))} */}
    </div>
  );
};

export default SnowAnimatedBackground;
