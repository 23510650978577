import { useMutation, useQuery } from "@apollo/client";
import { Combobox, Transition } from "@headlessui/react";
import React, { FC, ReactElement, useState } from "react";
import {
  IoCodeOutline,
  IoPeopleSharp,
  IoCheckmarkSharp,
  IoCloseSharp,
} from "react-icons/io5";
import { toast } from "react-toastify";
import { CREATE_USER, GET_USERS } from "../graphql/users";
import useDebounce from "../hooks/debounce";
import { Client } from "../types";
import Icon from "./UI/Icon";
import Loading from "./UI/Loading";
import removeAuth from "../hooks/removeAuth";
import Avatar from "./UI/Avatar";

interface UserSearchProps {
  label: string;
  value: any;
  role?: string;
  onChange: (e: any) => void;
  onBackspace: () => void;
  deleteItem: (index: number) => void;
  onEnter: (user: any) => void;
}
const UserSearchMultiple: FC<UserSearchProps> = ({
  label,
  value,
  role = "all",
  onChange,
  onBackspace,
  deleteItem,
  onEnter,
}) => {
  const [query, setQuery] = useState("");
  const debouncedValue = useDebounce(query);
  const [showInput, setShowInput] = useState(true);
  const [showCombobox, setShowCombobox] = useState(false);
  const {
    data: employees,
    loading: employeesLoading,
    refetch,
    error,
  } = useQuery(GET_USERS, {
    variables: {
      searchUsers: {
        name: debouncedValue.trim(),
        role,
      },
    },
  });
  const [createUser] = useMutation(CREATE_USER);

  async function keyEvents(e: React.KeyboardEvent) {
    if (e.key === "Backspace" && query.length === 0) {
      if (showInput) return setShowInput(false);
      onBackspace();
    }

    if (e.key === "Enter") {
      if (query !== "") {
        setQuery("");
        setShowCombobox(false);
        setShowInput(false);
      } else {
        setShowInput(true);
      }
    }

    if (
      e.key === "Enter" &&
      query.length !== 0 &&
      employees.getUsers.length === 0
    ) {
      if (confirm(`Создать пользователя с именем "${query}"?`)) {
        try {
          const { data: user } = await createUser({
            variables: {
              createUser: {
                name: query,
                category: "z",
              },
            },
          });
          onEnter(user.createUser);
          refetch();
        } catch (e) {
          toast.error(
            "При добавлении пользователя произошла ошибка! Попробуйте снова или обратитесь к администратору."
          );
        }
      }
    }
  }

  function onActivate(e: any) {
    setShowInput(true);
  }

  if (error) removeAuth();

  return (
    <div
      className="cursor-pointer text-xs sm:w-96"
      onClick={(e) => onActivate(e)}
    >
      {/* LiveSearch component */}
      <Combobox value={value} onChange={onChange} multiple>
        <Combobox.Label className={"text-sm"}>{label}</Combobox.Label>
        <div className="bordered form-element mt-1 flex rounded bg-slate-50 !py-[3px] !px-1 dark:border-none dark:bg-slate-700 dark:text-gray-400">
          <Icon className=" left-1">
            <IoPeopleSharp className="text-sm" />
          </Icon>
          <div
            className={`${
              !showInput && "pt-1"
            } flex w-full flex-wrap items-center gap-1 text-xs`}
          >
            {/* <div className="flex w-auto flex-wrap gap-1 bg-yellow-50"> */}
            {value.map((person: any, index: number) => (
              <div
                key={person.id}
                className="bg-primary relative flex w-full rounded px-1 py-0.5"
              >
                {person.name}
                <IoCloseSharp
                  className="absolute right-1 top-1 text-white"
                  onClick={() => deleteItem(index)}
                />
              </div>
            ))}
            {/* </div> */}
            <Combobox.Input
              onChange={(event) => {
                setShowCombobox(true);
                setQuery(event.target.value);
              }}
              className={`${!showInput && "h-0"} flex-1
                bg-slate-50 text-sm outline-none dark:bg-slate-700`}
              displayValue={(person: Client) => person.name}
              autoComplete="none"
              placeholder={`${value.length === 0 ? "Начните вводить ФИО" : ""}`}
              name={`${Math.random().toString(8)}`}
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                keyEvents(e);
              }}
            />
          </div>
          <Combobox.Button className="items-center">
            <Icon className="rotate-90" aria-hidden="true">
              <IoCodeOutline className="text-xs" />
            </Icon>
          </Combobox.Button>
        </div>
        <Transition
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-0 opacity-0"
          enterTo="transform scale-100 opacity-100"
        >
          {showCombobox && (
            <>
              <Combobox.Options
                static
                className={
                  "shadowed absolute mt-1 w-full rounded bg-white dark:bg-slate-700"
                }
              >
                {employeesLoading ? (
                  <div className="flex h-8 items-center justify-center text-xl">
                    <Loading />
                  </div>
                ) : employees.getUsers.length > 0 &&
                  debouncedValue.trim() !== "" ? (
                  employees.getUsers.map((person: Client, index: number) => (
                    <Combobox.Option
                      key={person.id}
                      value={person}
                      onClick={() => {
                        setQuery("");
                        setShowCombobox(false);
                      }}
                    >
                      {({ active, selected }) => (
                        <div
                          className={`text-main flex cursor-pointer select-none items-center gap-2 rounded py-1.5 pl-2 pr-4  ${
                            selected || active
                              ? "bg-slate-100 dark:bg-slate-600 dark:text-gray-200"
                              : null
                          }`}
                        >
                          <Avatar
                            href={person.id}
                            alt="avatar"
                            className="h-6 w-6 rounded-full"
                          />
                          {person.name}
                          {selected && (
                            <IoCheckmarkSharp className="absolute right-4" />
                          )}
                        </div>
                      )}
                    </Combobox.Option>
                  ))
                ) : debouncedValue.trim() !== "" ? (
                  <div className="text-disabled py-2 text-center">
                    Ничего не найдено!{" "}
                    <span className="text-main font-bold">
                      {" "}
                      Нажмите "Enter", чтобы добавить клиента "{query}"?
                    </span>
                  </div>
                ) : (
                  <div className="text-disabled py-2 text-center">
                    Начните вводить ФИО!
                  </div>
                )}
              </Combobox.Options>
            </>
          )}
        </Transition>
      </Combobox>
    </div>
  );
};

export default UserSearchMultiple;
