import { useEffect, useRef } from "react";

export default function useScroll(
  parentRef: React.RefObject<Element> | null,
  childRef: React.RefObject<Element> | null,
  callback: () => void
) {
  const observer = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    const parent = parentRef?.current;
    const child = childRef?.current;
    const hasIOSupport = !!window.IntersectionObserver;

    if (!hasIOSupport || !parent || !child) return;

    const options = {
      root: parent,
      rootMargin: "0%",
      threshold: [0, 1],
    };

    observer.current = new IntersectionObserver(([target]) => {
      if (target.isIntersecting) {
        callback();
      }
    }, options);

    observer.current.observe(child);

    return function () {
      if (observer.current) observer.current.unobserve(child);
    };
  }, [callback]);
}
