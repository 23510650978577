import { FC, useEffect, useState } from "react";
import PageHeader from "../../components/UI/PageHeader";
import ButtonBack from "../../components/UI/ButtonBack";
import Button from "../../components/UI/Button";
import { GoPlus } from "react-icons/go";
import { useMutation, useQuery } from "@apollo/client";
import {
  CREATE_GROUP,
  DELETE_GROUP,
  GET_GROUPS,
  UPDATE_GROUP,
  UPDATE_USER,
} from "../../graphql/users";
import Loading from "../../components/UI/Loading";
import {
  IoColorFilterSharp,
  IoMoveSharp,
  IoSaveSharp,
  IoSendSharp,
  IoTrash,
  IoTrashBinSharp,
} from "react-icons/io5";
import removeAuth from "../../hooks/removeAuth";
import Input from "../../components/UI/Input";
import {
  MdAllInclusive,
  MdCancel,
  MdEdit,
  MdMoney,
  MdOutlineComment,
} from "react-icons/md";
import ModalDialog from "../../components/UI/ModalDialog";
import { toast } from "react-toastify";
import { Tab } from "@headlessui/react";
import classNames from "../../hooks/classNames";
import { useNavigate } from "react-router-dom";
import { Client } from "../../types";
import Select from "../../components/UI/Select";
import getPageName from "../../utils/getPageName";

const UsersGroup: FC = () => {
  const [loading, setLoading] = useState(false);
  const [dialogAddGroup, setDialogAddGroup] = useState(false);
  const [dialogDeleteGroup, setDialogDeleteGroup] = useState(false);
  const [dialogMoveUsers, setDialogMoveUsers] = useState(false);
  const [currentUserGroup, setCurrentUserGroup] = useState<any>({});

  const navigate = useNavigate();

  const [group, setGroup] = useState({
    id: null,
    value: "",
    subs_fee: "",
    comment: "",
  });
  const {
    data: groups,
    loading: loadingGroups,
    error,
    refetch,
  } = useQuery(GET_GROUPS);

  useEffect(() => {
    refetch();
    document.title = getPageName("Все группы");
  }, []);
  const [createGroup] = useMutation(CREATE_GROUP);
  const [updateGroup] = useMutation(UPDATE_GROUP);
  const [deleteGroup] = useMutation(DELETE_GROUP);
  const [updateUser] = useMutation(UPDATE_USER);

  const [checkedUsers, setCheckedUsers] = useState<Client[]>([]);
  function pushCheckedUsers(user: Client) {
    if (checkedUsers.includes(user)) {
      return setCheckedUsers(checkedUsers.filter((item) => item !== user));
    }

    return setCheckedUsers([...checkedUsers, user]);
  }
  function toggleAddGroup() {
    setDialogAddGroup(true);
  }

  async function toggleEditGroup(g: any) {
    setGroup({ ...g });
    setDialogAddGroup(true);
  }

  async function toggleDeleteGroup(g: any) {
    if (!g.id) return;
    localStorage.setItem("deleteGroup", g.id);
    setDialogDeleteGroup(true);
  }

  async function runDeleteGroup() {
    setLoading(true);

    try {
      await deleteGroup({
        variables: { id: localStorage.getItem("deleteGroup") },
      });
    } catch (e) {
      console.log("Ошибка при удалении группы >>> ", e);
      // toast.error(
      //   "Ошибка при удалении группы! Попробуйте снова или обратитесь к администратору."
      // );
    } finally {
      await refetch();
      toast.success(`Группа была успешно удалена!`);
      localStorage.removeItem("deleteGroup");
      setLoading(false);
      setDialogAddGroup(false);
      setDialogDeleteGroup(false);
    }
  }

  function closeDialog() {
    setGroup({
      id: null,
      value: "",
      subs_fee: "",
      comment: "",
    });

    setDialogAddGroup(false);
  }

  async function runEditGroup() {
    console.log(group);
    setLoading(true);
    try {
      await updateGroup({
        variables: {
          updateGroup: {
            id: group.id,
            subs_fee: parseInt(group.subs_fee),
            value: group.value,
            comment: group.comment,
          },
        },
      });
      await refetch();
      toast.success("Группа была изменена");
    } catch (e) {
      console.log("Ошибка во время изменения группы >>>", e);
      toast.error(
        "Во время изменения группы произошла ошибка! Попробуйте снова или обратитесь к администратору."
      );
    } finally {
      setLoading(false);
      closeDialog();
    }
  }

  async function saveGroup() {
    try {
      setLoading(true);
      if (group.subs_fee.length === 0)
        throw new Error("Недопустимое значение для имени группы");

      if (group.comment.length === 0)
        throw new Error("Недопустимое значение для комментария группы");

      if (group.value.length === 0)
        throw new Error("Недопустимое значение для абонемента группы");

      const { data } = await createGroup({
        variables: {
          createGroup: {
            subs_fee: parseInt(group.subs_fee),
            comment: group.comment,
            value: group.value,
          },
        },
      });

      await refetch();

      closeDialog();

      toast.success(`Группa была успешно добавлена!`);
    } catch (e) {
      console.log("Ошибка при добавлении группы!", e);
      toast.error(
        `Ошибка при добавлении группы! Попробуйте снова или обратитесь к администратору.`
      );
    } finally {
      setGroup({
        id: null,
        value: "",
        comment: "",
        subs_fee: "",
      });

      setLoading(false);
    }
  }

  function toggleMoveUsers(group: any) {
    setCurrentUserGroup(group);
    setDialogMoveUsers(true);
  }

  async function runMoveUsers() {
    setLoading(true);
    try {
      for await (const user of checkedUsers) {
        await updateUser({
          variables: {
            updateUser: {
              id: user.id,
              groupId: currentUserGroup.id,
            },
          },
        });
      }
      await refetch();
      toast.success(
        `Пользователи были перемещены в группу ${currentUserGroup.value}!`
      );
    } catch (e) {
      toast.error(
        "Во время перемещения пользователей произошла ошибка. Попробуйте снова или обратитесь к администратору"
      );
      console.log(e);
    } finally {
      setLoading(false);
      setDialogMoveUsers(false);
      setCheckedUsers([]);
    }
  }
  if (loadingGroups) return <Loading />;
  if (error) removeAuth();

  return (
    <>
      <PageHeader title="Группы" description="Список всех групп для клиентов">
        <div className="flex space-x-5">
          <Button
            label="Добавить"
            icon={<GoPlus />}
            onClick={() => toggleAddGroup()}
            filled
          />
          <ButtonBack />
        </div>
      </PageHeader>

      {/* Table with categories */}
      <Tab.Group>
        <Tab.List className={"translate-y-[1px] text-xs"}>
          {groups.getAllGroups.map((group: any, index: number) => (
            <Tab
              key={group.id}
              className={({ selected }) =>
                classNames(
                  "text-main w-fit min-w-[100px] rounded-tl-md rounded-tr-md py-1 px-3",
                  "border-gray-200 outline-none ring-0 dark:border-none",
                  "",
                  selected
                    ? "text-primary border border-b-white bg-white font-bold dark:border-none dark:bg-slate-800"
                    : "text-disabled "
                )
              }
              onClick={() => setCheckedUsers([])}
            >
              {group.value}
            </Tab>
          ))}
        </Tab.List>
        <div className="card shadowed overflow-x-auto  !rounded-tl-none shadow-gray-100 ">
          <Tab.Panels>
            {groups.getAllGroups.map((group: any, index: number) => {
              return (
                <Tab.Panel
                  key={group.id}
                  className={
                    "text-main border-primary w-full space-y-5 py-1 px-2 outline-none ring-0"
                  }
                >
                  {/* Header */}
                  <div className="flex justify-between">
                    <div>
                      <h2 className="text-dark text-md font-bold">
                        {group.comment} ({group.subs_fee} в мес.)
                      </h2>
                      <p className="text-xs font-light text-gray-400">
                        Всего посетителей: {group.users.length} чел.
                      </p>
                    </div>
                    <div className="flex items-center space-x-5">
                      {checkedUsers.length > 0 ? (
                        <>
                          <Button
                            label="Переместить в другую группу"
                            icon={<IoSendSharp />}
                            className=" text-xs"
                            onClick={() => toggleMoveUsers(group)}
                            filled
                          />
                          <Button
                            label="Выбрать все"
                            icon={<MdAllInclusive />}
                            onClick={() => setCheckedUsers(group.users)}
                            className="text-xs"
                          />
                          <Button
                            label="Отмена"
                            icon={<MdCancel />}
                            className="text-xs"
                            onClick={() => setCheckedUsers([])}
                          />
                        </>
                      ) : (
                        <>
                          <Button
                            label="Добавить"
                            icon={<GoPlus />}
                            onClick={() => navigate("/clients?add=true")}
                            className="text-xs"
                          />
                          <Button
                            label="Изменить"
                            icon={<MdEdit />}
                            onClick={() => toggleEditGroup(group)}
                            className="text-xs"
                          />
                        </>
                      )}
                    </div>
                  </div>
                  <div>
                    <table className="w-full text-xs">
                      <thead className=" text-gray-400">
                        <tr className="border-b dark:border-none">
                          <td className="px-3 pb-2">№</td>
                          <td className="px-3 pb-2">Имя</td>
                          <td className="px-3 pb-2">Дата рождения</td>
                          <td className="px-3 pb-2">Роль</td>
                          <td className="px-3 pb-2 text-center">Баланс:</td>
                          <td className="px-3 pb-2"></td>
                        </tr>
                      </thead>
                      <tbody className="text-dark text-sm">
                        {group.users &&
                          group.users.map((user: Client, index: number) => (
                            <tr
                              key={user.id}
                              className={classNames(
                                "cursor-pointer",
                                (index + 1) % 2 !== 0
                                  ? "bg-gray-50 dark:bg-slate-900/40"
                                  : ""
                              )}
                              onClick={() => navigate(`/clients/${user.id}`)}
                            >
                              <td className="px-2 py-2.5">{index + 1}</td>
                              <td className="px-2 py-2.5">{user.name}</td>
                              <td className="px-2 py-2.5">
                                {user.birthdate
                                  ? new Date(user.birthdate).toLocaleDateString(
                                      "ru"
                                    )
                                  : "Не указана"}
                              </td>
                              <td className="px-2 py-2.5">
                                {user.role === "client"
                                  ? "Клиент"
                                  : "Сотрудник"}
                              </td>
                              <td
                                className={`px-2 py-2.5 text-center font-bold ${
                                  // @ts-ignore
                                  user.deposit - user.score >= 0
                                    ? "text-green"
                                    : "text-red"
                                }`}
                              >
                                {/* @ts-ignore */}
                                {user.deposit - user.score}
                              </td>
                              <td
                                className="px-2 py-2.5"
                                onClick={(e) => e.stopPropagation()}
                              >
                                <input
                                  type="checkbox"
                                  className={`${
                                    checkedUsers.includes(user)
                                      ? "opacity-100"
                                      : "opacity-50"
                                  } accent-primary cursor-pointer`}
                                  checked={checkedUsers.includes(user)}
                                  onChange={() => pushCheckedUsers(user)}
                                />
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    {group.users.length < 1 && (
                      <div className="text-main flex h-[125px] w-full items-center justify-center text-sm">
                        В данной группе пока никого нет!
                      </div>
                    )}
                  </div>
                </Tab.Panel>
              );
            })}
          </Tab.Panels>
          {groups.getAllGroups.length < 1 && (
            <div className="text-main flex h-[125px] w-full items-center justify-center">
              Так сложились обстоятельства, что группы не были найдены!
            </div>
          )}
        </div>
      </Tab.Group>

      <ModalDialog
        onClose={() => closeDialog()}
        show={dialogAddGroup}
        title={group.id ? "Редактирование группы" : "Создание новой группы"}
        loading={loading}
      >
        <div className="w-full">
          <div className="mb-5 flex flex-col gap-5 lg:flex-row">
            <Input
              icon={<IoColorFilterSharp />}
              onChange={(e) =>
                setGroup({
                  ...group,
                  value: e.target.value,
                })
              }
              type="text"
              value={group.value}
              label="Название группы"
              className={"w-full"}
              placeholder="Например: Взрослая группа"
            />
            <Input
              icon={<MdMoney />}
              onChange={(e) =>
                setGroup({
                  ...group,
                  subs_fee: e.target.value,
                })
              }
              type="text"
              value={group.subs_fee}
              label="Абонемент"
              placeholder="*****"
            />
            <Input
              icon={<MdOutlineComment />}
              onChange={(e) =>
                setGroup({
                  ...group,
                  comment: e.target.value,
                })
              }
              type="text"
              value={group.comment}
              placeholder="Комментарий..."
              label="Комментарий по группе"
              className="w-full"
            />
          </div>
          <div className="flex justify-end">
            {group.id ? (
              <div className="flex w-full justify-between">
                <Button
                  icon={<IoTrash />}
                  label="Удалить"
                  onClick={() => toggleDeleteGroup(group)}
                  style="red"
                />

                <Button
                  icon={<IoSaveSharp />}
                  label="Сохранить"
                  onClick={() => runEditGroup()}
                  loading={loading}
                ></Button>
              </div>
            ) : (
              <Button
                icon={<IoSaveSharp />}
                label="Создать"
                onClick={() => saveGroup()}
                loading={loading}
              ></Button>
            )}
          </div>
        </div>
      </ModalDialog>

      <ModalDialog
        show={dialogDeleteGroup}
        onClose={() => setDialogDeleteGroup(false)}
        title="Подтвердите действие"
        loading={loading}
      >
        <div className="w-full">
          <p className="text-main mb-5">
            Вы действительно хотите удалить данную группы? При удалении группы
            также <strong>уничтожаются</strong> данные{" "}
            <strong>всех клиентов</strong> этой группы, а также связанные с ними
            данные!
          </p>
          <div className="flex justify-between">
            <Button
              onClick={() => setDialogDeleteGroup(false)}
              label="Отменить"
              icon={<MdCancel />}
              loading={loading}
            />
            <Button
              onClick={() => runDeleteGroup()}
              label="Удалить"
              icon={<IoTrashBinSharp />}
              style="red"
              loading={loading}
            />
          </div>
        </div>
      </ModalDialog>

      <ModalDialog
        show={dialogMoveUsers}
        onClose={() => setDialogMoveUsers(false)}
        title="Перемещение пользователей"
        loading={loading}
      >
        <div className="w-full">
          <div className="text-main mb-5">
            Выберите группу, в которую необходимо перенести выбранных
            посетителей:
            <Select
              items={groups.getAllGroups}
              onChange={(v) => setCurrentUserGroup(v)}
              value={currentUserGroup}
              icon={<IoColorFilterSharp />}
            />
          </div>
          <div className="flex justify-between">
            <Button
              onClick={() => setDialogMoveUsers(false)}
              label="Отменить"
              icon={<MdCancel />}
              loading={loading}
            />
            <Button
              onClick={() => runMoveUsers()}
              label="Перенести"
              icon={<IoMoveSharp />}
              loading={loading}
              filled
            />
          </div>
        </div>
      </ModalDialog>
    </>
  );
};

export default UsersGroup;
