import React, { FC, useEffect, useRef } from "react";
import useScroll from "../../hooks/useScroll";

interface InfinityPaginationProps {
  children: JSX.Element;
  callback: () => void;
}
const InfinityPagination: FC<InfinityPaginationProps> = ({
  children,
  callback,
}) => {
  const parentRef = useRef<HTMLDivElement | null>(null);
  const childRef = useRef<HTMLDivElement | null>(null);

  useScroll(parentRef, childRef, () => callback());

  return (
    <div ref={parentRef} className="padded h-screen overflow-y-auto rounded">
      {children}

      <div ref={childRef} className="h-1 w-full"></div>
    </div>
  );
};

export default InfinityPagination;
