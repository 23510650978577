import { ChangeEventHandler, FC } from "react";
import InputMask from "react-input-mask";
interface InputProps {
  bordered?: boolean;
  label?: string;
  icon: JSX.Element;
  placeholder?: string;
  type: string;
  value: any;
  color?: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  className?: string;
  mask?: string;
  maskPlaceholder?: string;
}

const Input: FC<InputProps> = ({
  onChange,
  value,
  bordered = true,
  label = "",
  icon,
  placeholder = "",
  type,
  color = "slate-50",
  className = "",
  mask = "",
  maskPlaceholder = "",
}) => {
  return (
    <div
      className={`text-main flex flex-col items-center  ${className}  ${
        label ? "space-y-1" : null
      }`}
    >
      <label className={`w-full text-sm`}>{label ? `${label}:` : ""}</label>
      <div className={`relative flex w-full items-center`}>
        <span className="absolute left-3">{icon}</span>
        <InputMask
          mask={mask}
          maskPlaceholder={maskPlaceholder}
          onChange={onChange}
          type={type}
          value={value}
          placeholder={placeholder}
          className={`w-full bg-${color} form-element !pl-8 text-gray-600 outline-none dark:border-none dark:bg-slate-700 dark:text-gray-400 dark:focus:bg-slate-800 ${
            bordered ? "border border-gray-300" : "border-none"
          }
          focus:border focus:border-gray-200 focus:bg-white`}
        />
      </div>
    </div>
  );
};

export default Input;
