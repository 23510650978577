import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const NotFoundPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/");
  }, []);
  return (
    <div className="bg-red flex h-fit w-full">This is not found page!</div>
  );
};

export default NotFoundPage;
