import { FC } from "react";

const TheLogo: FC = () => {
  return (
    <div
      className="relative my-2 flex w-full scale-150 items-end justify-center lg:my-1 lg:scale-100"
      id="logo"
    >
      <span className="lg:text-main font-[Wallpoet] text-6xl text-indigo-600 transition-all lg:translate-x-11 lg:group-hover:translate-x-0 lg:group-hover:text-indigo-600 lg:group-hover:delay-200 lg:dark:text-gray-400">
        E
      </span>
      <span className="pb-[3px] font-[Goldman] text-2xl transition-transform dark:text-gray-300 lg:translate-x-52 lg:group-hover:translate-x-0 lg:group-hover:delay-100">
        itrynne
      </span>
      <span className="absolute top-3 right-[33%] font-[Goldman] text-xs text-gray-700 transition-opacity dark:text-gray-400 lg:right-14 lg:opacity-0 lg:group-hover:opacity-100 lg:group-hover:delay-200 lg:group-hover:duration-500">
        {process.env.REACT_APP_VERSION}
      </span>
    </div>
  );
};

export default TheLogo;
