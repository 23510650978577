import { gql } from "@apollo/client";

// USER
export const GET_USERS = gql`
  query getUsers($searchUsers: SearchUsersInput!) {
    getUsers(searchUsers: $searchUsers) {
      id
      name
      birthdate
      category {
        value
      }
      role
      deposit
      score
      group {
        subs_fee
        value
        comment
      }
    }
  }
`;

export const GET_USER_RECORDS = gql`
  query getUserRecordsById(
    $id: String!
    $searchValue: String!
    $take: Float!
    $skip: Float!
  ) {
    getRecordsByUserId(
      id: $id
      take: $take
      skip: $skip
      searchValue: $searchValue
    ) {
      id
      employee {
        id
        name
      }
      duration
      date
      prices {
        id
        status
        value
        user {
          id
          name
        }
      }
    }
  }
`;

export const GET_USER = gql`
  query getOneUser($id: String!) {
    getOneUser(id: $id) {
      id
      name
      role
      deposit
      score
      phone
      email
      birthdate
      visible
      category {
        value
        comment
      }
      group {
        id
        value
        subs_fee
      }
      studio {
        id
      }
    }

    getPaymentsByUserId(id: $id) {
      id
      date
      value
      type
      comment
    }
  }
`;

export const CREATE_USER = gql`
  mutation createUser($createUser: CreateUserInput!) {
    createUser(createUser: $createUser) {
      id
      name
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($updateUser: UpdateUserInput!) {
    updateUser(updateUser: $updateUser) {
      name
    }
  }
`;

export const DELETE_USER = gql`
  mutation deleteUser($id: String!) {
    deleteUser(id: $id) {
      name
    }
  }
`;

// CATEGORIES
export const GET_CATEGORIES = gql`
  query {
    getAllCategories {
      id
      value
      comment
      users {
        id
        name
        birthdate
        deposit
        score
        role
        group {
          value
          subs_fee
        }
      }
    }
  }
`;

export const CREATE_CATEGORY = gql`
  mutation createCategory($createCategory: CreateCategoryInput!) {
    createCategory(createCategory: $createCategory) {
      value
    }
  }
`;

export const UPDATE_CATEGORY = gql`
  mutation updateCategory($updateCategory: UpdateCategoryInput!) {
    updateCategory(updateCategory: $updateCategory) {
      id
    }
  }
`;

export const DELETE_CATEGORY = gql`
  mutation deleteCategory($id: String!) {
    deleteCategory(id: $id) {
      id
    }
  }
`;

// PRICES

export const GET_PRICES = gql`
  query {
    getAllPrices {
      id
      value
      category {
        value
      }
      employee {
        name
        record_fee_45
        record_fee_60
        group_fee
      }
    }

    getAllCategories {
      id
      value
      comment
    }

    getAllEmployees {
      id
      name
      role
      record_fee_45
      record_fee_60
      group_fee
      category {
        value
      }
      prices {
        id
        value
        category {
          value
        }
      }
    }
  }
`;

export const UPDATE_PRICE = gql`
  mutation updatePrice($updatePrice: UpdatePriceInput!) {
    updatePrice(updatePrice: $updatePrice) {
      id
    }
  }
`;

// PAYMENTS

export const CREATE_PAYMENT = gql`
  mutation createPayment($createPayment: CreatePaymentInput!) {
    createPayment(createPayment: $createPayment) {
      id
    }
  }
`;

export const GET_PAYMENTS = gql`
  query getAllPayments($userName: String!, $count: Float!, $offset: Float!) {
    getAllPayments(userName: $userName, count: $count, offset: $offset) {
      id
      user {
        id
        name
      }
      value
      comment
      date
      type
    }
  }
`;

export const DELETE_PAYMENT = gql`
  mutation deletePayment($id: String!) {
    deletePayment(id: $id) {
      id
    }
  }
`;

// GROUPS
export const CREATE_GROUP = gql`
  mutation createGroup($createGroup: CreateGroupInput!) {
    createGroup(createGroup: $createGroup) {
      id
    }
  }
`;

export const GET_GROUPS = gql`
  query {
    getAllGroups {
      id
      subs_fee
      value
      comment
      users {
        id
        name
        birthdate
        score
        deposit
        role
      }
    }
  }
`;

export const GET_GROUP_WITH_SUBS = gql`
  query {
    getAllGroupsWithSubsFee {
      id
      subs_fee
      value
      users {
        id
        score
      }
    }
  }
`;

export const UPDATE_GROUP = gql`
  mutation updateGroup($updateGroup: UpdateGroupInput!) {
    updateGroup(updateGroup: $updateGroup) {
      id
    }
  }
`;

export const DELETE_GROUP = gql`
  mutation deleteGroup($id: String!) {
    deleteGroup(id: $id) {
      value
    }
  }
`;

// REPORTS
export const GET_REPORTS_DATA = gql`
  query ($searchUsers: SearchUsersInput!) {
    getReportsData(searchUsers: $searchUsers) {
      id
      name
      record_prices {
        value
        record {
          employee {
            id
            name
          }
          date
          duration
        }
        status
      }
      employee_records {
        id
        date
        prices {
          id
          user {
            name
          }
          value
          record {
            duration
          }
        }
      }
    }

    getPaymentsForReport(searchUsers: $searchUsers) {
      id
      value
      type
    }
  }
`;
