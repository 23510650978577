import React, { FC, useEffect, useState } from "react";
import Input from "./UI/Input";
import {
  IoChevronDownOutline,
  IoLogOutOutline,
  IoMenuSharp,
  IoMoon,
  IoMoonOutline,
  IoMoonSharp,
  IoNotificationsOutline,
  IoNotificationsSharp,
  IoPersonSharp,
  IoSearchOutline,
  IoSettingsOutline,
  IoSunnySharp,
} from "react-icons/io5";
import Button from "./UI/Button";
import { Menu, Transition } from "@headlessui/react";
import Icon from "./UI/Icon";
import { useNavigate } from "react-router-dom";
import Cookie from "js-cookie";
import removeAuth from "../hooks/removeAuth";
import TheHeaderSearch from "./TheHeaderSearch";
import Avatar from "./UI/Avatar";
import TheNotifications from "./TheNotifications";

export type Theme = "light" | "dark";

interface TheHeaderProps {
  menuButtonCallback: () => void;
  sidebarActive: boolean;
}

const TheHeader: FC<TheHeaderProps> = ({
  menuButtonCallback,
  sidebarActive,
}) => {
  const navigate = useNavigate();

  const [searchInput, setSearchInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentTheme, setCurrentTheme] = useState<Theme>("light");

  const [activeMenu, setActiveMenu] = useState<boolean>(false);

  useEffect(() => {
    if (localStorage.getItem("theme") === "dark") {
      document.documentElement.classList.add("dark");
      setCurrentTheme("dark");
    }
  }, []);

  function changeTheme() {
    if (document.documentElement.classList.contains("dark")) {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("theme", "light");
      setCurrentTheme("light");

      return;
    }

    document.documentElement.classList.add("dark");

    localStorage.setItem("theme", "dark");
    setCurrentTheme("dark");
  }

  function clickBurgerMenuButton() {
    menuButtonCallback();
    activeMenu ? setActiveMenu(false) : setActiveMenu(true);
  }
  return (
    <nav
      className={`shadowed absolute z-auto ${
        !sidebarActive ? "left-0" : "left-[65%]"
      } right-0 top-0 flex items-center justify-between rounded bg-white p-1.5 px-2.5 dark:bg-slate-800 lg:top-0 lg:right-0 lg:left-20 lg:justify-between lg:p-3.5`}
    >
      <div className="hidden lg:block">
        <TheHeaderSearch />
      </div>
      <div className="block lg:hidden">
        {/* Burger button */}
        <Button
          filled={false}
          icon={<IoMenuSharp />}
          onClick={() => clickBurgerMenuButton()}
          className="py-1 !text-xl"
        />
      </div>
      <div className="flex items-center">
        <div className="flex items-center justify-between space-x-3">
          <div className="flex justify-between">
            <TheNotifications />
            {currentTheme === "dark" ? (
              <Button
                className="!text-xl"
                icon={<IoSunnySharp />}
                loading={loading}
                onClick={changeTheme}
              />
            ) : (
              <Button
                icon={<IoMoon />}
                className="!text-xl"
                loading={loading}
                onClick={changeTheme}
              />
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default TheHeader;
